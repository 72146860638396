import React, {Component} from 'react';
import PropTypes from 'prop-types'
import styled from 'styled-components';
import {colors, fonts} from "../styles/styleguide";

class RoundIconButton extends Component {
    render() {
        const {label, onClick, backgroundColor, disabled, disabledBackgroundColor, iconSrc, color, border, height, fontSize, fontWeight, letterSpacing, imgSize} = this.props;
        return (
            <Button backgroundColor={disabled ? disabledBackgroundColor : backgroundColor} onClick={onClick} border={border} height={height}>
                <Img imgSize={imgSize} src={iconSrc}/>
                <Label fontSize={fontSize} color={color} fontWeight={fontWeight} letterSpacing={letterSpacing}>{label}</Label>
            </Button>
        );
    }
}

RoundIconButton.propTypes = {
    label: PropTypes.string,
    onClick: PropTypes.func,
    backgroundColor: PropTypes.string,
    color: PropTypes.string,
    disabled: PropTypes.bool,
    disabledBackgroundColor: PropTypes.string,
    iconSrc: PropTypes.string,
    border: PropTypes.string,
    height: PropTypes.number,
    fontSize: PropTypes.number,
    fontWeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    letterSpacing: PropTypes.number,
    imgSize: PropTypes.number
};

RoundIconButton.defaultProps = {
    backgroundColor: colors.charcoal,
    color: "#ffffff",
    disabledBackgroundColor: colors.greyish,
    disabled: false,
    border: "solid 2px #ffffff",
    height: 50,
    fontSize: 21,
    fontWeight: "bold",
    letterSpacing: 1.7,
    imgSize: 29
};

const Button = styled.div`
  height: ${props => props.height}px;
  object-fit: contain;
  border-radius: ${props => props.height / 2}px;
  box-shadow: -6.9px 4px 4px 0 rgba(147, 143, 143, 0.23);
  border: ${props => props.border};
  background-color: ${props => props.backgroundColor};
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-right: 10px;
  padding-left: 10px;
`;

const Img = styled.img`
  width: ${props => props.imgSize}px;
  height: ${props => props.imgSize}px;
  object-fit: contain;
`;

const Label = styled.div`
  font-family: ${fonts.OpenSansHebrew};
  font-size: ${props => props.fontSize}px;
  font-weight: ${props => props.fontWeight};
  font-style: normal;
  font-stretch: normal;
  letter-spacing: ${props => props.letterSpacing}px;
  text-align: center;
  color:${props => props.color};
  margin-right: 7px;
`;


export default RoundIconButton;