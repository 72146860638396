import {buildEnv} from "shared/src/constants/buildEnv";
import * as prodSettings from "./env.prod";
import * as devSettings from "./env.dev";
import * as stgSettings from "./env.stg";

let settings = devSettings

switch (buildEnv) {
    case "prod":
        settings = prodSettings
        break
    case "stg":
        settings = stgSettings
        break
}

export const GA_UA = settings.GA_UA;