import React, {Component, PureComponent} from 'react';
import PropTypes from 'prop-types'
import {injectIntl, FormattedMessage} from 'react-intl';
import {get, orderBy} from "lodash";

import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';

import IconInput from "./IconInput";

class FirebaseObjectListSelect extends PureComponent{


    renderList = (items, isMaterial, rawObject = false) => {
        const {labelAccessor, itemPath} = this.props;


        let list = [];
        for(let key in items){
            let label = ""
            if(typeof  labelAccessor === "function"){
                label = labelAccessor(items[key])
            }else {
                if(itemPath){
                    label = get(items, `${key}.${itemPath}.${labelAccessor}`)
                }else {
                    label = get(items, `${key}.${labelAccessor}`)
                }
            }
            list.push({label: label, value: key})
        }
        const sorted = orderBy(list, ["label"]);

        if(rawObject){
            return sorted;
        }

        return sorted.map((item, index)=>{
            if(isMaterial){
                return <MenuItem key={`opt_key_${index}`} value={item.value} primaryText={item.label}/>;
            }
            else {
                return <option key={`opt_key_${index}`} value={item.value}>{item.label}</option>
            }
        })
    }

    renderMaterialSelect = () => {
        const {
            onChange, list, value, intl,
            style, iconStyle, labelStyle, underlineStyle, hintText, disabled
        } = this.props;

        return (
            <SelectField
                disabled={disabled}
                value={value}
                onChange={(event, index, value) => onChange(value)}
                hintText={hintText || intl.formatMessage({id: "supplier.select.title"})}
                style={style || null}
                iconStyle={iconStyle || null}
                labelStyle={labelStyle || null}
                underlineStyle={underlineStyle || null}
                autoWidth={true}
            >
                {
                    this.renderList(list, true)
                }
            </SelectField>
        )
    }

    renderNativeSelect = () => {

        const {
            onChange, list, value, intl,
            style, iconStyle, labelStyle, underlineStyle, hintText, disabled, icon, size
        } = this.props;

        if(icon){
            const options = this.renderList(list, false, true);
            return (
                <IconInput
                    disabled={disabled}
                    options={options}
                    value={value}
                    placeholder={hintText}
                    labelAccessor={"label"}
                    valueAccessor={"value"}
                    onChange={(value) => onChange(value)}
                    type={"select"}
                    icon={icon}
                    size={size}
                />
            )
        }
        return(
            <select
                disabled={disabled}
                value={value}
                onChange={(event) => onChange(event.target.value)}
                style={style || null}
            >
                <option value={""}>{hintText}</option>
                {
                    this.renderList(list, false)
                }
            </select>
        )
    }

    render() {
        const {
           useMaterialDesign
        } = this.props;

        if(useMaterialDesign){
            return this.renderMaterialSelect();
        }
        return this.renderNativeSelect();
    }
}

FirebaseObjectListSelect.defaultProps = {
    labelAccessor: "name",
    useMaterialDesign: true,
    disabled: false,
    size: "lg"
}

FirebaseObjectListSelect.propTypes = {
    onChange: PropTypes.func,
    list: PropTypes.object,
    value: PropTypes.any,
    style: PropTypes.object,
    iconStyle: PropTypes.object,
    labelStyle: PropTypes.object,
    underlineStyle: PropTypes.object,
    hintText: PropTypes.string,
    labelAccessor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    disabled: PropTypes.bool,
    useMaterialDesign: PropTypes.bool,
    itemPath: PropTypes.string
}

export default injectIntl(FirebaseObjectListSelect);