import React, {Component} from 'react';
import PropTypes from 'prop-types'
import styled from 'styled-components';
import { fonts, colors } from "../../styles/styleguide";

class IconButton extends Component {

  render() {
      const {label,
          labelColor,
          onClick,
          backgroundColor,
          containerStyle,
          borderColor,
          icon,
          disabled

      } = this.props;
    return (
        <Button style={containerStyle} backgroundColor={backgroundColor} borderColor={borderColor} onClick={onClick} disabled={disabled}>
            {icon}
            <Label labelColor={labelColor}>{label}</Label>
        </Button>
    );
  }
}

IconButton.propTypes = {
    label: PropTypes.string,
    labelColor: PropTypes.string,
    onClick: PropTypes.func,
    backgroundColor: PropTypes.string,
    borderColor: PropTypes.string,
    containerStyle: PropTypes.string,
    icon: PropTypes.any,
    disabled: PropTypes.bool
};

IconButton.defaultProps = {
    labelColor: "#fff",
    backgroundColor: colors.charcoal,
    borderColor: "#fff",
    containerStyle: {},
    disabled: false
};

const Button = styled.button`
  height: 72px;
  border: 1px solid ${props => props.borderColor};
  position: relative;
  padding-left: 40px;
  background-color: ${props => props.disabled ? "#f0f0f0" : props.backgroundColor ? props.backgroundColor : colors.charcoal};
  display: flex;
  flex-direction: row;
  align-items: center;
   border-radius: 36px;
  box-shadow: -6.9px 4px 4px 0 rgba(147, 143, 143, 0.23);
      &:focus{
      outline: none;
    }
`;

const Label = styled.div`
  font-size: 25px;
  font-weight: bold;
  color: ${props => props.labelColor ? props.labelColor : "#fff"};
  line-height: 2;
  letter-spacing: 1.8px;
  margin-right: 15px;
`



export default IconButton;