import React from "react";
import styled from "styled-components";
import { colors, fonts } from "../../styles/styleguide";


const Checkbox = ({className, checked, label, ...props}) => (
    <label>
        <CheckboxContainer className={className}>
            <HiddenCheckbox checked={checked} {...props} />
            <StyledCheckbox checked={checked}>
                <Icon viewBox="0 0 24 24">
                    <polyline points="20 6 9 17 4 12"/>
                </Icon>
            </StyledCheckbox>
        </CheckboxContainer>
        <LabelText>{label}</LabelText>
    </label>
)


const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;

`;



const HiddenCheckbox = styled.input.attrs({type: 'checkbox'})`
  // Hide checkbox visually but remain accessible to screen readers.
  // Source: https://polished.js.org/docs/#hidevisually
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const Icon = styled.svg`
  fill: none;
  stroke: ${colors.charcoal };
  stroke-width: 2px;
`;

const LabelText = styled.span`
  font-family: ${fonts.OpenSansHebrew};
  font-size: 21px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: ${colors.charcoal};
  margin: 0 10px;
`

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 25px;
  height: 25px;
  background: #f0f0f0;
  border: 2px solid ${colors.charcoal };
  border-radius: 6px;
 
  transition: all 150ms;
  
  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px pink;
  }
  
  ${Icon} {
    visibility: ${props => props.checked ? 'visible' : 'hidden'}
  }
  
`;

export default Checkbox;