import React, {Component} from 'react';
import PropTypes from 'prop-types'
import styled from 'styled-components';
import {colors, fonts} from "../styles/styleguide";

class RoundButton extends Component {
    render() {
        const {label, onClick, backgroundColor, disabled} = this.props;
        return (
            <Button backgroundColor={backgroundColor} onClick={()=> !disabled && onClick()} {...this.props} disabled={disabled}>
                <Label>{label}</Label>
            </Button>
        );
    }
}

RoundButton.propTypes = {
    label: PropTypes.string,
    onClick: PropTypes.func,
    backgroundColor: PropTypes.string,
    disabled: PropTypes.bool
};

RoundButton.defaultProps = {
    backgroundColor: colors.yellow,
    disabled: false
};

const Button = styled.div`
  height: 50px;
  object-fit: contain;
  border-radius: 25px;
 border: solid 2px #464646;
  background-color: ${props => props.backgroundColor};
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-right: 10px;
  padding-left: 10px;
`;



const Label = styled.div`
  font-family: ${fonts.OpenSansHebrew};
  font-size: 21px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
 letter-spacing: 1.2px;
 color: ${colors.charcoal};
  margin-right: 5px;
`;


export default RoundButton;