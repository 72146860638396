import React, { Component } from 'react';


import { firebaseConnect, isEmpty, isLoaded } from 'react-redux-firebase'
import styled from 'styled-components';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { isEqual } from "lodash";


import RoundButton from "../../../components/RoundButton"
import IconInput from "../../../components/Form/IconInput";

import { InputGroup } from "../../../styles/stylesheet";
import {colors} from "../../../styles/styleguide";


import AuthPage, {GoogleButton, GoogleLogo, LoginError, ContentWrapper} from "../AuthPage";

class Login extends Component {

    constructor() {
        super();
        this.state = {
            email: "",
            password: "",
            loginError: "",
            loginSuccess: false
        }
    }

    componentDidUpdate(prevProps) {
        const {history, auth} = this.props;
        if (!isEqual(prevProps.auth) && auth && isLoaded(auth) && !isEmpty(auth) && this.state.loginSuccess) {
            const redirect = location.hash.split('redirect=');
            if (redirect && redirect[1]) {
                let find = '%2F';
                let re = new RegExp(find, 'g');
                let str = redirect[1].replace(re, '/');
                history.push(str)
            }
            else {
                history.push("/");
            }
        }
    }

    onLoginSuccess = (response) => {
        const {history} = this.props;
        if (response.user && (response.user.uid || response.user.user.uid)) {
            console.log("login success");
            //history.push("/");
            this.setState({loginSuccess: true})
        }
    }

    onLogin = () => {
        const {firebase} = this.props;
        const {email, password} = this.state;
        this.setState({loginError: ""})
        firebase.login({email, password}).then((response) => {
            console.log("onLogin success", response)
            this.onLoginSuccess(response)
        }).catch(e => {
            console.log("login error", e);
            this.setState({loginError: e.message})
        })
    }

    onGoogleSignIn = () => {
        const {firebase} = this.props;
        this.setState({loginError: ""})
        firebase.login({
            provider: 'google',
            type: 'popup'
        }).then((response) => {
            this.onLoginSuccess(response)
        }).catch(e => {
            console.log("login error", e);
            this.setState({loginError: e.message})
        })
    }

    onFacebookSignIn = () => {
        const {firebase} = this.props;
        this.setState({loginError: ""})
        firebase.login({
            provider: 'facebook',
            type: 'popup'
        }).then((response) => {
            this.onLoginSuccess(response)
        }).catch(e => {
            console.log("login error", e);
            this.setState({loginError: e.message})
        })
    }

    _handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            console.log('enter pressed');
            this.onLogin();
        }
    }


    render() {

        const {email, password, loginError} = this.state;

        return (
            <AuthPage title={"כניסה למשתמשים רשומים"}>
                <ContentWrapper>

                    <GoogleButton bg={colors.vibrantGreen90} onClick={()=>{this.props.history.push("sms-login")}}>
                        <div>כניסה באמצעות SMS</div>
                    </GoogleButton>

                    <GoogleButton style={{marginTop: 15}} onClick={this.onGoogleSignIn}>
                        <GoogleLogo src={"img/google_logo.png"}/>
                        <div>כניסה עם גוגל</div>
                    </GoogleButton>



                    <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-around" , marginTop: 15}}>
                        <div style={{width: "40%", height: 1, backgroundColor: "#ababab"}}/>
                        <span style={{
                            fontFamily: "OpenSansHebrew",
                            fontSize: 21.5,
                            fontWeight: 300,
                            color: "#ababab"
                        }}>או</span>
                        <div style={{width: "40%", height: 1, backgroundColor: "#ababab"}}/>
                    </div>


                    <LoginError style={{marginTop: 15}}>{loginError}</LoginError>

                    <InputGroup style={{marginTop: 15}}>
                        <IconInput
                            type={"email"}
                            onChange={(value) => {
                                this.setState({email: value})
                            }}
                            value={email}
                            dir="ltr"
                            onKeyPress={this._handleKeyPress}
                            icon={<img src={"img/icons/person_outline.svg"} style={{width: 31}}/>}
                            placeholder={"כתובת דואר אלקטרוני"}
                        />
                    </InputGroup>

                    <InputGroup style={{marginTop: 15}}>
                        <IconInput
                            type={"password"}
                            onChange={(value) => {
                                this.setState({password: value})
                            }}
                            value={password}
                            dir="ltr"
                            onKeyPress={this._handleKeyPress}
                            icon={<img src={"img/icons/password.png"} style={{width: 31}}/>}
                            placeholder={"סיסמא"}
                        />
                    </InputGroup>

                    <div style={{display: "flex", flexDirection: "row", alignItems: "baseline", justifyContent: "space-between" , marginTop: 15}}>
                        <div
                            onClick={() => {this.props.history.push("/password-reset")}}
                            style={{
                                fontFamily: "OpenSansHebrew",
                                fontSize: 21.5,
                                fontWeight: 300,
                                color: colors.charcoal,
                                cursor: "pointer"
                            }}
                        >
                            שכחת את הסיסמא?
                        </div>
                        <div style={{
                            fontSize: 28.5,
                            color: colors.yellow
                        }}>|</div>
                        <div
                            onClick={() => {
                                this.props.history.push("/signup")
                            }}
                            style={{
                                fontFamily: "OpenSansHebrew",
                                fontSize: 23.5,
                                fontWeight: 300,
                                color: colors.charcoal,
                                cursor: "pointer"
                            }}
                        >
                            הרשמו עכשיו
                        </div>
                    </div>

                </ContentWrapper>
                <div style={{display: "flex", flexDirection: "row", alignItems: "baseline", justifyContent: "space-between"}}>
                    <div
                        onClick={() => {
                            window.location.href = '/about';
                        }}
                        style={{
                            fontFamily: "OpenSansHebrew",
                            fontSize: 23.5,
                            fontWeight: 300,
                            color: colors.charcoal,
                            cursor: "pointer"
                        }}
                    >
                        אודות החברה
                    </div>
                </div>
                <div style={{position: "absolute", bottom: -25, left: 15}}>
                    <RoundButton
                        onClick={this.onLogin}
                        label={"כניסה"}
                    />
                </div>
            </AuthPage>
        );
    }
}

const mapStateToProps = ({firebase: {auth}}) => ({
    auth: auth,
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default firebaseConnect()(connect(mapStateToProps, mapDispatchToProps)(Login));
