import React, { Component } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { firebaseConnect, getVal } from "react-redux-firebase";
import { compose } from "redux";
import PropTypes from 'prop-types';

import { isLoaded, isEmpty } from 'react-redux-firebase'

import {
    Nav,
    NavbarBrand,
    NavbarToggler,
    NavItem,
    NavLink,
    Badge
} from 'reactstrap';
import HeaderDropdown from './HeaderDropdown';

import { logout } from "shared/src/modules/account"
import { shouldShowMessagesBadge } from "shared/src/selectors/messages";

import {
    userIsSingleSupplier
} from "shared/src/selectors/roles";

import ROUTES from "../../constants/routes"

class Header extends Component {

    constructor(props) {
        super(props);
    }

    sidebarToggle(e) {
        e.preventDefault();
        document.body.classList.toggle('sidebar-hidden');
    }

    sidebarMinimize(e) {
        e.preventDefault();
        document.body.classList.toggle('sidebar-minimized');
    }

    mobileSidebarToggle(e) {
        e.preventDefault();
        document.body.classList.toggle('sidebar-mobile-show');
    }

    asideToggle(e) {
        e.preventDefault();
        document.body.classList.toggle('aside-menu-hidden');
    }

    render() {
        const {profile, auth, messageManagement, userIsSingleSupplier, supplierLogo, showErpIntegrationFailure} = this.props;
        console.log("header -- : ", profile, auth.isAnonymous, userIsSingleSupplier, supplierLogo);
        let showSideBarToggle = false;
        let showMessagesBadge = messageManagement ? shouldShowMessagesBadge(messageManagement.lastUpdated, messageManagement.lastChecked) : false;

        return (
            <header className="app-header navbar">
                {showMessagesBadge && (<Badge color="success"></Badge>)}
                {showSideBarToggle ? ([
                    (<NavbarToggler className="d-lg-none" onClick={this.mobileSidebarToggle}>
                        <span className="navbar-toggler-icon"></span>
                    </NavbarToggler>),
                    (<NavbarBrand href="#"></NavbarBrand>),
                    (<NavbarToggler className="d-md-down-none" onClick={this.sidebarToggle}>
                        <span className="navbar-toggler-icon"></span>
                    </NavbarToggler>)
                ]) : (<NavbarBrand href="#"></NavbarBrand>)}
                <Nav className="mr-auto" navbar>
                    {isLoaded(auth) ? !auth.isAnonymous && profile ?
                        <span>Hello! {profile.displayName}</span> :
                        <span onClick={() => {
                            this.props.firebase.login({
                                provider: 'google',
                                type: 'popup'
                            })
                        }}>sign in</span> : <span>Loading...</span>
                    }
                    <HeaderDropdown showMessagesBadge={showMessagesBadge} profile={profile} logout={() => {
                        this.props.logout()
                    }} {...this.props}/>
                </Nav>
                {showErpIntegrationFailure ? (
                    <div style={{
                        position: "absolute", left: "20%", right: "20%", top: 0, background: "red", height: 55,
                        display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center",
                        fontWeight: "bold", fontSize: 20
                    }}>
                        שימו לב! יש תקלה בסנכרון מול מערכת ERP אנא ודאו שמחשב הרשת דולק ומחובר לרשת. במידה והתקלה נמשכת אנא פנו לתמיכה
                    </div>
                ) : null}
            </header>
        );
    }
}

const mapStateToProps = state => {
    const supplierId = userIsSingleSupplier(state.roles.roles);
    return {
        profile: state.firebase.profile, auth: state.firebase.auth,
        showSideBarToggle: state.layout.showSideBarToggle,
        messageManagement: getVal(state.firebase, `data.messageManagement.${state.firebase.auth.uid}`, {}),
        userIsSingleSupplier: supplierId,
        supplierLogo: supplierId ? getVal(state.firebase, `data.suppliers.${supplierId}/logo`, null) : null,
        roles: state.roles.roles,
        uid: state.firebase.auth.uid
    }
};

const mapDispatchToProps = dispatch => bindActionCreators({
    logout
}, dispatch);

const enhance = compose(
    connect(mapStateToProps, mapDispatchToProps),
    firebaseConnect((props) => {
        const {roles, uid} = props;
        const supplierId = userIsSingleSupplier(roles);
        const paths = [
            `messageManagement/${uid}`
        ];
        if(supplierId){
            paths.push(`suppliers/${supplierId}/logo`)
        }
        return (paths)
    })
)

export default enhance(Header);
