import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { firebaseConnect, getVal } from "react-redux-firebase";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { Col, Row } from "reactstrap";
import { Card, CartHeader, InputGroup, InputLabel } from "../../../styles/stylesheet";
import IconInput from "../../../components/Form/IconInput";
import AddButton from "../../../components/AddButton";
import ConfirmButton from "../../../components/ConfirmButton";
import ReactTable from "react-table";
import { toastr } from "react-redux-toastr";
import { uuidWithoutSpace } from "shared/src/utils";
import _ from "lodash";

class Agents extends Component {

    constructor(props) {
        super(props);
        this.state = {
            agents: [],
            name: "",
            nameError: false,
            agentErpIdError: false,
            agentErpId: ""
        }
    }

    componentDidMount() {
        this.setState({agents: this.props.agentsBySupplier || []})
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.agentsBySupplier, this.props.agentsBySupplier)) {
            this.setState({agents: this.props.agentsBySupplier || []})
        }
    }

    saveAgents = () => {
        const {
            agents
        } = this.state;

        const {supplierId} = this.props;

        const {firebase} = this.props;

        console.log("save delivery options");


        if (!supplierId) {
            toastr.error("יש לשמור את פרטי הספק לפני ששומרים את הסוכנים");
            return;
        }

        const transformedOptions = agents.map((o) => {
            if (o.__isNew__) {
                o.value = uuidWithoutSpace()
                delete o.__isNew__;
            }
            return o;
        })

        firebase.set(`suppliersPrivateData/${this.props.supplierId}/agents`, transformedOptions, (res) => {
            toastr.success("הסוכנים נשמרו בהצלחה");
        })
    }

    addAgent = (name, agentErpId) => {

        if (!this.validateOption(name, agentErpId)) {
            return;
        }

        const option = {
            name: name,
            id: uuidWithoutSpace(),
            agentErpId
        }

        const agents = _.cloneDeep(this.state.agents);
        agents.push(option);
        this.setState({
            agents,
            nameError: false,
            name: "",
            agentErpIdError: false,
            agentErpId: ""

        });
    }

    validateOption = (name, agentErpId) => {
        let isValid = true;
        if (!name) {
            isValid = false;
            this.setState({
                nameError: true,
            });
        }
        if (!agentErpId) {
            isValid = false;
            this.setState({
                agentErpIdError: true,
            });
        }


        return isValid;
    }

    removeAgent = (index) => {
        const agents = _.cloneDeep(this.state.agents);
        agents.splice(index, 1);
        this.setState({
            agents
        });
    }

    render() {
        const {
            agents,
            name,
            nameError,
            agentErpIdError,
            agentErpId
        } = this.state;
        return (
            <Row>
                <Col md={12}>
                    <Card>
                        <CartHeader>סוכנים</CartHeader>
                        <Row>
                            <Col md={3}>
                                <InputGroup>
                                    <InputLabel>שם הסוכן</InputLabel>
                                    <IconInput
                                        type={"text"}
                                        onChange={(text) => {
                                            this.setState({name: text})
                                        }}
                                        value={name}
                                        icon={<img src={"img/icons/driver.svg"}/>}
                                    />
                                    {nameError && (
                                        <div style={{color: "red"}}>יש להזין את שם הסוכן</div>)}
                                </InputGroup>
                            </Col>

                            <Col md={3}>
                                <InputGroup>
                                    <InputLabel>מזהה פנימי</InputLabel>
                                    <IconInput
                                        type={"text"}
                                        onChange={(text) => {
                                            this.setState({agentErpId: text})
                                        }}
                                        value={agentErpId}
                                        icon={<img src={"img/icons/list.svg"}/>}
                                    />
                                    {agentErpIdError && (
                                        <div style={{color: "red"}}>יש להזין מזהה פנימי</div>)}
                                </InputGroup>
                            </Col>

                            <Col md={2} className={"align-self-end"}>
                                <AddButton onClick={() => {
                                    this.addAgent(name, agentErpId)
                                }} label={"הוספה"}/>
                            </Col>
                            <Col md={2} className={"align-self-end"}>
                                <ConfirmButton
                                    onClick={this.saveAgents}
                                    label={"שמירה"}
                                />
                            </Col>

                        </Row>
                    </Card>
                    <Row>
                        <Col md={8}>
                            <ReactTable
                                style={{minHeight: 168, marginTop: 25}}
                                data={agents || []}
                                columns={[
                                    {
                                        id: 'name',
                                        Header: 'שם הסוכן',
                                        accessor: 'name', // String-based value accessors!,
                                    },
                                    {
                                        id: 'agentErpId',
                                        Header: 'מזהה פנימי',
                                        accessor: 'agentErpId', // String-based value accessors!,
                                    },
                                    {
                                        id: "actions",
                                        Header: '',
                                        width: 50,
                                        Cell: props => {
                                            return (
                                                <img src={"img/icons/close_red.svg"} style={{width: 20}}
                                                     onClick={() => {
                                                         this.removeAgent(props.index)
                                                     }}/>
                                            )
                                        }//<EllipsisText text={props.value || ''} length={18}/>, // Custom cell components!
                                    }
                                ]}
                                showPagination={false}
                                pageSize={agents ? agents.length : 0}
                                filterable={false}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
}

Agents.propTypes = {
    supplierId: PropTypes.string.isRequired
};


const mapStateToProps = (state, props) => {
    return {
        agentsBySupplier: getVal(state.firebase.data, `suppliersPrivateData/${props.supplierId}/agents`, []),
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);


export default compose(
    firebaseConnect((props) => ([
        `suppliersPrivateData/${props.supplierId}/agents`,
    ])),
    connect(
        mapStateToProps, mapDispatchToProps
    )
)(Agents);

