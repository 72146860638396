import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {compose} from "redux"
import { firebaseConnect, getVal } from 'react-redux-firebase'
import { map, get } from 'lodash'
import Dropzone from 'react-dropzone'
import {injectIntl, FormattedMessage} from 'react-intl';
import MediaUploader from "../../components/Form/ImageUploader";
import { toastr } from 'react-redux-toastr'

import {upload} from "../../utils/firebaseUploader"

import {
    Modal, ModalHeader, ModalBody, ModalFooter, Button
} from 'reactstrap';
import { isUsersAdmin } from "shared/src/selectors/roles";


// Component Enhancer that adds props.firebase and creates a listener for
// files them passes them into props.uploadedFiles

const mapStateToProps = (state, props) => {
    return {
        uploadedFiles: getVal(state.firebase.data, `suppliersPrivateData/${props.supplierId}/logo`, null)
    };
};

const enhance = compose(
    firebaseConnect((props) =>([
        `suppliersPrivateData/${props.supplierId}/logo`
    ])),
    connect(mapStateToProps)
)



class Uploader extends React.Component{

    constructor() {
        super();
        this.state = {
            itemToDelete: null,
            isModalOpen: false
        }

        this.openModal = this.openModal.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.deleteFile = this.deleteFile.bind(this);
    }

    openModal(file, key){
        this.setState({
            isModalOpen: true,
            itemToDelete: {
                file,
                key
            }
        })
    }

    confirmDelete(){
        const {itemToDelete} = this.state;
        this.deleteFile(itemToDelete.file, itemToDelete.key);
        this.closeModal();
    }

    deleteFile(file, key){
        const {firebase, supplierId} = this.props;
        let dbPath = `suppliersPrivateData/${supplierId}/logo`;
        return firebase.deleteFile(file.fullPath, `${dbPath}/${key}`)
    }

    closeModal(){
        this.setState({
            isModalOpen: false,
            itemToDelete: null
        })
    }

    render() {

        const {uploadedFiles, firebase, supplier, itemId, supplierId} = this.props;
        const {isModalOpen, itemToDelete} = this.state;




        const onFilesDrop = (files, rejected) => {
            if(files && files.length > 0){
                // uploadFiles(storagePath, files, dbPath)
                let dbPath = `suppliersPrivateData/${supplierId}/logo`;
                let storagePath = `/metadataBySupplier/${supplierId}/logo`;
                // return firebase.uploadFiles(storagePath, files, dbPath)
                upload(firebase,storagePath,files.slice(0, 1)[0], dbPath, (item)=>{
                    console.log('Item file uploaded');
                });
            }
            else {
                console.log("rejected files", rejected);
                toastr.error('לא ניתן להעלות תמונה בפורמט שיניסתם, אנא ודאו שאתם מעלים קובץ מהסוגים: jpg או png', {timeOut: 4000})
            }

        }

        // Deletes file and removes metadata from database

        console.log("uploadedFiles", uploadedFiles)
        return (
            <MediaUploader
                onDeleteFile={this.deleteFile}
                onFilesDrop={onFilesDrop}
                uploadedFiles={uploadedFiles}
                accept={"image/jpg, image/jpeg, image/png"}
                showSidePreview={false}
                dropzoneHeight={"230px"}
                showAddIconOnPreview={false}
            />

        )
    }
}


Uploader.propTypes = {
    firebase: PropTypes.object.isRequired,
    supplierId: PropTypes.string
}

export default enhance(injectIntl(Uploader))