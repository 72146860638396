import React, { Component } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux';
import { firebaseConnect, isLoaded, getVal } from 'react-redux-firebase'
import { compose } from 'redux'
import _ from "lodash";
import { Row, Col } from 'reactstrap';
import styled from "styled-components";
import {convertPhoneNumberToLocal} from "shared/src/utils/phone"

class Profile extends Component {

    filterRoles = (roles) => {
        let clone = _.cloneDeep(roles);
        const removeKey = (key) => {
            if(clone[key]){
                delete clone[key];
            }
        }
        removeKey("iss");
        removeKey("picture");
        removeKey("user_id");
        removeKey("sub");
        removeKey("iat");
        removeKey("exp");
        removeKey("email_verified");
        removeKey("firebase");
        removeKey("aud");
        removeKey("auth_time");
        removeKey("name");
        removeKey("email");
        removeKey("phone_number");

        return clone;
    }

    render() {
        const {profile, firebase, userId, roles} = this.props;
        console.log("profile: ", profile)
        const providerName = profile && profile.providerData && profile.providerData.length > 0 ?
            profile.providerData[0].providerId : null
        return (
            <div>
                {profile && (
                    <Row>
                        <Col md={12}>
                            <Card>
                                <InputGroup>
                                    <InputLabel>שם</InputLabel>
                                    <input
                                        disabled={!(!providerName || providerName === "phone")}
                                        style={{width: "100%", height: 34}}
                                        value={profile.displayName}
                                        onChange={(e) => { firebase.set( `userProfiles/${userId}/displayName`,e.target.value)}}/>
                                </InputGroup>
                                <InputGroup>
                                    <InputLabel>טלפון</InputLabel>
                                    <input
                                        disabled={providerName === "phone"}
                                        style={{width: "100%", height: 34}}
                                        value={providerName === "phone" ? convertPhoneNumberToLocal(profile.phoneNumber) : profile.phoneNumber}
                                        onChange={(e) => { firebase.set( `userProfiles/${userId}/phoneNumber`,e.target.value)}}/>
                                </InputGroup>
                                <InputGroup>
                                    <InputLabel>אימייל</InputLabel>
                                    <input
                                        disabled={true}
                                        style={{width: "100%", height: 34}}
                                        value={profile.email}/>
                                </InputGroup>
                                    {providerName && (
                                            <InputGroup>
                                                <InputLabel>Provider</InputLabel>
                                                <input
                                                    disabled={true}
                                                    style={{width: "100%", height: 34}}
                                                    value={providerName}/>
                                            </InputGroup>
                                        )
                                    }
                                <InputGroup>
                                    <InputLabel>הרשאות</InputLabel>
                                    <pre dir={"ltr"} style={{textAlign: "left", backgroundColor: "rgb(235, 235, 228)", border:"1px solid #aeaeae", padding: 10}}>{JSON.stringify(this.filterRoles(roles), null, 4)}</pre>
                                </InputGroup>
                            </Card>
                        </Col>
                        <Col md={4}>

                        </Col>
                    </Row>
                )}

            </div>
        );
    }
}


const Card = styled.div`
    background: #fff;
    padding: 15px;
    margin-top: 17px;
    border: 2px solid #E0E0E0;
    box-sizing: border-box;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    height: calc( 100% - 17px);
    position: relative;
    `;

const CardTitle = styled.div`
    font-family: 'Rubik', sans-serif;
    font-size: 24px;
    color: #BDBDBD;
`;

const InputLabel = styled.div`
    font-family: 'Rubik', sans-serif;
    font-size: 14px;
    color: #4F4F4F;
    margin-bottom: 6px; 
`;

const InputGroup = styled.div`
  margin-top: 10px;
`;

Profile.propTypes = {
    userId: PropTypes.string.isRequried
};

const mapStateToProps = (state, props) => {
    return {
        profile: getVal(state.firebase.data, `userProfiles/${props.userId}`),
        roles: state.roles.roles
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default compose(
    firebaseConnect(props => {
            console.log("firebase connect profile", props.userId);
            return [`userProfiles/${props.userId}`]
        }
    ),
    connect(
        mapStateToProps, mapDispatchToProps
    )
)(Profile);