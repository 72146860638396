import React, {Component} from 'react';
import PropTypes from 'prop-types'

const __html = require('../Register/terms.html');
const termsTemplate = { __html: __html };

class Terms extends Component{

    constructor() {
        super()
    }

    render() {
        return (
            <div dangerouslySetInnerHTML={termsTemplate}/>
        )
    }
}

Terms.propTypes = {};

export default Terms;