import React, { Component } from 'react';
import { Route, Switch } from "react-router-dom";
import ROUTES from "../../constants/routes";
import {getWithSupplierPOs} from "shared/src/HOC/PO/index"
import {firebaseConnect} from "react-redux-firebase";

import { ORDER_MANAGEMENT_CONTEXT} from "shared/src/constants/orders"

import POList from "./list";
import POForm from "./form";

import ContractorList from "./contractor/list"
import ContractorForm from "./contractor/form"

const SupplierList = getWithSupplierPOs(firebaseConnect)(POList);
const SupplierForm = getWithSupplierPOs(firebaseConnect)(POForm);

class Nav extends Component {

    render() {
        const {context} = this.props;
        let List = null;
        let Form = null;
        if(context === ORDER_MANAGEMENT_CONTEXT.CONTRACTOR){
            List = ContractorList;
            Form = ContractorForm;
        }else if(context === ORDER_MANAGEMENT_CONTEXT.SUPPLIER){
            List = SupplierList;
            Form = SupplierForm;
        }
        return (
            <div>
                {List !== null && (
                    <Switch>
                        <Route exact path={ROUTES.PO_LIST} name="PO List" render={(props) => (<List {...this.props}/>)}/>
                        <Route path={ROUTES.PO} name="PO Form" render={(props) => (<Form  {...this.props}/>)}/>
                    </Switch>
                )}
            </div>
        );
    }
}

export default Nav;