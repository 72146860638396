import React, { Component } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux';

import { firebaseConnect, isLoaded } from 'react-redux-firebase'
import { compose } from 'redux'
import { cloneDeep, get, orderBy } from "lodash"
import { uuidWithoutSpace } from "shared/src/utils";

import { Row, Col } from "reactstrap"
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import FontIcon from 'material-ui/FontIcon';
import styled from "styled-components";

import StickyFloatingButton from "../../components/StickyFloatingButton"


class Suppliers extends Component {

    constructor(props) {
        super(props);

        this.defaultSupplier = {
            id: null,
            location: {
                address: ""
            },
            name: ""
        }

        this.state = {
            isModalOpen: false,
            modalContext: null,
            modalSupplier: this.defaultSupplier,
        }


    }

    renderSupplier = (id, supplier) => {

        console.log("renderSupplier", id, supplier)
        console.log(this.props.firebase)

        return (
            <Card onClick={() => {
               // this.openSupplierModal(Object.assign({}, this.defaultSupplier, supplier, {id}))
                this.props.history.push(`/suppliers/${id}`);
            }}>
                <Row>
                    <Col md={6}>
                        <DetailsRow>
                            <FontIcon style={{fontSize: 18}} color={"#636363"}
                                      className={"material-icons"}>person</FontIcon>
                            <Detail>
                                {supplier.name}
                            </Detail>
                        </DetailsRow>

                        <DetailsRow>
                            <FontIcon style={{fontSize: 18}} color={"#636363"}
                                      className={"material-icons"}>place</FontIcon>
                            <Detail>{supplier.location && supplier.location.address ? supplier.location.address : "NA"}</Detail>
                        </DetailsRow>

                        <DetailsRow>
                            <FontIcon style={{fontSize: 18}} color={"#636363"}
                                      className={"material-icons"}>phone</FontIcon>
                            <Detail>{supplier.phoneNumber || "NA"}</Detail>
                        </DetailsRow>

                    </Col>
                </Row>
            </Card>
        )
    }

    openSupplierModal = (supplier) => {
        this.setState({
            isModalOpen: true,
            modalSupplier: supplier
        })
    }

    onEdit = () => {

    }

    onCreact = () => {
    }

    renderSuppliers = () => {
        const {suppliers} = this.props;
        let toRender = [];
        const list = [];

        for(let key in suppliers){
            const name = suppliers[key].name;
            list.push({name, key})
        }
        const sorted = orderBy(list, ["name"]);
        if (sorted) {
            toRender = sorted.map((item, index)=>{ return this.renderSupplier(item.key, suppliers[item.key])})
               // toRender.push(this.renderSupplier(key, suppliers[key]))
        }
        return toRender;
    }

    render() {

        const {isModalOpen, modalSupplier} = this.state;
        const {firebase} = this.props;

        return (
            <div>
                <div>
                    {this.renderSuppliers()}
                </div>
                <StickyFloatingButton
                    icon={"add"}
                    onPress={() => {
                        const newId = uuidWithoutSpace();
                        this.props.history.push(`/suppliers/${newId}`);
                       // this.openSupplierModal(this.defaultSupplier)
                    }}
                />
                <Modal isOpen={this.state.isModalOpen}>
                    <ModalHeader>
                        <span>{modalSupplier.id ? "עריכת ספק" : "יצירת ספק"}</span>
                        <ModalCloseButtonWrapper onClick={() => {
                            this.setState({isModalOpen: false})
                        }}>
                            <FontIcon color={"#636363"} className={"material-icons"}>close</FontIcon>
                        </ModalCloseButtonWrapper>
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col md={6}>
                                <InputGroup>
                                    <InputLabel>
                                        שם
                                    </InputLabel>
                                    <input style={{width: "100%", height: 34}} type={"text"} onChange={(e) => {
                                        const supplier = cloneDeep(modalSupplier);
                                        supplier.name = e.target.value;
                                        this.setState({modalSupplier: supplier})
                                    }}
                                           value={modalSupplier.name}
                                    />
                                </InputGroup>
                            </Col>
                            <Col md={6}>
                                <InputGroup>
                                    <InputLabel>
                                        כתובת
                                    </InputLabel>
                                    <input style={{width: "100%", height: 34}} type={"text"} onChange={(e) => {
                                        const supplier = cloneDeep(modalSupplier);
                                        supplier.location.address = e.target.value;
                                        this.setState({modalSupplier: supplier})
                                    }}
                                           value={modalSupplier.location.address}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <InputGroup>
                                    <InputLabel>
                                        טלפון
                                    </InputLabel>
                                    <input style={{width: "100%", height: 34}}
                                           type={"text"}
                                           onChange={(e) => {
                                               const supplier = cloneDeep(modalSupplier);
                                               supplier.phoneNumber = e.target.value;
                                               this.setState({modalSupplier: supplier})
                                           }}
                                           value={modalSupplier.phoneNumber}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>

                    </ModalBody>
                    <ModalFooter>
                        <SendButton onClick={() => {
                            let id = modalSupplier.id;
                            if (!modalSupplier.id) {
                                // edit
                                id = uuidWithoutSpace();
                            }
                            firebase.set(`suppliers/${id}`, modalSupplier, (result) => {
                                console.log("complete save supplier", result)
                            });
                            this.setState({isModalOpen: false, modalSupplier: this.defaultSupplier});
                        }}>שמירה</SendButton>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

Suppliers.propTypes = {
    suppliers: PropTypes.object,
    createSupplierHandler: PropTypes.func,
    editSupplierHandler: PropTypes.func,
};

Suppliers.defaultProps = {};

const mapStateToProps = (state) => {
    return {
        suppliers: state.firebase.data.suppliers
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

const Card = styled.div`
    background: #fff;
    padding: 15px;
    margin-top: 17px;
    border: 2px solid #E0E0E0;
    box-sizing: border-box;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    position: relative;
    cursor: pointer;
    `;

const DetailsRow = styled.div`
    font-family: 'Rubik', sans-serif;
    font-size: 18px;
    color: #636363;
`;

const Detail = styled.span`
    font-family: 'Rubik', sans-serif;
    font-size: 18px;
    color: #636363;
    margin-right: 7px;
    vertical-align: text-bottom;
`;

const InputLabel = styled.div`
font-family: 'Rubik', sans-serif;
font-size: 14px;
color: #4F4F4F;
margin-bottom: 6px;
`;

const InputGroup = styled.div`
margin-top: 10px;
`;

const SendButton = styled.div`
border-radius: 3px;
font-family: 'Rubik', sans-serif;
font-size: 16px;
text-align: center;
color: #FFFFFF;
height: 50px;
line-height: 50px;
padding: 0 11px;
cursor:  ${props => props.disabled ? "default" : "pointer"};
box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1);
background-color: ${props => props.disabled ? "#818181" : "#319CD8"};
`;

const ModalCloseButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
`;


export default compose(
    firebaseConnect([
        'suppliers'
    ]),
    connect(
        mapStateToProps, mapDispatchToProps
    )
)(Suppliers);
