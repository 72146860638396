import React, {Component} from 'react';
import PropTypes from 'prop-types'
import moment from "moment";

import {PRODUCT_ACTION_TYPES} from "shared/src/constants/orders";

class OrderDraftPrint extends Component{

    constructor() {
        super()
    }

    renderOrder =(showOnScreen, shouldBreakPage)=>{
        const {
            items,
            deliveryDate,
            customerName,
            whoOrdered,
            address,
            contactPhoneNumber,
            contactPersonInSite,
            notes,
            anythingElse,
            supplierNotes
        } = this.props;

        return (
            <div dir="rtl" style={{padding: 50, pageBreakBefore: shouldBreakPage ? "always" : "auto", marginTop: 170}} className={showOnScreen ? "" : "d-none d-print-block"}>
                <div style={{textAlign: "center"}}>
                    <strong>- פנימי -</strong>
                </div>
                <div style={{textAlign: "right"}}>
                    <strong>טיוטת הזמנה</strong>
                </div>
                <div>
                    <div style={{textAlign: "left"}}>תאריך: {moment(deliveryDate).format("D/MM/YY")}</div>
                </div>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <div style={{display: "flex", width: "50%"}}>
                        <strong>שם לקוח:</strong>
                        <span style={{marginRight: 10}}>{customerName}</span>
                    </div>
                    <div style={{display: "flex", width: "50%"}}>
                        <strong>שם מזמין:</strong>
                        <span style={{marginRight: 10}}>{whoOrdered}</span>
                    </div>
                </div>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <div  style={{display: "flex", width: "50%"}}>
                        <strong>כתובת למשלוח:</strong>
                        <span style={{marginRight: 10}}>{address}</span>
                    </div>
                    <div  style={{display: "flex", width: "50%"}}>
                        <strong>טלפון:</strong>
                        <span style={{marginRight: 10}}>{contactPhoneNumber}</span>
                    </div>
                </div>
                <div>
                    <div>
                        <strong>איש קשר באתר:</strong>
                        <span style={{marginRight: 10}}>{contactPersonInSite}</span>
                    </div>
                </div>
                <div>
                    <div>
                        <strong>הערות:</strong>
                        <span style={{marginRight: 10}}>{notes}</span>
                    </div>
                </div>
                <div>
                    <div>
                        <strong>משהו נוסף:</strong>
                        <span style={{marginRight: 10}}>{anythingElse}</span>
                    </div>
                </div>
                <div>
                    <div>
                        <strong>הערות ספק:</strong>
                        <span style={{marginRight: 10}}>{supplierNotes}</span>
                    </div>
                </div>
                <div>
                    <div>
                        <table style={{borderCollapse: "collapse", border: "1px solid black", width: "100%"}}>
                            <thead>
                            <tr>
                                <th style={{border: "1px solid black"}}>מק"ט</th>
                                <th style={{border: "1px solid black"}}>שם פריט</th>
                                <th style={{border: "1px solid black"}}>כמות</th>
                            </tr>
                            </thead>
                            <tbody>
                            {items.map((item, index)=>{
                                let count = item.count;
                                if(item.actions && item.actions.length > 0) {
                                    const latestAction = item.actions[item.actions.length - 1];

                                    switch (latestAction.type){
                                        case PRODUCT_ACTION_TYPES.DELAY:
                                        case PRODUCT_ACTION_TYPES.REPLACE:
                                        case PRODUCT_ACTION_TYPES.CANCELED:
                                            return null;
                                        case PRODUCT_ACTION_TYPES.QUANTITY_CHANGE:
                                            let extraAction = latestAction.data.extreAction;
                                            let originalQuantity = latestAction.data.original;
                                            let currentQuantity =  latestAction.data.quantity;
                                            count = currentQuantity;
                                            break;
                                        default:
                                            break;
                                    }
                                }
                                return (
                                    <tr key={index}>
                                        <th style={{border: "1px solid black"}} scope="row">{item.catalogId}</th>
                                        <td style={{border: "1px solid black"}}>{item.name}</td>
                                        <td style={{border: "1px solid black"}}>{count}</td>
                                    </tr>
                                )})}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }

    render() {

        const {
            items,
            isPrinting,
            notes,
            anythingElse,
            supplierNotes
        } = this.props;

        const notesLines = notes ? (notes.match(/\r?\n/g) || '').length + 1 : 1;
        const anythingElseLines = anythingElse ? (anythingElse.match(/\r?\n/g) || '').length + 1 : 1;
        const supplierNotesLines = supplierNotes ? (supplierNotes.match(/\r?\n/g) || '').length + 1 : 1;

        const shouldBreakPage = items.length + notesLines + anythingElseLines + supplierNotesLines > 8;

        return (
            <div className={"A4"} style={{height: "100%"}}>
                <div style={
                    shouldBreakPage ? {} : {
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%"
                }}>
                    {this.renderOrder(true)}
                    {this.renderOrder(false, shouldBreakPage)}
                </div>

            </div>
        )
    }
}

OrderDraftPrint.propTypes = {
    items: PropTypes.array,
    deliveryDate: PropTypes.string,
    customerName: PropTypes.string,
    whoOrdered: PropTypes.string,
    address: PropTypes.string,
    contactPhoneNumber: PropTypes.string,
    contactPersonInSite: PropTypes.string,
    notes: PropTypes.string,
    anythingElse: PropTypes.string,
    supplierNotes: PropTypes.string
};

export default OrderDraftPrint;