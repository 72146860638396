import React from 'react';
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { firebaseConnect, isLoaded } from 'react-redux-firebase'
import _ from "lodash";
import { FormattedMessage, injectIntl } from 'react-intl';

import { ACL, catalogPageSize, PRODUCT_MODES } from "shared/src/constants/config"
import { clearCatalog, getCatalog, setCategories, setSupplier, updateQuery } from "shared/src/modules/catalog";
import {
	addProduct,
	deleteProduct,
	getProduct,
	setMode,
	setProduct,
	setProductRef
} from "shared/src/modules/catalogProduct"
import { getSupplierIds, isCatalogAdmin } from "shared/src/selectors/roles";
import { uuidWithoutSpace } from "shared/src/utils";

import CatalogList from "./CatalogList";
import SupplierSelect from "../../components/Form/FirebaseObjectListSelect";

import { Toolbar, ToolbarGroup, ToolbarSeparator } from 'material-ui/Toolbar';
import FontIcon from 'material-ui/FontIcon';
import IconButton from 'material-ui/IconButton';
import MenuItem from 'material-ui/MenuItem';

import AutoSuggest from "../../components/AutoSuggest";

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import styled from 'styled-components';
import CatalogUploader from "./CatalogUploader";


class CatalogManagement extends React.Component {

	constructor() {
		super();
		this.state = {
			shouldShowSupplierSelect: false,
			page: 0,
			isPageInputOpen: false,
			isModalOpen: false,
			makatQuery: "",
			isMakatSearch: false,
		};

		this.supplierFilter = null;

		this.onAddItem = this.onAddItem.bind(this);
		this.onEditItem = this.onEditItem.bind(this);
		this.onDeleteItem = this.onDeleteItem.bind(this);
		this.onDuplicateItem = this.onDuplicateItem.bind(this);
		this.openModal = this.openModal.bind(this);
		this.confirmDelete = this.confirmDelete.bind(this);
		this.closeModal = this.closeModal.bind(this);

		this.handleSuggestionSelect = this.handleSuggestionSelect.bind(this);
		this.handleMakatSuggestionSelect = this.handleMakatSuggestionSelect.bind(this)
	}

	componentDidMount() {
		this.initRoleSuppliers(this.props.roles);
	}

	initRoleSuppliers( roles ) {
		const { getCatalog, setSupplier, query, catalog } = this.props;
		const catalogAdmin = isCatalogAdmin(roles);
		console.log("catalog admin:", catalogAdmin, roles);
		if (catalogAdmin) {
			console.log("user is admin - need to select supplier");
			this.setState({ shouldShowSupplierSelect: true });
			// getCatalog({supplierId: keys[0]});
		} else {
			if (query) {
				return;
			}
			const suppliers = getSupplierIds(roles, ACL.items);
			if (suppliers.supplierIds.length > 0) {
				console.log("user is supplier - supplierId");
				if (suppliers.supplierIds.length === 1) {
					setSupplier(suppliers.supplierIds[ 0 ]);
					// getCatalog({supplierId: suppliers.supplierIds[0]});
				} else {
					this.supplierFilter = suppliers.supplierIds;
					this.setState({ shouldShowSupplierSelect: true });
				}
			}
		}
	}

	componentDidUpdate( prevProps, prevState, snapshot ) {
		const { suppliers, roles } = this.props;
		if (isLoaded(suppliers) && !_.isEmpty(roles) && (
			!_.isEqual(prevProps.roles, roles) || !_.isEqual(prevProps.suppliers, suppliers)
		)) {
			console.log('Suppliers are loaded: ', suppliers)
			this.initRoleSuppliers(roles);
		}
	}

	fetchCatalog( supplierId ) {
		const { getCatalog } = this.props;
		getCatalog({ supplierId: supplierId });
	}

	onAddItem() {
		const { setProduct, setMode, addProduct, supplierId } = this.props;
		const id = uuidWithoutSpace();
		console.log("onAddItem")
		setProduct({ id });
		// addProduct(`itemsBySupplier/${supplierId}/${id}/data`);
		setMode(PRODUCT_MODES.ADD);
		// setMode(PRODUCT_MODES.EDIT);

		// setIsEdit(false)
		// const isEdit = false
		this.props.history.push(`/catalog-management/${ supplierId }/${ id }`)
		// this.props.history.push(`/catalog-management/${ supplierId }/${ id }/${ PRODUCT_MODES.ADD }`)
	}

	onEditItem( item ) {
		const { setProduct, setMode, getProduct } = this.props;
		const { supplierId } = this.props;
		let productRef = null;
		console.log("onEditItem")
		productRef = this.transformProductRef(item, productRef);
		console.log("getting item", item, productRef)
		//    getProduct({path: `/itemsBySupplier/${supplierId}/${item.id}/data`, productRef});
		//  setProduct(item);
		setMode(PRODUCT_MODES.EDIT);
		// setIsEdit(true)
		// const isEdit = true
		this.props.history.push(`/catalog-management/${ supplierId }/${ item.id }`);
		// this.props.history.push(`/catalog-management/${ supplierId }/${ id }/${ PRODUCT_MODES.EDIT }`)
	}

	transformProductRef( item, productRef ) {
		if (item.productRef) {
			productRef = {
				item: Object.assign({}, _.cloneDeep(item), { id: item.productRef }),
				supplier: item.productRef.supplier
			}
			delete productRef.item[ "catalogId" ];
			delete productRef.item[ "default_price" ];
			delete productRef.item[ "productRef" ];
		}

		console.log("transformProductRef")
		console.log("productRef", productRef)
		return productRef;
	}

	onDuplicateItem( item ) {
		const { setMode, getProduct, addProduct } = this.props;
		const { supplierId } = this.props;
		const newProductId = uuidWithoutSpace();
		let productRef = null;
		productRef = this.transformProductRef(item, productRef);

		addProduct(
			`itemsBySupplier/${ supplierId }/${ newProductId }/data`,
			Object.assign({}, item, { id: newProductId })
		);
		getProduct({
			path: `/itemsBySupplier/${ supplierId }/${ item.id }/data`,
			isDuplicate: true,
			newProductId,
			productRef
		});
		setMode(PRODUCT_MODES.EDIT);
		// setIsEdit(true)
		// const isEdit = true
		this.props.history.push(`/catalog-management/${ supplierId }/${ newProductId }`);
	}

	goToPage( pageNumber ) {
		const { getCatalog, query, supplier } = this.props;
		const { isMakatSearch } = this.state;

		getCatalog({
			query,
			from: pageNumber * catalogPageSize,
			supplierId: supplier,
			isMakat: isMakatSearch
		});

		this.setState({ page: pageNumber });
	}

	nextPage() {
		// const {getCatalog, query, supplier} = this.props;
		const {
			      page,
			      // isMakatSearch
		      } = this.state;

		// getCatalog({
		//     query, from: ((page + 1) * catalogPageSize), supplierId: supplier, isMakat: isMakatSearch
		// });
		// this.setState({page: page + 1});

		this.goToPage(page + 1)
	}

	prevPage() {
		// const {getCatalog, query, supplier} = this.props;
		const {
			      page,
			      // isMakatSearch
		      } = this.state;

		// getCatalog({query, from: ((page - 1) * catalogPageSize), supplierId: supplier, isMakat: isMakatSearch});
		// this.setState({page: page - 1});

		this.goToPage(page - 1)
	}

	onDeleteItem( item ) {
		console.log("delete item", item.id);
		this.openModal(item);
	}


	openModal( item ) {
		this.setState({
			isModalOpen: true,
			itemToDelete: item
		})
	}

	confirmDelete() {
		const { itemToDelete } = this.state;
		const { deleteProduct, supplierId } = this.props;
		deleteProduct(supplierId, itemToDelete.id);
		this.closeModal();
		//  this.fetchCatalog(supplierId);
	}

	closeModal() {
		this.setState({
			isModalOpen: false,
			itemToDelete: null
		})
	}

	changeSupplier( supplierId ) {
		const { setSupplier } = this.props;
		this.setState({ page: 0 });
		//   this.fetchCatalog(supplierId);
		setSupplier(supplierId);
	}

	renderSuppliers( suppliers ) {
		let list = [];
		let filterdSuppliers = suppliers;
		if (this.supplierFilter) {
			filterdSuppliers = {};
			this.supplierFilter.map(suppKey => {
				filterdSuppliers[ suppKey ] = suppliers[ key ]
			})
		}

		for (let key in filterdSuppliers) {
			list.push((
				<MenuItem key={ `supp_key_${ key }` } value={ key } primaryText={ suppliers[ key ].name }/>
			))
		}
		return list;
	}

	handleSuggestionSelect( query ) {
		const supplierId = this.props.supplierId;
		this.updateMakatQuery("");
		this.updateQuery(query);
		this.props.getCatalog({
			query,
			supplierId,
			from: 0
		});
		this.setState({ page: 0, isMakatSearch: false });
	}

	handleMakatSuggestionSelect( query ) {
		const supplierId = this.props.supplierId;
		this.updateQuery("");
		this.updateMakatQuery(query);
		this.props.getCatalog({
			isMakat: true,
			query,
			supplierId,
			from: 0
		});
		this.setState({ page: 0, isMakatSearch: true });
	}

	updateQuery = ( query ) => {
		// this.setState({makatQuery: ""})
		this.props.updateQuery(query);
	}

	updateMakatQuery = ( value ) => {
		// this.props.updateQuery("");
		this.setState({
			// query: "",
			makatQuery: value,
		});
	};

	render() {

		const {
			      suppliers,
			      // updateQuery,
			      // getCatalog,
			      query,
			      catalog,
			      didSearched,
			      isLoading,
			      // categories,
			      totalItems,
			      supplierId,
			      accountToken,
			      // setSupplier,
			      intl
		      } = this.props;
		const {
			      makatQuery,
			      shouldShowSupplierSelect,
			      page,
			      isPageInputOpen,
			      itemToDelete,
		      } = this.state;

		return (
			<div className="animated fadeIn">
				<CatalogHeader>
					<div style={ { width: "30%" } }>
						<AutoSuggest
							query={ query }
							supplierId={ supplierId }
							onChange={ this.updateQuery }
							onSelection={ this.handleSuggestionSelect }
							placeholder={ 'חיפוש שם מוצר' }
						/>
					</div>
					<div style={ { width: "30%" } }>
						<AutoSuggest
							isMakat={ true }
							query={ makatQuery }
							supplierId={ supplierId }
							onChange={ this.updateMakatQuery }
							onSelection={ this.handleMakatSuggestionSelect }
							placeholder={ 'חיפוש מק"ט' }
						/>
					</div>
					<Toolbar>
						<ToolbarSeparator/>
						{
							<div style={ { display: "flex", flexDirection: "row" } }>
								<ToolbarGroup>
									<IconButton tooltip="next" onClick={ () => {
										this.nextPage()
									} } disabled={ (
										page + 1
									) * catalogPageSize >= totalItems }>
										<FontIcon className="material-icons">navigate_next</FontIcon>
									</IconButton>
									{ isPageInputOpen && !!catalog && catalog.length !== 0
										? (
											<input
												style={ {
													maxWidth: 50,
													textAlign: "center",
												} }
												autoFocus={ true }
												type={ "number" }
												dir={ "ltr" }
												value={ page + 1 }
												onChange={ e => {
													let value = Number(e.target.value) - 1;

													if (value < 0) {
														value = 0
													} else if (value !== Math.floor(value)) {
														value = Math.floor(value)
													}

													this.setState({ page: value })
												} }
												onKeyPress={ e => {
													if (e.key === "Enter") {
														this.setState({ isPageInputOpen: false })
														this.goToPage(page)
													}
												} }
												onBlur={ () => {
													this.setState({ isPageInputOpen: false })
													this.goToPage(page)
												} }
											/>
										) : (
											<div onClick={ () => {
												this.setState({ isPageInputOpen: true })
											} }>
												{ page + 1 }
											</div>
										)
									}
									<IconButton tooltip="prev" onClick={ () => {
										this.prevPage()
									} } disabled={ page === 0 }>
										<FontIcon className="material-icons">navigate_before</FontIcon>
									</IconButton>
								</ToolbarGroup>
								<ToolbarSeparator/>
							</div>
						}
						<ToolbarGroup lastChild={ !shouldShowSupplierSelect }>
							<IconButton tooltip="add product" onClick={ this.onAddItem }>
								<FontIcon className="material-icons">add</FontIcon>
							</IconButton>
						</ToolbarGroup>
						{ shouldShowSupplierSelect && (
							<ToolbarSeparator/>
						) }
						{ shouldShowSupplierSelect && (
							<ToolbarGroup lastChild={ shouldShowSupplierSelect }>
								<SupplierSelect
									list={ suppliers }
									onChange={ ( value ) => this.changeSupplier(value) }
									value={ supplierId }
								/>
							</ToolbarGroup>
						) }

						<ToolbarSeparator/>
						<div
							style={ {
								display: "flex",
								flex: 1,
								padding: "0 4px",
								alignItems: "center",
							} }
						>
							{ shouldShowSupplierSelect && !!supplierId && (
								<CatalogUploader accountToken={ accountToken } supplierId={ supplierId }/>
							) }
						</div>
					</Toolbar>
				</CatalogHeader>
				<CatalogList
					list={ catalog }
					totalItems={ totalItems }
					// categories={ categories }
					onItemEdit={ this.onEditItem }
					onItemDelete={ this.onDeleteItem }
					onDuplicateItem={ this.onDuplicateItem }
					isLoading={ isLoading }
					didSearched={ didSearched }
					emptyListMessage={ intl.formatMessage({ id: "catalog.list.emptyMessage" }) }
				/>


				{ shouldShowSupplierSelect && !supplierId && (
					<Aligner>
						<MiddleSelect>
							<h4>{ intl.formatMessage({ id: "supplier.select.title" }) }</h4>
							<SupplierSelect
								list={ suppliers }
								onChange={ ( value ) => this.changeSupplier(value) }
								value={ supplierId }
							/>
						</MiddleSelect>
					</Aligner>

				) }

				<Modal isOpen={ this.state.isModalOpen } className={ 'modal-danger ' }>
					<ModalHeader><FormattedMessage id="product.deleteModal.title"/></ModalHeader>
					<ModalBody>
						<FormattedMessage id="product.deleteModal.question"/>
						{ itemToDelete ? itemToDelete.name : "" } ?
					</ModalBody>
					<ModalFooter>
						<Button color="danger" onClick={ this.confirmDelete }><FormattedMessage
							id="product.deleteModal.confirm"/></Button>{ ' ' }
						<Button color="secondary" onClick={ this.closeModal }><FormattedMessage
							id="product.deleteModal.cancel"/></Button>
					</ModalFooter>
				</Modal>
			</div>
		)
	}
}

const Aligner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MiddleSelect = styled.div`
  max-width: 50%;
  border: 1px dashed;
  margin-top: 100px;
  padding: 10px;
`;

const CatalogHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: rgb(232, 232, 232);
`;

const mapStateToProps = state => (
	{
		accountToken: state.account.token,
		query: state.catalog.query,
		results: state.catalog.results,
		supplierId: state.catalog.supplier,
		isLoading: state.catalog.isLoading,
		catalog: state.catalog.catalog,
		totalItems: state.catalog.totalItems,
		supplier: state.catalog.supplier,
		// categories: state.catalog.categories,
		suppliers: state.firebase.data.suppliers,
		roles: state.roles.roles,
		didSearched: state.catalog.didSearched
	}
)

const mapDispatchToProps = dispatch => bindActionCreators({
	updateQuery,
	getCatalog,
	setProduct,
	setSupplier,
	setCategories,
	setMode,
	deleteProduct,
	getProduct,
	addProduct,
	setProductRef,
	clearCatalog
}, dispatch);

export default compose(
	firebaseConnect([
		'suppliers'
	]),
	connect(
		mapStateToProps, mapDispatchToProps
	)
)(injectIntl(CatalogManagement))
