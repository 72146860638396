import React, { Component } from "react";
import PropTypes from "prop-types";
import { api } from "shared/src/constants/api";
import { noProductImageUrl, ORDER_MANAGEMENT_CONTEXT } from "shared/src/constants/orders";
import { CATALOG_TYPES } from "shared/src/modules/catalog";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import Autosuggest from "react-autosuggest";

import SearchInput from "../components/Search/SearchInput";
import { colors } from "../styles/styleguide";
import { getSuggestions as getSuggestionsFactory, PRODUCT_SUGGESTERS, SEARCH_TERM } from "shared/src/utils/suggestions";

// const PRODUCT_SUGGESTERS = "product"
// const FAMILY_SUGGESTERS = "family"
// const SEARCH_TERM = "searchTerm"

const SUGGESTION_ROW_HEIGHT = "50px"

class AutoSuggest extends Component {
  constructor() {
    super();
    this.state = {
      items: [],
      query: "",
    };
  }

  loadSuggestions = (q) => {
    if (q.length === 0) {
      this.setState({ items: [] });
      return;
    }
    const { supplierId, contractorId, isMakat, context } = this.props;
    let search = null;
    if (isMakat) {
      if (contractorId) {
        search = api.makatSuggestion(q, supplierId, contractorId);
      } else {
        search = api.makatSuggestion(q, supplierId);
      }
    } else {
      if (contractorId) {
        search = api.searchSuggestion(q, supplierId, contractorId);
      } else {
        search = api.searchSuggestion(q, supplierId);
      }
    }


    search.then((data) => {
        // const items = [];
        //
        // if (isMakat) {
        //     items.push({
        //         title: "מוצרים",
        //         type: PRODUCT_SUGGESTERS,
        //         items: data.map(item => ({
        //             title: item.text,
        //             image: item.image || noProductImageUrl,
        //             name: item.name,
        //         }))
        //     })
        // } else {
        //     items.push({
        //         title: "מוצרים",
        //         type: PRODUCT_SUGGESTERS,
        //         items: data.product.map(item => ({
        //             title: item.text,
        //             image: item.image || noProductImageUrl,
        //         }))
        //     })
        //
        //     if (!!data.family) {
        //         items.push({
        //             title: "קטגוריות",
        //             type: FAMILY_SUGGESTERS,
        //             items: data.family.map(item => ({
        //                 title: item.text,
        //             }))
        //         })
        //     }
        // }
        //
        // items.push({
        //     title: "חיפוש כללי",
        //     type: SEARCH_TERM,
        //     items: [{
        //         title: "המשך חיפוש",
        //         image: "img/icons/search_round.svg",
        //     }]
        // })

      const items =
                getSuggestions({ data, isMakat })
                    .map(section => ({
                      title: section.title,
                      index: section.index,
                      type: section.type,
                      items: section.data
                    }))

        console.log("items", items)

      this.setState({ items });
    });
  };

    getSectionSuggestions = section => section.items

  getSuggestionValue = (suggestion) => suggestion.title;

  onChange = (event, { newValue }) => {
    this.props.onChange(newValue);
  };

  onSuggestionsFetchRequested = ({ value, reason }) => {
    this.loadSuggestions(value);
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      items: [],
    });
  };

  onSuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
      const { items } = this.state

      if (items[sectionIndex].type === SEARCH_TERM) {
          this.props.onSelection(this.props.query);
      } else {
          this.props.onSelection(this.getSuggestionValue(suggestion));
      }
  };

  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      this.props.onSelection(this.props.query);
    }
  };

  handleFocus = (e) => {
    e.target.select();
  };

  renderSuggestion = (suggestion, { query }) => {
    const suggestionText = this.getSuggestionValue(suggestion);

    let matches = [];
    let parts;

    if (suggestion.type !== SEARCH_TERM) {
      matches = match(suggestionText, query);
      parts = parse(suggestionText, matches);
    } else {
      parts = parse(suggestionText, matches);
    }

    const isImage = !!suggestion.image

    return (
      <div
          className={"suggestion-content"}
          style={{
            display: "flex",
            alignItems: "center",
            // height: SUGGESTION_ROW_HEIGHT
          }}
      >
          {isImage && (
              <div
                  style={{
                      // height: SUGGESTION_ROW_HEIGHT,
                      // width: SUGGESTION_ROW_HEIGHT,
                      display: "flex",
                      flex: 1,
                      justifyContent: "center",
                      alignItems: "center"
                  }}
              >
                  <img
                      className={"img-container"}
                      // style={{
                      //   // height: SUGGESTION_ROW_HEIGHT,
                      //   width: SUGGESTION_ROW_HEIGHT
                      // }}
                      src={suggestion.image}
                  />
              </div>
          )}

          {isImage && <div style={{ margin: "4px" }} />}

        <div
            style={{
              display: "flex",
              flexDirection: "row",
              flex: 6,
              // height: SUGGESTION_ROW_HEIGHT
            }}
        >
          <span>
            {parts.map((part, index) => (
                <span className={part.highlight ? "highlight" : null} key={index}>
                  {part.text}
                </span>
            ))}
          </span>

          <div style={{ margin: "4px" }} />

          <span>
            {suggestion.name}
          </span>
        </div>
      </div>
    );
  };

  renderInputComponent = (inputProps) => (
    <SearchInput
      inputStyle={this.props.inputStyle}
      iconSrc={this.props.iconSrc || "img/icons/search.svg"}
      inputProps={inputProps}
    />
  );

  renderSuggestionsContainer = ({ containerProps, children, query }) => {
    return (
      <div
        {...containerProps}
        style={{ position: "absolute", left: 0, right: 0 }}
      >
        {children}
      </div>
    );
  };

  render() {
    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: this.props.placeholder,
      value: this.props.query,
      onChange: this.onChange,
      onKeyPress: this.handleKeyPress,
      onFocus: this.handleFocus,
    };

    return (
      <div style={{ position: "relative", width: "100%" }}>
        <Autosuggest
            multiSection={true}
            suggestions={this.state.items}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            getSuggestionValue={this.getSuggestionValue}
            renderSuggestion={this.renderSuggestion}
            inputProps={inputProps}
            onSuggestionSelected={this.onSuggestionSelected}
            renderInputComponent={this.renderInputComponent}
            renderSuggestionsContainer={this.renderSuggestionsContainer}
            renderSectionTitle={SectionSeparator}
            getSectionSuggestions={this.getSectionSuggestions}
        />
      </div>
    );
  }
}

const SectionSeparator = (section) => (
    <div
        style={{
          height: 2,
          width: "95%",
          margin: "8px",
          alignSelf: "center",
          backgroundColor: colors.yellow
        }}
    />
)

const getSuggestions = getSuggestionsFactory({
  noImageIcon: noProductImageUrl,
  searchIcon: "img/icons/search_round.svg",
  isMobile: false
})


AutoSuggest.propTypes = {
  onChange: PropTypes.func,
  query: PropTypes.string,
  onSelection: PropTypes.func,
  supplierId: PropTypes.string,
  contractorId: PropTypes.string,
  placeholder: PropTypes.string,
  iconSrc: PropTypes.string,
  // isMakat: PropTypes.boolean,
};

AutoSuggest.defaultProps = {
  placeholder: "חיפוש בקטלוג",
  inputStyle: null,
  isMakat: false,
};

export default AutoSuggest;
