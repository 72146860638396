import React, { Component } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux';
import {connectOrders } from "shared/src/api/driverOrders";
import { fetchDrItems } from "shared/src/api/orderItems";

import { Switch, Route, Redirect } from 'react-router-dom';


import ROUTES from "../../constants/routes"

import Deliveries from "./deliveries"
import Delivery from "./delivery"

class DeliveriesIndex extends Component {

    componentDidMount(){
        this.fetchScreenData();
    }

    fetchScreenData() {
        const {connectOrders} = this.props;
        connectOrders();
    }

    render() {

        const {orders} = this.props;

        return (
            <div>
                <Switch>
                    <Route exact path={ROUTES.DELIVERIES} render={(props)=>(<Deliveries orders={orders.reverse()} {...props}/>)}/>
                    <Route exact path={ROUTES.DELIVERY} component={Delivery}/>
                </Switch>
            </div>

        );
    }
}

DeliveriesIndex.propTypes = {
    orders: PropTypes.array,
    connectOrders: PropTypes.func,
    fetchDrItems: PropTypes.func
};

DeliveriesIndex.defaultProps = {};

const mapStateToProps = (state) => {
    return {
        orders: state.deliveries.orders
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({
    connectOrders,
    fetchDrItems
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DeliveriesIndex);