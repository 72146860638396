import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux';
import _ from "lodash"
import matchSorter from 'match-sorter'
import memoize from 'memoize-one';
import { fetchContractItems, fetchContractWithItems, saveDiscount, setFieldValue } from "shared/src/modules/priceList"
import {roundFloatNumberWithoutTrailingZeroes} from "shared/src/utils/Utils"
import ROUTES from "../../constants/routes";
import {getRoutParams} from "../../utils/navigtion"

import { Row, Col } from "reactstrap"
import { toastr } from 'react-redux-toastr'
import FontIcon from 'material-ui/FontIcon';
import Loader from "../../components/Loader";
import SearchField from "../search/SearchField";


import {
    Card,
    CartHeader,
    InputLabel,
    InputGroup,
    Button, InputError
} from "../../styles/stylesheet"
import {  ORDER_MANAGEMENT_CONTEXT } from "shared/src/constants/orders";
import IconInput from "../../components/Form/IconInput";

import Products from "./products"



class Contract extends Component {

    constructor(){
        super()
        this.state = {
            query: "",
            isValidDiscount: true
        }

        this.saveDiscount = _.debounce(this.saveDiscount, 2000, {'leading': true, 'trailing': false})
    }

    componentDidMount() {
        const {selected, fetchContractItems, fetchContractWithItems, context} = this.props;
        if(!selected.supplier || !selected.contractor){
            if (context) {
                const {supplierId, contractorId} = getRoutParams(ROUTES.PRICE_LIST_ITEMS)
                fetchContractWithItems(supplierId, contractorId)
            }
        }else {
            fetchContractItems(selected.supplier.id, selected.contractor.id)
        }

    }

    componentDidUpdate(prevProps, prevState) {
        const {selected, fetchContractItems, fetchContractWithItems, context} = this.props;
        if((!selected.supplier || !selected.contractor) && context && !prevProps.context){
            const {supplierId, contractorId} = getRoutParams(ROUTES.PRICE_LIST_ITEMS)
            fetchContractWithItems(supplierId, contractorId)
        }
    }

    filter = memoize(
        (list, filterText) => {
            return matchSorter(list || [], filterText, {keys: [`name`, "catalogId"]})
        }
    );

    convertDiscountToString = (value) => {
        if(value){
            return roundFloatNumberWithoutTrailingZeroes((value * 100), 2)
        }
        return 0;
    }

    convertDiscountStringToValue = (string) => {
        const {setFieldValue} = this.props;

        let num = parseFloat(string) / 100;
        setFieldValue("selected.discount", num);

    }

    validateDiscount = (discount) => {
        if(isNaN(discount) || discount < 0 || discount > 1){
            this.setState({isValidDiscount: false})
            return false
        }
        return true
    }

    saveDiscount = () => {
        const {selected, saveDiscount} = this.props;
        if(!this.validateDiscount(selected.discount)){
            return;
        }
        saveDiscount(selected.supplier.id, selected.contractor.id, selected.discount).then(()=>{
            toastr.success("ההנחה עודכנה בהצלחה")
            this.props.history.push(ROUTES.PRICE_LIST)
        }).catch(e=>{
            toastr.error("לא הצלחנו לעדכן את ההנחה, אנא נסו שוב")
            console.error("error updating discount",e)
        })
    }


    render() {
        const {selected, isLoadingItems, isLoading, context} = this.props;
        const {query, isValidDiscount} = this.state;
        console.log("render contract", selected, isLoadingItems)
        let title = ""
        if(context === ORDER_MANAGEMENT_CONTEXT.SUPPLIER){
            title = _.get(selected, "contractor.name", "")
        }else {
            title = _.get(selected, "supplier.name", "")
        }

        const filtered = this.filter(selected.products, query);

        return (
            <div>
                <Card>
                    <CartHeader>
                        {`מחירון ${title}`}
                    </CartHeader>
                    {context === ORDER_MANAGEMENT_CONTEXT.CONTRACTOR && !selected.discount ? null : (
                        <Row>
                            <Col md={2}>
                                <InputGroup>
                                    <InputLabel>הנחה קבועה (באחוזים)</InputLabel>
                                    <IconInput
                                        type={"number"}
                                        value={this.convertDiscountToString(selected.discount)}
                                        disabled={context === ORDER_MANAGEMENT_CONTEXT.CONTRACTOR}
                                        icon={<img style={{width: 25, height: 25}} src={"img/icons/percent.svg"}/>}
                                        onChange={(value)=>{this.convertDiscountStringToValue(value)}}
                                    />
                                    {!isValidDiscount && (<InputError>יש להזין ערך בין 0 ל 100</InputError>)}
                                </InputGroup>
                            </Col>
                            {context === ORDER_MANAGEMENT_CONTEXT.SUPPLIER && (
                                <Col md={2} className={"align-self-end"}>
                                    <Button onClick={this.saveDiscount}>שמירה</Button>
                                </Col>
                            )}
                        </Row>
                    )}
                </Card>

                <Card>
                    <CartHeader>
                        <Row>
                            <Col md={2}>
                                {`מוצרים (${_.get(selected, "products.length", 0)})`}
                            </Col>
                        </Row>

                    </CartHeader>
                    <Row>
                        <Col>
                            <SearchField
                                onSubmit={()=>{}}
                                label={'חיפוש לפי שם או מק"ט'}
                                onChange={(value)=>{
                                    this.setState({query: value})
                                }}
                                value={query}
                            />
                        </Col>
                    </Row>
                    <Products items={filtered}/>
                </Card>
            </div>
        );
    }
}

Contract.propTypes = {};

Contract.defaultProps = {};

const mapStateToProps = (state) => {
    const context = state.ordersManagement.context;
    return {
        context,
        selected: state.priceList.selected,
        isLoadingItems: state.priceList.isLoadingItems,
        isLoading: state.priceList.isLoading,
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchContractItems,
    fetchContractWithItems,
    saveDiscount,
    setFieldValue
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Contract);