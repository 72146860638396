import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Col, Modal, ModalBody, Row } from "reactstrap";
import Select from "react-select";
import styled from "styled-components";

import RoundButton from "./RoundButton"
import {colors, fonts} from "../styles/styleguide";

class SuccessModal extends PureComponent {
    render() {
        const {isOpen,
            onSubmit,
            onClose,
            message,
            messageBold
        } = this.props;
        return (
            <Modal isOpen={isOpen} toggle={onClose}>
                <ModalBody>
                    <Row>
                        <Col md={12}>
                            <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                                <img src={"img/icons/like.png"}/>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                                <div style={{
                                    fontFamily: fonts.OpenSansHebrew,
                                    fontSize: 19.8,
                                    color: "#2e423d"
                                }}>{message}</div>
                                <div style={{
                                    fontFamily: fonts.OpenSansHebrew,
                                    fontWeight: 800,
                                    fontSize: 19.8,
                                    color: "#2e423d",
                                    marginRight: 5
                                }}>{messageBold}</div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <RoundButton onClick={onSubmit} label={"אישור"}/>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        );
    }
}

SuccessModal.propTypes = {
    isOpen: PropTypes.bool,
    onSubmit: PropTypes.func,
    onClose: PropTypes.func,
    message: PropTypes.string,
    messageBold: PropTypes.string,
};

SuccessModal.defaultProps = {
    onSubmit: ()=>{},
    onClose: ()=>{}
}

export default SuccessModal;