import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";
import { colors, fonts } from "../../../styles/styleguide";

class AddToList extends Component {

    constructor(props){
        super(props);
        this.state = {
            value: _.cloneDeep(props.defaultValue)
        }
    }

    setValue = (value) => {
        const {
            disabled,
            onChange
        } = this.props;

        if(disabled){
            return
        }
        this.setState({value})
        onChange(value)
    }

    addHandler = () => {
        const {value} = this.state
        const {
            onAddToList,
            defaultValue,
            list,
            disabled,
            onChange
        } = this.props;

        if(disabled){
            return
        }
        onAddToList(value, list.length);
      //  console.log("defaultValue", defaultValue)
        this.setValue(defaultValue);

    }

    removeItemHandler = (index, item) => {
        const {
            onRemoveListItem,
            disabled
        } = this.props;

        if(disabled){
            return
        }
        onRemoveListItem(index, item);
        this.setState({lastRemoved: item})
    }

    renderAddToListComponent = () => {

        const {getAddToListComponent} = this.props;
        const {value} = this.state;

        return(
            <AddToListWrapper>
                {getAddToListComponent(value, this.setValue)}
                <ButtonImage onClick={this.addHandler} src={"img/icons/add_black.svg"} style={{width: 40}}/>
            </AddToListWrapper>
        )
    }

    render() {
        const {
            list,
            getAddToListComponent,
            getListItemComponent,
            addToListPosition
        } = this.props;

        const {value} = this.state

        return (
            <div>
                {addToListPosition === "top" && this.renderAddToListComponent()}

                {list.map((item, index)=>{
                    return (
                        <ListItem>
                            {getListItemComponent(item)}
                            <ButtonImage onClick={()=>this.removeItemHandler(index, item)} src={"img/icons/remove-red.svg"} style={{width: 35}}/>
                        </ListItem>
                    )
                })}

                {addToListPosition === "bottom" && this.renderAddToListComponent()}
            </div>
        );
    }
}

AddToList.propTypes = {
    list: PropTypes.array,
    getAddToListComponent: PropTypes.func, // (value, onChangeHandler=(value)=>{})=>{}
    getListItemComponent: PropTypes.func, // (item) => {}
    onAddToList: PropTypes.func, // (item) => {}
    onRemoveListItem: PropTypes.func, // (index, item) => {}
    addToListPosition: PropTypes.oneOf(["top", "bottom"]),
    defaultValue: PropTypes.any,
    disabled: PropTypes.bool,
    onChange: PropTypes.func
};

AddToList.defaultProps = {
    addToListPosition: "top",
    defaultValue: null,
    disabled: false
};

const AddToListWrapper = styled.div`
   display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #f3f3f3;
  padding: 26px;
  justify-content: space-between;
`;

const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-family: ${fonts.OpenSansHebrew};
  font-size: 20px;
  color: ${colors.charcoal};
  padding: 26px;
  
`;

const ButtonImage = styled.img`
   cursor: pointer;
`



export default AddToList;