import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { colors, fonts } from "../../styles/styleguide";


const TileAddButton = ({title, icon = 'img/icons/add_yellow.svg', onClick = ()=>{}}) => {
    return (
        <Tile onClick={onClick}>
            <AddImage>
                {title}
            </AddImage>
            <ActionButtonWrapper>
                <ActionButton>
                    <img src={icon}/>
                </ActionButton>
            </ActionButtonWrapper>
        </Tile>
    );
}


const Tile = styled.div`
  border-radius: 10px;
  box-shadow: -6.9px 4px 4px 0 rgba(213, 213, 213, 0.23);
  border: solid 1px rgba(209, 209, 209, 0.45);
  background-color: #ffffff;
  width: 154px;
  height: 154px;
  position: relative;
  cursor: pointer;
`

const AddImage = styled.div`
  font-family: ${fonts.OpenSansHebrew};
  font-size: 24px;
  font-weight: bold;
  margin: 15px 20px;
  text-align: center;
`;

const ActionButton = styled.div`
  height: 55px;
  width: 55px;
  background-color:  ${props => props.background ? props.background : colors.charcoal};
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ActionButtonWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
`


TileAddButton.propTypes = {
    title: PropTypes.string
};

export default TileAddButton;