import React, { Component } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { bindActionCreators, compose } from "redux";
import { firebaseConnect, getVal } from "react-redux-firebase";
import _ from "lodash";
import matchSorter from 'match-sorter'
import memoize from 'memoize-one';
import { ORDER_MANAGEMENT_CONTEXT } from "shared/src/constants/orders"
import { getContractorIds, getSupplierIds } from "shared/src/selectors/roles";
import { getPriceListContractorsOrSuppliers } from "shared/src/selectors/priceList";
import { fetchContracts, fetchContractItems, selectContract, getContractorsAndSuppliers} from "shared/src/modules/priceList"

import { Row, Col } from "reactstrap"
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import FontIcon from 'material-ui/FontIcon';
import Loader from "../../components/Loader";
import SearchField from "../search/SearchField";

import {
    Card,
    CartHeader,
    DetailsRow,
    Detail,
    InputLabel,
    InputGroup,
    Button
} from "../../styles/stylesheet"

import ROUTES from "../../constants/routes";
import FirebaseObjectListSelect from "../../components/Form/FirebaseObjectListSelect";
import { roundFloatNumberWithoutTrailingZeroes } from "shared/src/utils";


class Contracts extends Component {

    constructor(){
        super();
        this.state = {
            contracts: [],
            isLoading: false,
            selectedContractor: null,
            query: "",
        }
    }

    componentDidMount() {
        const {context, shouldSelectContractor, supplierId, contractorId, potentialContractsWith} = this.props;
        const {selectedContractor} = this.state;
        this.fetchScreenData(shouldSelectContractor, context, supplierId, potentialContractsWith, contractorId);

    }

    fetchScreenData = (shouldSelectContractor, context, supplierId, potentialContractsWith, contractorId) =>{
        let suppliers = [];
        let contractors = [];
        console.log("fetchScreenData", shouldSelectContractor, context, supplierId, potentialContractsWith, contractorId);
        if (shouldSelectContractor) {
            return;
        }
        if (context === ORDER_MANAGEMENT_CONTEXT.SUPPLIER) {
            suppliers.push({id: supplierId})
            contractors = potentialContractsWith;
        } else {
            contractors.push({id: contractorId});
            suppliers = potentialContractsWith;
        }
        this.getPriceListContracts(suppliers, contractors)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {context, shouldSelectContractor, supplierId, contractorId, potentialContractsWith} = this.props;
        if(!_.isEqual(prevProps.contractorId, contractorId) || !_.isEqual(prevProps.supplierId, supplierId) || !_.isEqual(prevProps.shouldSelectContractor, shouldSelectContractor) || !_.isEqual(prevProps.potentialContractsWith, potentialContractsWith)){
            this.fetchScreenData(shouldSelectContractor, context, supplierId, potentialContractsWith, contractorId)
        }
    }

    getPriceListContracts = (suppliers, contractors) =>{
        const {fetchContracts} = this.props;
        fetchContracts(suppliers, contractors);
    }

    filter = memoize(
        (list, filterText, key) => matchSorter(list, filterText, {keys: [`${key}.name`]})
    );

    onContractorSelection = (contractorId) => {
        const {getContractorsAndSuppliers} = this.props;
        console.log("contractor selected", contractorId)
        const contractor = {id: contractorId};
        getContractorsAndSuppliers(contractor);
        this.setState({
            selectedContractor: contractor
        });


    }

    render() {
        const {
            shouldSelectContractor,
            context,
            history,
            fetchContracts, selectContract,
            contracts,
            isLoading,
            contractors
        } = this.props;

        const {
            selectedContractor,
            query,
        } = this.state

        const cardKey = context === ORDER_MANAGEMENT_CONTEXT.SUPPLIER ?  "contractor" : "supplier"

        const filtered = this.filter(contracts, query, cardKey);

        return (
            <div>
                {shouldSelectContractor && (
                    <FirebaseObjectListSelect
                        list={contractors}
                        onChange={(value) => this.onContractorSelection(value)}
                        hintText={"בחרו קבלן"}
                        value={selectedContractor ? shouldSelectContractor.id : null}
                    />
                )}
                <Row>
                    <Col>
                        <SearchField
                            onSubmit={()=>{}}
                            label={"חיפוש"}
                            onChange={(value)=>{
                                this.setState({query: value})
                            }}
                            value={query}
                        />
                    </Col>
                </Row>
                {isLoading && <Loader/>}
              {!isLoading && filtered.map((c,i) =>{
                  console.log("c",cardKey, c)
                    return (
                        <Row>
                            <Col md={6} sm={12}>
                                <Card key={i} onClick={()=>{
                                    selectContract(c)
                                    history.push(`${ROUTES.PRICE_LIST}/${c.supplier.id}/${c.contractor.id}`)
                                }} style={{cursor: "pointer"}}>
                                    <Row>
                                        <Col md={4}>
                                            <DetailsRow>
                                                <FontIcon style={{fontSize: 18}} color={"#636363"}
                                                          className={"material-icons"}>location_city</FontIcon>
                                                <Detail>
                                                    {c[cardKey].name}
                                                </Detail>
                                            </DetailsRow>
                                        </Col>

                                        <Col md={4}>
                                            <Detail>{"הנחה קבועה"}</Detail>
                                            <DetailsRow>
                                                {c.discount ? `${roundFloatNumberWithoutTrailingZeroes(c.discount * 100, 2)}%` : "אין"}
                                            </DetailsRow>
                                        </Col>
                                    </Row>

                                </Card>
                            </Col>
                        </Row>
                )})}
            </div>
        );
    }
}

Contracts.propTypes = {
    context: PropTypes.string,
    isApplicationOrdersAdmin: PropTypes.bool,
    potentialContractsWith: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string
    })),
    supplierId:  PropTypes.string,
    contractorId:  PropTypes.string,
    shouldSelectContractor: PropTypes.bool,
    contracts: PropTypes.arrayOf(PropTypes.shape({
        contractor: PropTypes.object,
        supplier: PropTypes.object,
        discount: PropTypes.number
    })),
    isLoading: PropTypes.bool,
};

Contracts.defaultProps = {};

const mapStateToProps = (state) => {

    const context = state.ordersManagement.context;

    const applicationOrdersAdmin = state.ordersManagement.isApplicationOrdersAdmin;
    const map = {
        context: context,
        isApplicationOrdersAdmin: applicationOrdersAdmin,
        contracts: state.priceList.contracts,
        isLoading: state.priceList.isLoading,
        contractors: state.firebase.data.contractors,
        roles: state.roles.roles
    }

    map["shouldSelectContractor"] = false;

    if (context === ORDER_MANAGEMENT_CONTEXT.SUPPLIER) {
        const supplierIds = getSupplierIds(state.roles.roles);
        const supplierId = supplierIds.supplierIds[0];
        const contractorsPublicData = getVal(state.firebase.data, `contractorsPublicData`, {});
        console.log("contractorsPublicData", contractorsPublicData)
        map["potentialContractsWith"] = getPriceListContractorsOrSuppliers(getVal(state.firebase.data, `suppliersPrivateData/${supplierId}/contractors`, {}), contractorsPublicData)
        console.log("contractorsPublicData after", map["potentialContractsWith"])
        map["supplierId"] = supplierId;
    } else if (context === ORDER_MANAGEMENT_CONTEXT.CONTRACTOR && !applicationOrdersAdmin) {
        const contractorIds = getContractorIds(state.roles.roles);
        if(contractorIds.contractorIds.length === 1){
            const contractorId = contractorIds.contractorIds[0];
            map["potentialContractsWith"] = getPriceListContractorsOrSuppliers(getVal(state.firebase.data, `contractors/${contractorId}/suppliers`, {}), getVal(state.firebase.data, `suppliers`, {}))
            console.log("potentialContractsWith",  map["potentialContractsWith"] )
            map["contractorId"] = contractorId;
        }else {
            map["shouldSelectContractor"] = true;
        }
    } else {
        map["potentialContractsWith"] = [];
        map["shouldSelectContractor"] = true;
    }

    return map;
};

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchContracts, fetchContractItems, selectContract, getContractorsAndSuppliers
}, dispatch);


export default compose(
    connect(
        mapStateToProps, mapDispatchToProps
    ),
    firebaseConnect((props) => {
        const  {context, isApplicationOrdersAdmin, roles} = props;

        const paths = [`suppliers`,  'contractors'];
        if (context === ORDER_MANAGEMENT_CONTEXT.SUPPLIER) {
            const supplierIds = getSupplierIds(roles);
            const supplierId = supplierIds.supplierIds[0];
            paths.push(`suppliersPrivateData/${supplierId}/contractors`);
            paths.push(`contractorsPublicData`);
        } else if (context === ORDER_MANAGEMENT_CONTEXT.CONTRACTOR && !isApplicationOrdersAdmin) {
            const contractorIds = getContractorIds(roles);
            if(contractorIds.contractorIds.length === 1){
                const contractorId = contractorIds.contractorIds[0];
                paths.push(`contractors/${contractorId}/suppliers`)
            }
        }

        return paths
    })

)(Contracts);

