import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
// import PropTypes from "prop-types";
import styled from "styled-components";

import {
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import { v4 as uuidv4 } from "uuid";
import base64js from "base64-js";

import { GOOGLE_API_KEY } from "shared/src/constants/config";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import {colors} from "../styles/styleguide";

const getRandomString = () => base64js.fromByteArray(uuidv4());

// Create the script tag, set the appropriate attributes
var script = document.createElement("script");

// script.type = "text/javascript"
script.src = "https://maps.googleapis.com/maps/api/js".concat(
  "?",
  "key=",
  GOOGLE_API_KEY,
  "&sessiontoken=",
  getRandomString(),
  "&libraries=places,geometry",
  "&language=iw",
);

// script.async = true;
// script.defer = true;

// Append the 'script' element to 'head'
document.head.appendChild(script);

// const DEFUALT_SPACE = 8;

const GooglePlacesAutocomplete = ({ onDone, currentAddress }) => {
  const [search, setSearch] = useState(
      !!currentAddress
          ? typeof currentAddress === "string"
            ? currentAddress
            : currentAddress.name
          : null
  );

  const [selectedSuggestion, setSelectedSuggestion] = useState();
  const [geolocation, setGeolocation] = useState();

  const [address, setAddress] = useState(
      !!currentAddress && typeof currentAddress !== "string"
          ? currentAddress
          : null
  );

  useEffect(() => {
    if (!!selectedSuggestion && search !== selectedSuggestion.description) {
      setSelectedSuggestion();
      setGeolocation();
    }
  }, [search]);

  useEffect(() => {
    !!selectedSuggestion && setSearch(selectedSuggestion.description);
  }, [selectedSuggestion]);

  useEffect(() => {
    if (!!geolocation && !!selectedSuggestion) {
      setAddress({
        place_id: selectedSuggestion.place_id,
        name: selectedSuggestion.description,
        ...geolocation,
      });
    }
  }, [geolocation]);

  useEffect(() => {
    if (!!address) {
      onDone(address);
    }
  }, [address]);

  return (
      <PlacesAutocomplete
          searchOptions={{
            componentRestrictions: {
              country: "il"
            }
          }}
          value={search}
          onChange={setSearch}
          onSelect={(value) => {
            geocodeByAddress(value)
                .then((results) => {
                  const result = {
                    ...results[0],
                    description: value,
                  };

                  setSelectedSuggestion(result);
                  return result;
                })
                .then((result) => getLatLng(result))
                .then(setGeolocation)
                .catch((error) => console.error("Error", error));
          }}
      >
        {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div>
              <input
                  {...getInputProps({
                    // ref: refAutocompleteInput,
                    placeholder: "חפש כתובת",
                    className: "location-search-input",
                    style: { width: "100%", height: 34 }
                    // autoFocus: true,
                  })}
              />

              <div
                  className="autocomplete-dropdown-container"
              >
                {
                  loading ? (
                      <div>Loading...</div>
                  ) : suggestions.map((suggestion) => {
                    const className = suggestion.active
                        ? "suggestion-item--active"
                        : "suggestion-item";
                    // inline style for demonstration purpose
                    const style = suggestion.active
                        ? { backgroundColor: "#e9e9e9" }
                        : { backgroundColor: "#ffffff" };

                    return (
                        <div
                            key={suggestion.placeId}
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style: {
                                ...style,
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                                minHeight: "25px",
                                padding: "5px",
                                border: `0.25px solid ${colors.dark_gray}`
                              },
                            })}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                    );
                  })
                }
              </div>
            </div>
        )}
      </PlacesAutocomplete>
  );
};

export default GooglePlacesAutocomplete;
