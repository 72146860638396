import React, { Component } from 'react';
import { Route, Switch } from 'react-router' // react-router v4
import {userIsLoggedIn} from "./auth"
import FirebaseReferenceHolder from "shared/src/utils/firebaseReferenceHolder";
import {firebaseConnect} from "react-redux-firebase";

import ReduxToastr from 'react-redux-toastr'
import ReactTooltip from 'react-tooltip';
// Containers
import Full from './containers/Full/'

// Views
import Login from './views/Pages/Login/';
import SMS from "./views/SMS"
import Register from './views/Pages/Register/';
import SMSLogin from './views/Pages/SMSLogin/';
import Invite from './views/Pages/Invite/';
import PasswordReset from './views/Pages/PasswordReset/PasswordReset';
import Page404 from './views/Pages/Page404/';
import Page500 from './views/Pages/Page500/';
import Terms from "./views/Pages/Terms";





const FullApplication = userIsLoggedIn(Full);


class AppWrapper extends Component {

    componentDidMount() {
        // init firebase ref holder for use under shared
       // FirebaseReferenceHolder.firebaseConnect = firebaseConnect
    }

    render() {
        return (
            <div>
                <Switch>
                    <Route exact path="/login" name="Login Page" component={Login}/>
                    <Route exact path="/sms" name="SMS" component={SMS}/>
                    <Route exact path="/sms-login" name="SMSLogin" component={SMSLogin}/>
                    <Route exact path="/invite" name="invite" component={Invite}/>
                    <Route exact path="/signup" name="Register Page" component={Register}/>
                    <Route exact path="/password-reset" name="Register Page" component={PasswordReset}/>
                    <Route exact path="/404" name="Page 404" component={Page404}/>
                    <Route exact path="/500" name="Page 500" component={Page500}/>
                    <Route exact path="/terms" name="Terms" component={Terms}/>

                    <Route path="/" name="Home" component={FullApplication}/>
                </Switch>
                <ReduxToastr
                    timeOut={4000}
                    newestOnTop={false}
                    preventDuplicates
                    position="top-left"
                    transitionIn="fadeIn"
                    transitionOut="fadeOut"
                    progressBar={false}/>
                <ReactTooltip />
            </div>
        );
    }
}



export default AppWrapper
