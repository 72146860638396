import React, {Component} from 'react';
import PropTypes from 'prop-types'
import ReactTable from 'react-table'

import moment from "moment"

import { DateRangePicker } from 'react-dates';
import * as reactDatesConstants from "react-dates/constants"
import 'react-table/react-table.css'
import 'react-dates/lib/css/_datepicker.css';
import "./react-table-overrides.scss";

import {ORDER_STATUSES, ORDER_MANAGEMENT_CONTEXT} from "shared/src/constants/orders"


import FontIcon from 'material-ui/FontIcon';

import { colors, fonts } from "../../styles/styleguide";

class OrdersTable extends Component {

    constructor() {
        super();
        this.state = {
            focusedInput: null,
            statusFilter: null,
            showDatePicker: false,
            columns: []
        };

        this.dateFilterOnChangeCallback = null;

        this.setOrderId = this.setOrderId.bind(this);
    }

    componentDidMount() {
        this.setState({columns: this.initColumns(this.props.context)});

    }

    initColumns = (context) => {
        return this.getContractorColumns()
    }

    getContractorColumns = () => {
        return [{
        //     {
            id: 'orderId',
            Header: 'מס תעודת משלוח',
            accessor: o => o.order.orderId, // String-based value accessors!,
            Cell: props => <span>{props.value}</span>, // Custom cell components!*/
            filterMethod: this._orderIdFilter,
            Filter: <input type="text" ref={ref => {
                this.orderId = ref
            }} onChange={(e) => {
                this._filterColumn("orderId", e.target.value)
            }}/>
        }, {
            id: 'supplier', // Required because our accessor is not a string
            Header: 'ספק',
            accessor: o => o.order.supplier.name,
            filterMethod: this._supplierFilter,
            Filter: <input type="text" ref={ref => {
                this.customer = ref
            }} onChange={(e) => {
                this._filterColumn("supplier", e.target.value)
            }}/>,
            Cell: props => <span className='number' onClick={() => {
                this.props.onEditItem(props.original)
            }}>{props.value}</span> // Custom cell components!
        }, {
            id: "deliveryDate",
            accessor: o => o.order.data.deliveryDate,
            Header: 'תאריך אספקה',
            filterMethod: this._dateFilter,
            Cell: props => {
                return moment(props.value).format("D/MM/YY")
            },
            Filter: ({filter, onChange}) => this.renderDateFilterColumn(onChange)
        }, {
            id: 'driver', // Required because our accessor is not a string
            Header: 'נהג',
            filterMethod: this._driverFilter,
            Filter: <input type="text" ref={ref => {
                this.driver = ref
            }} onChange={(e) => {
                this._filterColumn("driver", e.target.value)
            }}/>,
            accessor: o => o.order.driver ? `${o.order.driver.displayName}` : ""
        }, {
            id: 'address', // Required because our accessor is not a string
            Header: 'כתובת',
            filterMethod: this._addressFilter,
            Filter: <input type="text" ref={ref => {
                this.address = ref
            }} onChange={(e) => {
                this._filterColumn("address", e.target.value)
            }}/>,
            accessor: o => `${o.order.project.address} (${o.order.project.name})`
        }, {
            id: "total",
            Header: 'סכום',
            filterMethod: this._totalFilter,
            Filter: ({filter, onChange}) => this.renderAmountFilter(onChange),
            accessor: o => o.order.isRental ? o.order.rentalTotalPrice : o.order.summary ? o.order.summary.sum :  undefined // String-based value accessors!
        }]
    }


    _filterColumn = (columnId, value) => {
        this.props.filterColumn(columnId, value)
    }

    _orderIdFilter = (filter, row) => {
        return this._simpleTextFilter(filter, row, "orderId");
    };

    _supplierFilter = (filter, row) => {
        return this._simpleTextFilter(filter, row, "supplier");
    };


    _addressFilter = (filter, row) => {
        return this._simpleTextFilter(filter, row, "address");
    };

    _driverFilter = (filter, row) => {
        return this._simpleTextFilter(filter, row, "driver");
    };

    //text contains
    _simpleTextFilter = (filter, row, key) => {
        return (!filter.value || filter.value === '') || row[key] && row[key].toLowerCase().indexOf(filter.value.toLowerCase()) > -1
    }

    _dateFilter = (filter, row) => {
        return (!filter.value || filter.value === '' || !filter.value.startDate || !filter.value.endDate) || moment(row.deliveryDate).isBetween(filter.value.startDate, filter.value.endDate) || moment(row.deliveryDate).isSame(filter.value.startDate);
    }

    _totalFilter = (filter, row) => {

        if (!filter.value || filter.value === '' || !filter.value.value || !filter.value.condition) {
            return true;
        }
        if (filter.value.value && filter.value.condition) {
            switch (filter.value.condition) {
                case "greaterThan":
                    return row.total > filter.value.value;
                case  "smallerOrEqual":
                    return row.total <= filter.value.value;
            }
        }
        return true;
    }

    showDatePicker = () => {
        this.setState({showDatePicker: true, focusedInput: reactDatesConstants.START_DATE})
    }

    setOrderId(id, index, value) {
        this.props.setOrderId(index, value)

    }


    renderDateFilterColumn = (onChange) => {
        const {startDateFilter, endDateFilter} = this.props;
        this.dateFilterOnChangeCallback = onChange;
        if (startDateFilter && endDateFilter) {
            return (
                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <span
                        onClick={this.showDatePicker}>{moment(startDateFilter).format("D/MM/YY")} - {moment(endDateFilter).format("D/MM/YY")}</span>
                    <FontIcon onClick={() => {
                        this._filterColumn("deliveryDate", {startDate: null, endDate: null})
                        this.setState({startDateFilter: null, endDateFilter: null})
                    }} className="material-icons pointer" color={"#6e6e6e"}>close</FontIcon>
                </div>
            )
        }
        else {
            return (
                <div className={"pointer"} onClick={this.showDatePicker}>בחרו תאריכים</div>
            )
        }
    }

    renderDatePicker = () => {
        return (<DateRangePicker
            startDate={this.props.startDateFilter} // momentPropTypes.momentObj or null,
            startDateId="startDateFilter" // PropTypes.string.isRequired,
            endDate={this.props.endDateFilter} // momentPropTypes.momentObj or null,
            endDateId="endDateFilter" // PropTypes.string.isRequired,
            onDatesChange={({startDate, endDate}) => {
                this.props.setDates(startDate, endDate);
            }} // PropTypes.func.isRequired,
            focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
            onFocusChange={focusedInput => {
                this.setState({focusedInput})
            }} // PropTypes.func.isRequired,
            isRTL={true}
            isOutsideRange={day => moment(day).isBefore('2018-01-01')}
            anchorDirection={reactDatesConstants.ANCHOR_RIGHT}
            showClearDates={true}
            withPortal={true}
            appendToBody={true}
            disableScroll={true}
            small={true}
            block={true}
        />)
    }

    renderAmountFilter = () => {
        const {amountFilterCondition, amountFilterValue, setAmountFilter} = this.props;

        return (
            <div style={{flex: 1, flexDirection: "row"}}>
                <select
                    style={{width: 100}}
                    value={amountFilterCondition}
                    onChange={(e) => {
                        setAmountFilter({amountFilterCondition: e.target.value, amountFilterValue})
                    }}
                >
                    <option value={""}>בחרו תנאי</option>
                    <option value="greaterThan">גדול מ</option>
                    <option value="smallerOrEqual">קטן או שווה ל</option>
                </select>
                <input
                    style={{width: 50}}
                    value={amountFilterValue}
                    type={"number"}
                    onChange={(e) => {
                        setAmountFilter({amountFilterValue: e.target.value, amountFilterCondition})
                    }}/>
            </div>
        )

    }

    filterToday = () => {
      this.props.filterToday();
    }

    filterTommorow = () => {
     this.props.filterTomorrow();
    }

    filterThisWeek = () => {
      this.props.filterThisWeek();
    }

    showAllOpen = () => {
       this.props.showAllOpen();
    }

    showClosed = () => {
        this.props.showClosed();
    }

    showCancelled = () => {
        this.props.showCancelled();
    }

    getOrdersTotal = () => {
        const {orders} = this.props;
        const total = orders.reduce((total, o)=>{
            const {order} = o;
            return total + (order.isRental ? Number(order.rentalTotalPrice) : order.summary ? Number(order.summary.sum) :  0)
        }, 0)
        return Math.round((total) * 100) / 100;;
    }


    render() {

        const {orders, isLoading, filtered, sorted, onSortChange} = this.props;
        const {showDatePicker} = this.state;

        const totalPrice = this.getOrdersTotal();


        return (
            <div>
                <div>
                    <div style={{visibility: "hidden", height: 0}}>{this.renderDatePicker()}</div>
                </div>
                {totalPrice ? (
                    <div style={{marginTop: 15, marginBottom: 15, fontFamily: fonts.OpenSansHebrew, color: colors.charcoal, fontSize: 21}}>{`סה"כ מחיר כל ההזמנות ${totalPrice}₪`}</div>
                ) : null}
                <ReactTable
                    data={orders}
                    columns={this.state.columns}
                    showPagination={false}
                    pageSize={orders.length}
                    loading={isLoading}
                    filtered={filtered}
                    filterable
                    sorted={sorted}
                    onSortedChange={sorted => onSortChange(sorted)}
                    getTdProps={(state, rowInfo, column, instance) => {

                        const returnedObj = {
                            onClick: (e, handleOriginal) => {
                                console.log("A Td Element was clicked!");
                                console.log("it produced this event:", e);
                                console.log("It was in this column:", column);
                                console.log("It was in this row:", rowInfo);
                                console.log("It was in this table instance:", instance);
                                this.props.onEditItem(rowInfo.original);

                                // IMPORTANT! React-Table uses onClick internally to trigger
                                // events like expanding SubComponents and pivots.
                                // By default a custom 'onClick' handler will override this functionality.
                                // If you want to fire the original onClick handler, call the
                                // 'handleOriginal' function.
                                if (handleOriginal) {
                                    handleOriginal();
                                }
                            }
                        };

                        if(this.props.context === ORDER_MANAGEMENT_CONTEXT.SUPPLIER && rowInfo && rowInfo.row && (rowInfo.row.status === ORDER_STATUSES.APPROVED || rowInfo.row.status === ORDER_STATUSES.SENT)){
                            //console.log("row status", rowInfo.row.status, rowInfo.original.status);
                            returnedObj.style = {fontWeight: "bold"}
                        }

                        return returnedObj;
                    }}
                />
            </div>

        )

    }
}

OrdersTable.propTypes = {
    orders: PropTypes.array,
    setOrderId: PropTypes.func,
    saveOrderId: PropTypes.func,
    isLoading: PropTypes.bool,
    context: PropTypes.string,
    sendOrder: PropTypes.func,
    filterColumn: PropTypes.func,
    filterToday: PropTypes.func,
    filterTomorrow: PropTypes.func,
    filterThisWeek: PropTypes.func,
    showAllOpen: PropTypes.func,
    showClosed: PropTypes.func,
    showCancelled: PropTypes.func,
    onSortChange: PropTypes.func,
    setDates: PropTypes.func,
    setAmountFilter: PropTypes.func,
    filtered: PropTypes.array,
    sorted: PropTypes.array
};

export default OrdersTable;