import React, { useState } from "react"

import { PageContainer } from "./styles";
import CollapseDetails from "./CollapseDetails";
import SummaryContainer from "./SummaryContainer";

// import Col from "reactstrap/es/Col";
import { Card, CardTitle } from "../../../styles/stylesheet";

const FormContainer = ( {
    detailsProps: {
        isFDO,
        ActionButtonsComponent,
        DetailsComponentCard,
    },
    NotFoundItemList,
    summaryProps: {
        productCount,
        totalPrice,
        onPrintClick,
        SummaryItemList,
    },
    Catalog,
} ) => {
    const [ isSearching, setIsSearching ] = useState(false)
    const [ shouldOpen, setShouldOpen ] = useState(false)

    return (
        <div>
            <PageContainer>
                <CollapseDetails
                    isFDO={ isFDO }
                    isSearching={ isSearching }
                    shouldOpen={ shouldOpen }
                    onOpen={ () => {
                        setShouldOpen(false)
                    } }
                    actionsView={ ActionButtonsComponent({ setShouldOpen }) }
                >
                    { DetailsComponentCard }
                </CollapseDetails>
            </PageContainer>

            { !!NotFoundItemList && NotFoundItemList.length !== 0 && (
                <div style={ { width: "100%", margin: "20px 0 20px 0" } }>
                    <CardTitle>פריטים שלא נמצאו</CardTitle>
                    <Card>{ NotFoundItemList }</Card>
                </div>
            ) }

            { !!Catalog
                ? (
                    <div
                        style={ {
                            display: "flex",
                            width: "100%",
                            flexDirection: "row",
                            // justifyContent: "space-between",
                        } }
                        onFocus={ () => {
                            setIsSearching(true)
                        } }
                        onBlur={ () => {
                            setIsSearching(false)
                        } }
                    >
                        { Catalog }

                        <div
                            style={ {
                                display: "flex",
                                flex: 1,
                                justifyContent: "flex-end",
                            } }
                        >
                            <SummaryContainer
                                productCount={ productCount }
                                totalPrice={ totalPrice }
                                isCatalog={ true }
                                onPrintClick={ onPrintClick }
                            >
                                { SummaryItemList }
                            </SummaryContainer>
                        </div>
                    </div>
                ) : (
                    <SummaryContainer
                        productCount={ productCount }
                        totalPrice={ totalPrice }
                        isCatalog={ false }
                        onPrintClick={ onPrintClick }
                    >
                        { SummaryItemList }
                    </SummaryContainer>
                )
            }
        </div>
    )
}

export default FormContainer;

