import React, { PureComponent } from 'react';
import styled from 'styled-components';
import {
    Container,
    Row,
    Col,
} from 'reactstrap';
import { Card, CartHeader, InputLabel, InputGroup  } from "../../styles/stylesheet";
import {fonts, colors} from "../../styles/styleguide";



class AuthPage extends PureComponent {

    render() {

        const {title} = this.props

        return (
            <BG>
                <Container>
                    <Row className="justify-content-center">
                        <Col sm={12} md={5}>
                            <Row className="justify-content-center">
                                <div>
                                    <img src={"img/logo-punct.png"}  onClick={() => {
                                        window.location.href = '/about';
                                    }} style={{cursor: "pointer"}}/>
                                </div>
                            </Row>
                            <Row className="justify-content-center">
                                <div style={{
                                    fontFamily: fonts.NachlieliCLM,
                                    fontSize: 19.5,
                                    fontWeight: 300,
                                    color: colors.charcoal
                                }}>CONTROL YOUR SITE</div>
                            </Row>

                            <Card style={{position: "relative"}}>
                                <CartHeader style={{textAlign: "center"}}>{title}</CartHeader>
                                {this.props.children}
                            </Card>

                        </Col>
                    </Row>
                </Container>
            </BG>
        );
    }
}

const BG = styled.div`
    display: flex;
    min-height: 100vh;
    align-items: center ;
    flex-direction: row ;
    background-image:  url("img/login_bg.png");
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;
`;

export const GoogleButton = styled.div`
  cursor: pointer;
  height: 55px;
  object-fit: contain;
  border-radius: 27.5px;
  background-color: ${props => props.bg ? props.bg : '#de5137'};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  
  font-family: "NachlieliCLM";
  font-size: 21.5px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.37;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  
  position: relative;
  
  @media (min-width: 320px) and (max-width: 767px) {
        font-size: 18px;
    }
  
  
`;

export const GoogleLogo = styled.img`
  object-fit: contain;
  position: absolute;
top: 7.5px;
    right: 11px;
    width: 39px;
    height: 39px;
    
   @media (min-width: 320px) and (max-width: 767px) {
        top: 11.5px;
        width: 29px;
        height: 29px;
    }
       
`;

export const LoginError = styled.p`
  color: #BF2A2A;
  font-family: 'Rubik', sans-serif;
  font-size: 12px;
`

export const ContentWrapper = styled.div`
   padding: 44px;
   @media (min-width: 320px) and (max-width: 767px) {
       padding: 18px;
    }
`

export default AuthPage
