import _ from "lodash";

export const validateTaxId = (taxId, onValid, onInvalid) => {
    if (!(taxId && taxId.length === 9)) {
        onInvalid()
        return;
    }

    onValid("")

    //515749778
   /* fetch(`https://data.gov.il/api/action/datastore_search?resource_id=f004176c-b85f-4542-8901-7b3176f9a054&q=${taxId}`, {mode: "cors"}).then((res) => {
        return res.json()
    }).then((json) => {
        console.log(json)
        if(json.result && json.result.total > 0){
            let res = _.find(json.result.records, {"מספר חברה": Number(taxId)});
            if(res){
                if(res["סטטוס חברה"] === "פעילה"){
                    let name  = res["שם חברה"]
                    let find = '~';
                    let re = new RegExp(find, 'g');
                    name = name.replace(re, '"');
                    onValid(name);
                    return;
                }
            }
        }

    }).catch((e) => {
        console.log("error fetching taxId", e);
        onInvalid()
        return;
    })*/
   // onInvalid()

}