import { createStore, applyMiddleware, compose } from 'redux'
//import { routerMiddleware } from 'react-router-redux'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk'
import {authMiddleware, authBeforeMiddleware} from "shared/src/middleware/authMiddleware";
import {ordersMiddleware, getAutoSaveOrderMiddleware, getSupplierItemActionsMiddleware} from "shared/src/middleware/orders";
import {getAddOnProducsMiddleware} from "shared/src/middleware/addOnProducts";
import {getAutoCompleteMiddleware} from "shared/src/middleware/autoComplete"
import {analyticsMiddleware} from "../middleware/analytics"
import {navigationMiddleware} from "../middleware/navigation";
import {currentOrderMiddleware} from "../middleware/currentOrder"
import {filtersMiddleware, filtersContextMiddleware} from "../middleware/filters"
import {posMiddleware} from "../middleware/pos"
import { apiMiddleware } from 'redux-api-middleware';
import { firebaseConnect, getFirebase, withFirebase } from 'react-redux-firebase';
import {firebaseConfig} from "shared/src/constants/config";
import * as firebase from "firebase/app";
import 'firebase/database'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/remote-config'
import Raven from "raven-js"; // Or, you might already have this as `window.Raven`.
import createRavenMiddleware from "raven-for-redux";
import {toastr} from 'react-redux-toastr'
import {initConfig} from "shared/src/api/remoteConfig"
import { createFirestoreInstance } from 'redux-firestore'

firebase.initializeApp(firebaseConfig)
initConfig(firebase);

firebase.firestore()


import createHistory from 'history/createHashHistory'
import rootReducer from '../reducers'
import FirebaseReferenceHolder from "shared/src/utils/firebaseReferenceHolder";

export const history = createHistory()

// react-redux-firebase config
const rrfConfig = {
    userProfile: 'userProfiles',
    enableLogging: false,
    attachAuthIsReady: true,
    firebaseStateName: 'firebase',
}



const initialState = {};
const enhancers = [

];

const middleware = [
    thunk.withExtraArgument(getFirebase),
    routerMiddleware(history),
    authBeforeMiddleware,
    apiMiddleware,
    authMiddleware,
    analyticsMiddleware,
    navigationMiddleware,
    ordersMiddleware,
    getAutoSaveOrderMiddleware(console.log),
    getAutoCompleteMiddleware(console.log),
    getAddOnProducsMiddleware(console.log),
    currentOrderMiddleware,
    posMiddleware,
    getSupplierItemActionsMiddleware(toastr),
    filtersMiddleware,
    filtersContextMiddleware,
    createLogger({ collapsed: true }),
];

let sentryClient = null;

if(process.env.NODE_ENV === "production"){
    // add sentry to production
    Raven.config('https://ceddb30ea6a04c2a995e4346ba492ece@sentry.io/1251877').install();
    sentryClient = Raven;
    middleware.push(
        createRavenMiddleware(Raven, {
            // Optionally pass some options here.
        })
    )
}

export const Logger = sentryClient;

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

/*const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
)*/

const store = createStore(
    connectRouter(history)(rootReducer),
    initialState,
    composeEnhancers(
        applyMiddleware(...middleware),
        ...enhancers
    )
);

export const rrfProps = {
    firebase,
    config: rrfConfig,
    dispatch: store.dispatch,
    createFirestoreInstance // <- needed if using firestore
}

if (process.env.NODE_ENV !== "production") {
    if (module.hot) {
        module.hot.accept('../reducers', () => {
            store.replaceReducer(rootReducer)
        })
    }
}

FirebaseReferenceHolder.firebaseConnect = firebaseConnect
FirebaseReferenceHolder.withFirebase = withFirebase;

export default store;
