import React from 'react';
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import styled from 'styled-components';
import _ from "lodash";


import IconInput from "./IconInput";
import { InputGroup, InputLabel } from "../../styles/stylesheet";


class IconTreeSelect extends React.Component {

    constructor() {
        super()

    }

    setValue(level, value) {
        let clone = this.state.value;
        if (!value) {
            //delete current & next levels
            for (let key in clone) {
                if (key >= level) {
                    clone[key] = null;
                }
            }
        } else {
            clone[level] = value;
        }
        this.setState({
            value: clone
        })


    }

    getText(key, parentKey) {
        let prefix = "categories."
        if (parentKey) {
            return `${prefix}${parentKey}.${key}`
        }
        return `${prefix}${key}`;
    }

    renderChildren(children, parent, intl) {
        let nodes = []
        for (let key in children) {
            nodes.push({label: intl.formatMessage({id: this.getText(key, parent)}), value: key})
        }
        return nodes;
    }

    renderTree = () => {
        const {tree, value, setValue, intl, labels, selectLabel} = this.props;
        let toRender = [
            <InputGroup>
                <InputLabel>{labels && labels.length && labels[0]}</InputLabel>
                <IconInput
                    key={"root"}
                    type={"select"}
                    options={this.renderChildren(tree, "", intl)}
                    labelAccessor={"label"}
                    valueAccessor={"value"}
                    value={value.length === 0 ? null : value[0]}
                    onChange={(value) => setValue(0, value)}
                />
            </InputGroup>
        ];

        for (let key in value) {
            let i = key;
            if (value[i] && tree[value[i]] && tree[value[i]].children) {
                toRender.push(
                    <InputGroup>
                        <InputLabel>{labels && labels.length && labels[Number(i)+1]}</InputLabel>
                        <IconInput
                            key={i}
                            type={"select"}
                            options={this.renderChildren(tree[value[i]].children, value[i], intl)}
                            labelAccessor={"label"}
                            valueAccessor={"value"}
                            value={value[i + 1]}
                            onChange={(value) => setValue(i + 1, value)}
                        />
                    </InputGroup>
                )
            }
        }
        return toRender
    }


    render() {
        const {tree, value, setValue, intl, label, selectLabel, direction} = this.props;
        return (
            <div style={{display: "flex", flexDirection: direction}}>
                {this.renderTree()}
            </div>
        )
    }
}

IconTreeSelect.propTypes = {
    tree: PropTypes.array,
    value: PropTypes.array,
    setValue: PropTypes.func,
    labels: PropTypes.arrayOf(PropTypes.string),
    selectLabel: PropTypes.string,
    direction: PropTypes.oneOf(["row", "column"])
};

IconTreeSelect.defaultProps = {
    direction: "column"
}

export default injectIntl(IconTreeSelect);