import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { firebaseConnect, getVal, isLoaded } from 'react-redux-firebase'
import { ConnectedRouter } from 'react-router-redux'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom'
import 'react-dates/initialize';
import _ from "lodash";

import Loader from "../../components/Loader"

import { setRoles } from "shared/src/modules/roles";
import { setToken } from "shared/src/modules/account";
import { registerToFirebaseAuth } from "shared/src/utils/auth"
import {
    userIsAdminOrSupplier,
    userIsAdmin,
    userIsSupplierOrdersAdmin,
    userIsSupplierOrContractorOrdersAdmin,
    userIsCatalogAdmin,
    userIsSupplierUsersAdmin,
    userIsContractorOrdersAdmin,
    userIsProjectOrdersAdmin,
    userIsLoggedIn
} from "../../auth"
import {
    haveSupplierOrdersAccess,
    haveSupplierCatalogAccess,
    haveSupplierDriverAccess,
    canViewGenericCatalog,
    haveContractorOrdersAccess,
    haveContractorManagementAccess,
    userIsSingleSupplier
} from "shared/src/selectors/roles";

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import { Container } from 'reactstrap';
import Header from '../../components/Header/';
import nav from '../../components/Sidebar/_nav';
import TabBar from "../../components/Header/TabBar"

import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';


import CatalogSearch from "../../views/search/CatalogSearch"
import CatalogManagement from "../../views/CatalogManagement/CatalogManagement"
import ProductForm from "../../views/CatalogManagement/ProductForm"
import NewProductForm from "../../views/CatalogManagement/ProductForm2"
import ArticleManagement from "../../views/ArticalsManagement/ArticleManagement";
import ArticleForm from "../../views/ArticalsManagement/ArticleForm";
import OrdersManagement from "../../views/OrdersManagement";
import DeliveriesIndex from "../../views/Deliveries/index";
import SuppliersManagement from "../../views/SuppliersManagement";
import ProjectsManagement from "../../views/ProjectsManagement"
import ContractorsManagement from "../../views/ContractorsManagement"
import Profile from "../../views/Profile"
import HistoryOrders from "../../views/History";
import UserNotification from "../../views/UserNotifications"
import Welcome from "../../views/Pages/Welcome"
import PO from "../../views/PO"
import PriceList from "../../views/PriceList"
import Rentals from "../../views/Rentals";
import InvoiceReport from "../../views/InvoiceReport"

import ScrollToTop from "../../components/ScrollToTop";

import ROUTES from "../../constants/routes"
import { injectIntl } from "react-intl";
import Page403 from "../../views/Pages/Page403";

import { haveSupplierUsersAccess, getSupplierIds, getContractorIds } from "shared/src/selectors/roles";

const muiTheme = getMuiTheme({isRtl: true});

const AuthorizedOrdersManagement = userIsSupplierOrContractorOrdersAdmin(OrdersManagement);
const AuthorizedArticleForm = userIsAdmin(ArticleForm);
const AuthorizedArticalManagement = userIsAdmin(ArticleManagement);
const AuthorizedHistoryOrders = userIsSupplierOrContractorOrdersAdmin(HistoryOrders);
const AuthorizedSuppliersManagement = userIsSupplierUsersAdmin(SuppliersManagement);
const AuthorizedProjectsManagement = userIsProjectOrdersAdmin(ProjectsManagement);
const AuthorizedContractorsManagement = userIsContractorOrdersAdmin(ContractorsManagement);
const AuthorizedProfile = userIsLoggedIn(Profile);
const AuthorizedNotifications = userIsLoggedIn(UserNotification);
const AuthorizedWelcome = userIsLoggedIn(Welcome);
const AuthorizedPO = userIsSupplierOrContractorOrdersAdmin(PO);
const AuthorizedPriceList = userIsSupplierOrContractorOrdersAdmin(PriceList);
const AuthorizedRentals = userIsSupplierOrdersAdmin(Rentals)
const AuthorizedInvoiceReport = userIsContractorOrdersAdmin(InvoiceReport);

class Full extends Component {

    constructor() {
        super()
        this.callback = null
        this.metadataRef = null;
        this.state = {
            loadingAuth: true,
            isReloadModalOpen: false
        }
    }

    componentDidMount() {
        const {firebase, setToken, roles, webCurrentVersion} = this.props;
        //  console.log("componentDidMount webCurrentVersion", webCurrentVersion)
        registerToFirebaseAuth(firebase, setToken, this.callback, this.metadataRef);
        this.addUserSpecificRoutsToMenu(roles)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        //    console.log("componentDidUpdate webCurrentVersion", this.props.webCurrentVersion, prevProps.webCurrentVersion)
        if (this.state.loadingAuth && !_.isEmpty(this.props.roles)) {
            this.setState({loadingAuth: false})
        }
        if (prevProps.webCurrentVersion && this.props.webCurrentVersion && prevProps.webCurrentVersion !== this.props.webCurrentVersion) {
            //new web version is deployed - need to prompt user to reload the page
            this.setState({isReloadModalOpen: true})
        }
        if (!_.isEqual(this.props.roles, prevProps.roles)) {
            this.addUserSpecificRoutsToMenu(this.props.roles)
        }

    }

    addUserSpecificRoutsToMenu = (roles) => {
        //  console.log("adding user routes", roles, haveSupplierUsersAccess(roles))
        if (haveSupplierUsersAccess(roles) && _.findIndex(nav.items, {name: 'ניהול חנות'}) === -1) {
            const {supplierIds} = getSupplierIds(roles)
            nav.items.push(
                {
                    name: 'ניהול חנות',
                    url: `${ROUTES.SUPPLIERS}/${supplierIds[0]}`,
                    icon: 'icon-speedometer',
                    acl: haveSupplierUsersAccess,
                    imgIcon: "img/nav/nav_catalog.svg"
                }
            )
        }
        if (haveContractorManagementAccess(roles) && _.findIndex(nav.items, {name: 'ניהול קבלן'}) === -1) {
            const {contractorIds} = getContractorIds(roles);
            //const keys = Object.keys(contractorIds);
            if (contractorIds.length === 1) {
                let i = _.findIndex(nav.items, {name: 'sidebar.contractors.management'});
                if (i !== -1) {
                    nav.items[i].name = "ניהול קבלן";
                    nav.items[i].url = `${ROUTES.CONTRACTORS}/${contractorIds[0]}`
                }
            }

        }
    }

    getLandingPagePerUser = () => {

        const {roles} = this.props;
        //   console.log("getLandingPagePerUser", roles);
        if (haveSupplierOrdersAccess(roles) || haveContractorOrdersAccess(roles)) {
            return ROUTES.ORDERS_MANAGEMENT
        } else if (haveSupplierCatalogAccess(roles)) {
            return ROUTES.CATALOG_MANAGEMENT
        } else if (canViewGenericCatalog(roles)) {
            return ROUTES.CATALOG
        } else if (haveSupplierDriverAccess(roles) || haveContractorOrdersAccess(roles)) {
            return ROUTES.DELIVERIES
        }
        console.error("user can't be here", roles);
        return ROUTES.UNAUTHORIZED
    }

    render() {

        const {loadingAuth} = this.state;

        if (loadingAuth) {
            return <Loader/>
        }

        const {roles, showErpIntegrationFailure} = this.props;

        return (
            <MuiThemeProvider muiTheme={muiTheme}>
                <ScrollToTop>
                    <div className="app">
                        <Header {...this.props}/>
                        <TabBar  {...this.props}/>
                        <div className="app-body">
                            {/* <Sidebar {...this.props}/>*/}
                            <main className={`main ${this.props.location.pathname}`}>
                                {/*<Breadcrumb />*/}
                                <Container fluid>
                                    <Switch>
                                        <Route exact path={ROUTES.CATALOG_MANAGEMENT} name="catalog"
                                               component={userIsAdminOrSupplier(CatalogManagement)}/>
                                        <Route exact path={ROUTES.PRODUCT_MANAGEMENT} name="product"
                                               component={userIsAdminOrSupplier(ProductForm)}/>
                                        <Route exact path={ROUTES.NEW_PRODUCT_MANAGEMENT} name="new product"
                                               component={userIsAdminOrSupplier(NewProductForm)}/>
                                        <Route path={ROUTES.PO_LIST} name="PO" component={AuthorizedPO}/>
                                        <Route path={ROUTES.PRICE_LIST} name="PO" component={AuthorizedPriceList}/>
                                        <Route path={ROUTES.CATALOG} name="catalog" component={CatalogSearch}/>
                                        <Route exact path={ROUTES.ARTICLES_MANAGEMENT} name="article management"
                                               component={AuthorizedArticalManagement}/>
                                        <Route exact path={ROUTES.ARTICLE_FORM} name="articleForm"
                                               component={AuthorizedArticleForm}/>
                                        <Route path={ROUTES.ORDERS_MANAGEMENT} name="orders management"
                                               component={AuthorizedOrdersManagement}/>

                                        <Route path={ROUTES.RENTALS} name="rentals" component={AuthorizedRentals}/>
                                        <Route path={ROUTES.ORDERS_HISTORY_MANAGEMENT} name="orders management"
                                               component={AuthorizedHistoryOrders}/>
                                        <Route path={ROUTES.MESSAGES} component={AuthorizedNotifications}/>
                                        <Route path={ROUTES.WELCOME} component={AuthorizedWelcome}/>
                                        <Route path={ROUTES.DELIVERIES} component={DeliveriesIndex}/>
                                        <Route path={ROUTES.SUPPLIERS} component={AuthorizedSuppliersManagement}/>
                                        <Route path={ROUTES.PROJECTS} component={AuthorizedProjectsManagement}/>
                                        <Route path={ROUTES.CONTRACTORS} component={AuthorizedContractorsManagement}/>
                                        <Route path={ROUTES.INVOICE_REPORT} component={AuthorizedInvoiceReport}/>
                                        <Route path={ROUTES.PROFILE}
                                               render={(props) => (<Profile userId={roles ? roles.user_id : null}/>)}/>
                                        <Route exact path="/403" name="Page 403" component={Page403}/>
                                        <Redirect exact from="/" to={this.getLandingPagePerUser()}/>
                                    </Switch>
                                </Container>
                            </main>
                        </div>

                        <Modal isOpen={this.state.isReloadModalOpen} toggle={() => {
                            this.setState({isReloadModalOpen: false})
                        }}>
                            <ModalBody>
                                היי, עדכנו גרסא לאפליקציה, לחצו
                                <Button color="link" className="px-0" style={{
                                    paddingTop: 1,
                                    marginRight: 3,
                                    marginLeft: 3
                                }} onClick={() => {
                                    window.location.reload(true);
                                }}>כאן</Button>
                                כדי לרענן את העמוד ולראות את השינויים האחרונים
                            </ModalBody>
                        </Modal>
                    </div>
                </ScrollToTop>
            </MuiThemeProvider>
        );
    }
}

const mapStateToProps = ({firebase: {data, auth, profile}, roles, account}) => {
    const supplierId = userIsSingleSupplier(roles.roles);
    let showErpIntegrationFailure = false;
    if (supplierId && haveSupplierOrdersAccess(roles.roles)) {
        showErpIntegrationFailure = getVal(data,`suppliersIntegrationStatus/${supplierId}/failure`, false);
    }
    return {
        profile: profile,
        auth: auth,
        roles: roles.roles,
        token: account.token,
        webCurrentVersion: data.webCurrentVersion,
        showErpIntegrationFailure
    }
};

const mapDispatchToProps = dispatch => bindActionCreators({
    setRoles,
    setToken
}, dispatch);


export default compose(
    connect(
        mapStateToProps, mapDispatchToProps
    ),
    firebaseConnect((props) => {
        const {roles} = props;
        const supplierId = userIsSingleSupplier(roles);
        const paths = [
            'webCurrentVersion'
        ];
        if (supplierId && haveSupplierOrdersAccess(roles)) {
            paths.push(`suppliersIntegrationStatus/${supplierId}/failure`)
        }
        return paths
    })
)(injectIntl(withRouter(Full)));
