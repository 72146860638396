import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types'

import moment from "moment"
import { connect } from 'react-redux'

import { bindActionCreators, compose } from 'redux';

import styled from 'styled-components';
import { initOrderById } from "shared/src/modules/orders";
import { fetchItems } from "shared/src/api/orderItems";

import { getItemPackage, getOrderTotalPrice } from "shared/src/selectors/orders";

import { getAcceptance, getShippingCert } from "shared/src/api/orders"

import { List } from 'material-ui/List';

import { print } from "../../utils/print"

import { SingleDatePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import { findDOMNode } from "react-dom";

import { Col, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import OrderListItem, { ORDER_LIST_ITEM_CONTEXT_TYPES } from "../OrdersManagement/orderListItem"

import { SendButton, } from "../../components/Form/main/styles";

import { noProductImageUrl, ORDER_MANAGEMENT_CONTEXT, } from "shared/src/constants/orders"

import OrderDraftPrint from "./orderDraftPrint";


import { Card, CardTitle, InputGroup, InputLabel } from "../../styles/stylesheet"

import IconInput, { IconWrapper } from "../../components/Form/IconInput";

import { colors, fonts } from "../../styles/styleguide";
import ShippingCert from "../../components/ShippingCert";
import LoadingSpinner from "../../components/Loader";
import { firebaseConnect, getVal } from "react-redux-firebase";
import { toastr } from "react-redux-toastr";
import ActionButtonsContainer from "../../components/Form/main/ActionButtonsContainer";
import FormContainer from "../../components/Form/main/FormContainer";
import RoundButton from "../../components/RoundButton";

class OrderForm extends Component {

	constructor() {
		super()
		this.state = {
			focused: false,
			returnDateFocused: false,
			isModalOpen: false,
			isReplaceModalOpen: false,
			replaceModalItem: {},
			modalItem: null,
			supplierId: null,
			contractorId: null,
			modalContext: null,
			printModalOpen: false,
			isPrinting: false,
			collapsedCatalog: false,
			shippingCert: {},
			isOpenShippingCertPreview: false,
			isPreparingShippingCertPrint: false,
			SCLoaded: false,
			isOpenSignatureModal: false,
			signatureImg: null
		}
		this.componentRef = null;
	}

	componentDidMount() {

		const { match: { params }, order, context, getAcceptance } = this.props;
		const { contractorId, supplierId } = params;

		if (!!order.acceptance) {
			getAcceptance({ currentOrder: order }).then(( results ) => {
				console.log("getAcceptance results", results);
				!!results && !!results.signature && this.setState({ signatureImg: results.signature.image });
			});
		}

		if (contractorId && supplierId) {
			this.setState({ contractorId, supplierId })
		}

		if (order.id && context === ORDER_MANAGEMENT_CONTEXT.SUPPLIER) {
			this.initSupplierContext()
		}
	}


	componentDidUpdate( prevProps, prevState, snapshot ) {
		const { order, ordersLoading, ordersContextLoaded, match: { params }, initOrderById, context } = this.props;
		// console.log("ORDER FORM componentDidUpdate", ordersLoading, prevProps.ordersLoading);
		const { contractorId, projectId, supplierId, orderId } = params;
		if (!prevState.contractorId || !prevState.supplierId) {
			if (contractorId && supplierId) {
				this.setState({ contractorId, supplierId })
			}
		}
		if (!order.id && (
			ordersLoading !== prevProps.ordersLoading || ordersContextLoaded !== prevProps.ordersContextLoaded
		) && !ordersLoading && ordersContextLoaded) {
			//  console.log("********************************ORDERS LOADED***************************************");
			initOrderById(orderId);
			this.fetchScreenData({ contractorId, projectId, supplierId, orderId, context })
		}
		if (order.id && prevProps.order.id !== order.id && context === ORDER_MANAGEMENT_CONTEXT.SUPPLIER) {
			this.initSupplierContext()
		}
	}

	fetchScreenData = ( { contractorId, projectId, supplierId, orderId, context } ) => {
		const { fetchItems } = this.props;
		fetchItems({ contractorId, projectId, supplierId, orderId })
	}

	initSupplierContext = async () => {
		const { order, firebase } = this.props;
		if (order && order.scId) {
			try {
				this.setState({ isPreparingShippingCertPrint: true })
				const shippingCert = await getShippingCert(firebase, order, this.props.match.params.supplierId);
				// console.log("SC Loaded", shippingCert);
				this.setState({ shippingCert, isPreparingShippingCertPrint: false, SCLoaded: true })
			} catch (e) {
				console.error("unable to load sc", e)
				this.setState({ isPreparingShippingCertPrint: false })
			}
		}
	}

	printOrder = () => {
		console.log("printing", this.componentRef)
		let content = this.componentRef;
		const contentNodes = findDOMNode(content);
		print(contentNodes, 0, ( error ) => {
			toastr.error(error)
		});
	}


	printShippingCert = () => {
		this.setState({ isPreparingShippingCertPrint: true })
		let content = this.shippingCertRef;
		const contentNodes = findDOMNode(content);
		print(contentNodes, 0, ( error ) => {
			toastr.error(error)
		});
		this.setState({ isPreparingShippingCertPrint: false })
		this.closeShippingCertPreview()
	}

	openShippingCertPreview = () => {
		this.setState({ isOpenShippingCertPreview: true })
	}

	closeShippingCertPreview = () => {
		this.setState({ isOpenShippingCertPreview: false })
	}

	getSummaryList = ( { order } ) => {
		const {
			      // order,
			      match,
			      context
		      } = this.props;

		const params = match ? match.params : {};
		const { projectId } = params;

		const rows = [];

		order.items.forEach(( item, index ) => {
			rows.push(
				<OrderListItem
					catalogId={ item.catalogId }
					imgSrc={ item.downloadURL || noProductImageUrl }
					name={ item.name }
					count={ item.acceptance && item.acceptance.count ? item.acceptance.count : item.count }
					price={ item.default_price }
					room={ item.room || { id: projectId, name: "כללי" } }
					context={ ORDER_LIST_ITEM_CONTEXT_TYPES.ORDER_SUMMARY }
					formContext={ context }
					itemActions={ item.actions }
					item={ item }
					itemAcceptance={ item.acceptance || item.delivery }
					disabled={ true }
					packageInfo={ getItemPackage(item) }
				/>
			)
		})

		return rows.reverse()
	}

	getSummaryProps = ( { order } ) => {
		let orderTotalPrice = 0;
		let rentalLabel = 'ימי השכרה'

		const { price, count } = getOrderTotalPrice(order, true);

		if (order.isRental) {
			orderTotalPrice = order.rentalTotalPrice;
			//    console.log('Rent: ', order.rentInterval)
			if (order.rentInterval === 'week') {
				rentalLabel = 'שבועות השכרה'
			}
		} else {
			orderTotalPrice = price;
		}

		const SummaryItemList = this.getSummaryList({ order })

		return {
			productCount: count,
			totalPrice: orderTotalPrice,
			onPrintClick: () => {
				this.setState({ printModalOpen: true })
			},
			SummaryItemList
		}
	}

	renderOrderSummary = () => {
		const { order } = this.props;

		let orderTotalPrice = 0;
		let rentalLabel = 'ימי השכרה'

		const { price, count } = getOrderTotalPrice(order, true);

		if (order.isRental) {
			orderTotalPrice = order.rentalTotalPrice;
			//    console.log('Rent: ', order.rentInterval)
			if (order.rentInterval === 'week') {
				rentalLabel = 'שבועות השכרה'
			}
		} else {
			orderTotalPrice = price;
		}

		const rows = this.getSummaryList({ order })

		return (
			<Fragment>
				<CardTitle>סיכום הזמנה</CardTitle>
				<Card>
					<div style={ { backgroundColor: "#f9f9f9" } }>
						<div>
							<div>
								<TotalItems>
									{ order.isRental || (
										<span>{ `${ count } פריטים` }</span>
									) }
									{ order.isRental && (
										<span>{ `${ order.rentalDays } ${ rentalLabel }` }</span>
									) }

								</TotalItems>
								<div style={ { display: "flex", justifyContent: "space-between" } }>
									<TotalPrice>&#8362;{ orderTotalPrice }</TotalPrice>
								</div>
							</div>
						</div>
						<List>{ rows }</List>
					</div>
				</Card>
			</Fragment>

		)
	}

	renderDetails = ( { order, signatureImg } ) => {
		return (
			<Card>
				{ order.isInStore ? (
					<Row>
						<Col>
							<div style={ {
								textAlign: "center",
								fontFamily: fonts.OpenSansHebrew,
								fontSize: 18.8,
								color: colors.charcoal,
								backgroundColor: colors.sunflowerYellow,
								marginBottom: 10
							} }>{ "שימו לב! הזמנה זו נוצרה בחנות" }</div>
						</Col>
					</Row>

				) : null }

				<Row>
					<Col md={ 6 } lg={ 2 }>
						<InputGroup>
							<InputLabel>פרוייקט</InputLabel>
							<IconInput
								type={ "select" }
								options={ [ { id: order.project.id, name: order.project.name } ] }
								value={ order.project.id }
								disabled={ true }
								icon={ <img src={ "img/icons/home.svg" }/> }
								valueAccessor={ "id" }
								labelAccessor={ "name" }
							/>
						</InputGroup>
					</Col>
					<Col md={ 6 } lg={ 2 }>
						<InputGroup>
							<InputLabel>ספק</InputLabel>
							<IconInput
								type={ "select" }
								options={ [ { id: order.supplier.id, name: order.supplier.name } ] }
								value={ order.supplier.id }
								disabled={ true }
								icon={ <img src={ "img/icons/home.svg" }/> }
								valueAccessor={ "id" }
								labelAccessor={ "name" }
							/>
						</InputGroup>
					</Col>
					<Col md={ 6 } lg={ 2 }>
						<InputGroup>
							<InputLabel>תאריך אספקה</InputLabel>
							<div style={ { position: "relative" } }>
								<IconWrapper><img src={ "img/icons/date.svg" }/></IconWrapper>
								<SingleDatePicker
									disabled={ true }
									date={ moment(order.deliveryDate) } // momentPropTypes.momentObj or null
									onDateChange={ () => {
									} } // PropTypes.func.isRequired
									focused={ this.state.focused } // PropTypes.bool
									onFocusChange={ ( { focused } ) => this.setState({ focused }) } // PropTypes.func.isRequired
									id="deliveryDate" // PropTypes.string.isRequired,
									isRTL={ true }
									withPortal={ true }
									appendToBody={ true }
									disableScroll={ true }
									small={ true }
									block={ true }
									displayFormat={ "D/MM/YY" }
									isOutsideRange={ () => false }
								/>
							</div>
						</InputGroup>
					</Col>
					<Col md={ 6 } lg={ 2 }>
						<InputGroup>
							<InputLabel>מס תעודת משלוח</InputLabel>
							<IconInput
								type={ "text" }
								disabled={ true }
								value={ order.orderId }
								icon={ <img src={ "img/icons/list.svg" }/> }
							/>
						</InputGroup>
					</Col>
					<Col md={ 6 } lg={ 4 }>
						<InputGroup>
							<InputLabel>הערות ספק</InputLabel>
							<textarea
								disabled={ true }
								style={ {
									width: "100%",
									borderRadius: 3,
									border: "1px solid #E0E0E0",
									resize: "none"
								} }
								value={ order.supplierNote }
							/>
						</InputGroup>
					</Col>
				</Row>
				<Row>
					<Col md={ 6 }>
						<InputGroup>
							<InputLabel>הערות לקוח</InputLabel>
							<textarea
								disabled={ true }
								style={ {
									width: "100%",
									minHeight: 72,
									borderRadius: 3,
									border: "1px solid #E0E0E0",
									resize: "none"
								} }
								value={ order.note }
							/>
						</InputGroup>
					</Col>
					<Col md={ 6 }>
						<InputGroup>
							<InputLabel>משהו נוסף?</InputLabel>
							<textarea
								disabled={ true }
								style={ {
									width: "100%",
									minHeight: 72,
									borderRadius: 3,
									border: "1px solid #E0E0E0",
									resize: "none"
								} }
								value={ order.anythingElse }
							/>
						</InputGroup>
					</Col>
					<Col md={ 6 } lg={ 2 }>
						<InputGroup>
							<InputLabel>שם מזמין</InputLabel>
							<IconInput
								type={ "text" }
								value={ order.sentBy ? order.sentBy.displayName : "" }
								disabled={ true }
								icon={ <img src={ "img/icons/person.svg" }/> }
							/>
						</InputGroup>
					</Col>
					{ order.acceptance && order.acceptance.signature && (
						<Col md={ 6 } lg={ 2 }>
							<InputGroup>
								<InputLabel>נחתם ע"י</InputLabel>
								<div onClick={ () => {
									if (!!signatureImg) {
										this.setState({ isOpenSignatureModal: true })
									}
								} }>
									<IconInput
										disabled={ !signatureImg }
										type={ "button" }
										value={ order.acceptance.signature.name }
										icon={ <img src={ "img/icons/person.svg" }/> }
									/>
								</div>
							</InputGroup>
						</Col>
					) }
					{ order.status && order.status.approved && (
						<Col sm={ 6 } md={ 6 } lg={ 2 }>
							<TotalItems>{ `נשלח לספק בתאריך ${ moment(order.status.approved)
								.format("D/M/YY") } בשעה ${ moment(order.status.approved)
								.format("HH:mm:ss") }` }</TotalItems>
						</Col>
					) }
					<Col sm={ 6 } md={ 6 } lg={ 2 }>
						{ !!order.status && !!order.status.canceled && (
							!!order.canceledBy
								? (
									<TotalItems>
										{
											'בוטל ע"י '
											+ order.canceledBy.displayName
											+ "\nבתאריך: "
											+ moment(order.status.canceled).format("D/M/YY")
											+ "\nבשעה: "
											+ moment(order.status.canceled).format("HH:mm:ss")
										}
									</TotalItems>
								)
								: (
									<TotalItems>
										{
											"בוטל בתאריך: "
											+ moment(order.status.canceled).format("D/M/YY")
											+ "\nבשעה: "
											+ moment(order.status.canceled).format("HH:mm:ss")
										}
									</TotalItems>
								)
						) }
					</Col>
					<Col sm={ 6 } md={ 6 } lg={ 2 }>
						{ !!order.openedBy && !!order.openedBy.displayName && (
							<TotalItems>{ `נפתחה עי"י ${ order.openedBy.displayName }` }</TotalItems>
						) }
					</Col>
				</Row>
			</Card>
		)
	}

	render() {
		const { order, context, printHeaderMarginTop } = this.props;

		const {
			      isOpenShippingCertPreview,
			      shippingCert,
			      isPreparingShippingCertPrint,
			      SCLoaded,
			      signatureImg
		      } = this.state;

		console.log("History render order", order)

		const ActionButtonsComponent = ( { setShouldOpen } ) => (
			<ActionButtonsContainer>
				{ context === ORDER_MANAGEMENT_CONTEXT.SUPPLIER && order.scId && SCLoaded && (
					<SendButton style={ { marginRight: 15, marginLeft: 15 } }
					            backgroundColor={ "#5069d9" }
					            onClick={ () => {
						            this.openShippingCertPreview()
					            } }>{ "הדפסת תעודת משלוח" }</SendButton>
				)
				}
			</ActionButtonsContainer>
		)

		const DetailsComponentCard = this.renderDetails({ order, signatureImg })

		const detailsProps = {
			ActionButtonsComponent,
			DetailsComponentCard,
		}

		const summaryProps = this.getSummaryProps({ order })

		return (
			<div style={ { backgroundColor: "#eeeeee" } }>
				<Fragment>
					<FormContainer
						detailsProps={ detailsProps }
						summaryProps={ summaryProps }
					/>

					{
						// <div>
						//     <Row>
						//         <Col md={12}>
						//             <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
						//                 <CardTitle>פרטי הזמנה</CardTitle>
						//                 {ActionButtonsComponent}
						//             </div>
						//             {DetailsComponentCard}
						//         </Col>
						//     </Row>
						//     <Row>
						//         <Col md={12}>
						//             {this.renderOrderSummary()}
						//         </Col>
						//     </Row>
						// </div>
					}
				</Fragment>

				<Modal isOpen={ this.state.printModalOpen } toggle={ () => {
					this.setState({ printModalOpen: false })
				} }>
					<ModalBody>
						<SendButton
							onClick={ () => this.setState({ isPrinting: true }, this.printOrder) }>הדפסה</SendButton>
						<div style={ { marginTop: -170 } }></div>
						<OrderDraftPrint
							ref={ el => (
								this.componentRef = el
							) }
							deliveryDate={ order.deliveryDate }
							address={ order && order.project ? order.project.address : "" }
							contactPersonInSite={ order && order.sentBy ? order.sentBy.displayName : "" }
							contactPhoneNumber={ order && order.sentBy ? order.sentBy.phoneNumber : "" }
							customerName={ order && order.contractor ? order.contractor.name : "" }
							items={ order.items }
							notes={ order.note }
							supplierNotes={ order.supplierNote }
							anythingElse={ order.anythingElse }
							whoOrdered={ order && order.sentBy ? order.sentBy.displayName : "" }
							isPrinting={ true }
						/>
					</ModalBody>
				</Modal>

				{ !!signatureImg && (
					<Modal
						size={ "lg" }
						isOpen={ this.state.isOpenSignatureModal }
						toggle={ () => {
							this.setState({ isOpenSignatureModal: false });
						} }
                        style={{
                            flex: 1,
                            backgroundColor: "#fff"
                        }}
					>
						<ModalBody>
                            <div
                                // style={{
                                //     flex: 1,
                                //     height: "200px",
                                //     // width: "400px",
                                //     backgroundColor: "#fff"
                                // }}
                            >
                                <img
                                    src={ signatureImg }
                                    style={{
                                        // height: "250px",
                                        height: 300,
                                        backgroundColor: "#fff"
                                    }}
                                    // style={{"object-fit": "contain"}}
                                />
                            </div>
						</ModalBody>
						<ModalFooter>
							<RoundButton
								label={ "סגור" }
								onClick={ () => {
									this.setState({
										isOpenSignatureModal: false,
									});
								} }
								backgroundColor={ "#fff" }
							/>
						</ModalFooter>
					</Modal>
				) }

				{ context === ORDER_MANAGEMENT_CONTEXT.SUPPLIER && (
					<Modal size={ "xl" } isOpen={ isOpenShippingCertPreview } toggle={ () => {
						this.closeShippingCertPreview()
					} }>
						<ModalBody>
							<div style={ { display: "flex", flexDirection: "row", alignItems: "flex-end" } }>
								<SendButton style={ { flex: 6 } }
								            onClick={ () => this.setState(
									            { isPrinting: true },
									            this.printShippingCert
								            ) }>הדפסה</SendButton>
								<SendButton style={ { flex: 2, marginRight: 20, backgroundColor: colors.red } }
								            onClick={ this.closeShippingCertPreview }>ביטול</SendButton>
							</div>
							<div ref={ el => (
								this.shippingCertRef = el
							) }>
								<ShippingCert
									headerMarginTop={ printHeaderMarginTop || 0 }
									printLabel={ "מקור" }
									shippingCert={ shippingCert }
									copies={ [ { label: "עותק משוחזר", showPreview: true } ] }
								/>
							</div>
						</ModalBody>
						{ isPreparingShippingCertPrint && (
							<LoadingSpinner/>
						) }
					</Modal>
				) }
			</div>
		)
	}
}


OrderForm.propTypes = {
	context: PropTypes.string
};


const mapStateToProps = ( state, props ) => (
	{
		ordersContextLoaded: state.orders.ordersContextLoaded,
		ordersLoading: state.orders.ordersLoading,
		itemsLoading: state.orders.itemsLoading,
		order: !!state.orders.newOrder.id ? state.orders.newOrder : state.ordersHistory.currentOrder,
		contractors: state.orders.contractors,
		projects: state.orders.projects,
		contractorId: state.orders.contractorId,
		roles: state.roles.roles,
		isLoading: state.orders.itemsLoading || state.ordersHistory.isLoading,
		isLoadingCatalog: state.catalog.isLoading,
		query: state.catalog.query,
		catalog: state.catalog.catalog,
		searchHistory: state.autoComplete.searchHistory,
		printHeaderMarginTop: props.context === ORDER_MANAGEMENT_CONTEXT.SUPPLIER ? getVal(
			state.firebase.data,
			`suppliersPrivateData/${ props.match.params.supplierId }/printMarginTop`,
			0
		) : 0,
	}
);

const mapDispatchToProps = dispatch => bindActionCreators({
	fetchItems,
	initOrderById,
	getAcceptance
}, dispatch);


const TotalItems = styled.div`
  font-family: 'Rubik', sans-serif;
  font-size: 14px;
  color: #828282;
  margin-top: 11px;
`;

const TotalPrice = styled.div`
  font-family: 'Rubik', sans-serif;
  font-size: 36px;
  color: #4F4F4F;
`;

// const SendButton = styled.button`
//     border: none;
//     border-radius: 3px;
//     font-family: 'Rubik', sans-serif;
//     font-size: 18px;
//     text-align: center;
//     color: #FFFFFF;
//     height: 50px;
//     line-height: 50px;
//     padding: 0 11px;
//     cursor:  ${props => props.disabled ? "default" : "pointer"};
//     box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1);
//     background-color: ${props => props.disabled ? "#818181" : props.backgroundColor ? props.backgroundColor : "#319CD8"};
// `


export default compose(
	connect(
		mapStateToProps, mapDispatchToProps
	),
	firebaseConnect(( props ) => {
		const paths = [];
		paths.push(`suppliersPrivateData/${ props.match.params.supplierId }/printMarginTop`);
		return paths
	})
)(OrderForm);


