import React, { PureComponent } from 'react';
import PropTypes from 'prop-types'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import ConfirmButton from "./ConfirmButton";
import RoundButton from "./RoundButton"

class Confirm extends PureComponent {
    render() {

        const {
            isOpen,
            title,
            body,
            confirmButtonLabel,
            rejectButtonLabel,
            onConfirm,
            onReject
        } = this.props;

        return (
            <Modal isOpen={isOpen} toggle={onReject}>
                <ModalHeader>{title}</ModalHeader>
                <ModalBody>
                    {body}
                </ModalBody>
                <ModalFooter>
                    <ConfirmButton
                        onClick={onConfirm}
                        label={confirmButtonLabel}
                    />
                    <RoundButton
                        label={rejectButtonLabel}
                        onClick={onReject}
                        backgroundColor={"#fff"}
                    />
                </ModalFooter>
            </Modal>
        );
    }
}


Confirm.propTypes = {
    isOpen: PropTypes.bool,
    title: PropTypes.string,
    body: PropTypes.string,
    confirmButtonLabel: PropTypes.string,
    rejectButtonLabel: PropTypes.string,
    onConfirm: PropTypes.func,
    onReject: PropTypes.func
};

Confirm.defaultProps = {
    confirmButtonLabel: "אישור",
    rejectButtonLabel: "ביטול"
};


export default Confirm;