import React, {Component} from 'react';
import {
    Container,
    Row,
    Col,
    CardGroup,
    Card,
    CardBody,
    Button,
    Input,
    InputGroup,
    InputGroupAddon,
    Label,
    FormGroup
} from 'reactstrap';

import {firebaseConnect, isEmpty, isLoaded} from 'react-redux-firebase'
import styled from 'styled-components';
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux';
import {isEqual} from "lodash";

import {toastr} from 'react-redux-toastr'

import AuthPage, {GoogleButton, GoogleLogo, LoginError} from "../AuthPage";

class PasswordReset extends Component {

    constructor() {
        super();
        this.state = {
            email: "",
            password: "",
            loginError: "",
            loginSuccess: false
        }
    }

    componentDidUpdate(prevProps){
        const {history, auth} = this.props;
        if(!isEqual(prevProps.auth) && auth && isLoaded(auth) && !isEmpty(auth) && this.state.loginSuccess){
            history.push("/");
        }
    }

    _handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            console.log('enter pressed');
            this.onSubmit();
        }
    }

    sendResetPasswordLink = (email) => {
        console.log("send reset password to", email)
        const {firebase} = this.props;

        const auth = firebase.auth();

        return auth.sendPasswordResetEmail(email)
    };

    validateEmail = (email) => {
        let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }


    onSubmit = ()=> {
        const {email} = this.state;
        if(!this.validateEmail(email)){
            this.setState({loginError: "אנא הכניסו כתובת אימייל תקינה"});
            return;
        }
        this.setState({loginError: ""});
        this.sendResetPasswordLink(email).then(()=>{
            toastr.success("שלחנו לכם אימייל עם הנחיות לאיפוס הסיסמא")
        }).catch(()=>{
            toastr.warn("אירעה שגיאה בעת הניסיון לשלוח לכם אימייל, אנא נסו שנית")
        })
    }


    render() {

        const {email, loginError} = this.state;

        return (
            <AuthPage title={"איפוס סיסמא"}>
                <LoginError>{loginError}</LoginError>
                <FormGroup>
                    <Label for="email">אימייל</Label>
                    <Input type="email" id={"email"} value={email}
                           onChange={(e) => {
                               this.setState({email: e.target.value})
                           }}
                           onKeyPress={this._handleKeyPress}/>
                </FormGroup>


                <Row>
                    <Col xs="6">
                        <Button color={"#BBD9EA"} className="px-4" style={{
                            color: "#75B7DC",
                            borderRadius: 3,
                            fontSize: 18,
                            fontFamily: "'Rubik', sans-serif"
                        }}
                                onClick={this.onSubmit}
                                onKeyPress={this._handleKeyPress}>שלח</Button>
                    </Col>

                </Row>
                <Row style={{marginTop: 15}}>
                    <Col xs={12}>
                        <div className="text-left">
                            <Button color="link" onClick={() => {
                                this.props.history.push("/login")
                            }} className="px-0">עברו לעמוד הכניסה</Button>
                        </div>
                    </Col>
                </Row>
            </AuthPage>

        );
    }
}

const mapStateToProps = ({firebase: {auth}}) => ({
    auth: auth,
});

const mapDispatchToProps = dispatch => bindActionCreators({

}, dispatch);

export default firebaseConnect()(connect(mapStateToProps, mapDispatchToProps)(PasswordReset));
