import React from 'react';
import { hot } from 'react-hot-loader'
import { ConnectedRouter } from 'connected-react-router'
import {Provider} from 'react-redux'
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import store, {history, rrfProps} from './store'

import IntlWrapper from './containers/IntlWrapper';

import '@fortawesome/fontawesome-free/js/fontawesome'
import '@fortawesome/fontawesome-free/js/solid'
import '@fortawesome/fontawesome-free/js/regular'
import '@fortawesome/fontawesome-free/js/brands'

// Styles
// Import Font Awesome Icons Set
import 'font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
import 'simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
import '../scss/style.scss'
// Temp fix for reactstrap
import '../scss/core/_dropdown-menu-right.scss'

import AppWrapper from "./AppWrapper";


import ReactTooltip from 'react-tooltip';
const App = ()=> (<Provider store={store}>
    <ReactReduxFirebaseProvider {...rrfProps}>
    <IntlWrapper>
        <ConnectedRouter history={history}>
            <AppWrapper/>
        </ConnectedRouter>
    </IntlWrapper>
    </ReactReduxFirebaseProvider>

</Provider>);

export default hot(module)(App);
