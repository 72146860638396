import {GA_UA} from "./constants/env";

import ReactGA from 'react-ga';
//ReactGA.initialize('UA-000000-01');

class AnalyticsAgent {
    constructor(UA){
        if(AnalyticsAgent.instance){
            return AnalyticsAgent.instance;
        }

        ReactGA.initialize(UA,  { debug: true });
        this.ua = UA;
        this.uid = null;
        this.dimentions = {};
        AnalyticsAgent.instance = this;

        return AnalyticsAgent.instance;
    }

    setGAInstance = (uid) =>{
        if(uid === this.uid){
            console.log("uid already instantiated", uid);
            return;
        }
        this.uid = uid;

        ReactGA.initialize(this.ua,  { debug: true });
        if(uid){
            ReactGA.set({ userId: uid });
        }

    }

    getAgent(){
        return ReactGA;
    }

    hit(path){
        let pageView = {
               /// required
        }
        for(let key in this.dimentions){
            pageView[`dimension${key}`] = this.dimentions[key];
        }
        ReactGA.ga('send','pageview', path, pageView);
    }

    /**
     *
     * @param category
     * @param action
     * @param label
     * @param value
     */
    event(category, action, label, value){
        let event = {
            category,
            action,
            label,
            value
        };

        for (let key in this.dimentions) {
            event[`dimension${key}`] = this.dimentions[key];
        }

        ReactGA.event(event);
    }

    addDimention(id, label){
        this.dimentions[id] = label;

    }

    removeDimention(id){
        if(this.dimentions[id]){
            delete this.dimentions[id];
        }
    }
}

const instance = new AnalyticsAgent(GA_UA);
//Object.freeze(instance);

export default instance;
