import React, {Fragment} from "react";
import moment from "moment"
import FontIcon from "material-ui/FontIcon";
import { iconStyle, inputStyle, wrapperStyle } from "./style";


const DateRangeFilter = ({startDateFilter, endDateFilter, onChange, onChangeCallbackSetter, showDatePicker, onClose}) => {
    onChangeCallbackSetter(onChange);
    return (
        <div style={wrapperStyle}>
            <img style={iconStyle} src={"img/icons/calendar.svg"}/>
            <div
                style={Object.assign({}, {inputStyle}, {display: "flex", flexDirection: "row", alignItems: "center", height: 28})}
            >
                {startDateFilter && endDateFilter ? (
                    <Fragment>
                        <span
                            onClick={showDatePicker}>{moment(startDateFilter).format("D/MM/YY")} - {moment(endDateFilter).format("D/MM/YY")}</span>
                        <FontIcon onClick={onClose} className="material-icons pointer"
                                  color={"#6e6e6e"}>close</FontIcon>
                    </Fragment>
                ) : (
                    <div onClick={showDatePicker} style={{textAlign: "center", paddingRight: 17, paddingLeft: 17}}>
                        בחרו תאריכים
                    </div>

                )}

            </div>
        </div>
    )
}

export default DateRangeFilter;