import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux';

import { firebaseConnect, isLoaded } from 'react-redux-firebase'
import { compose } from 'redux'
import _, { orderBy } from "lodash"
import { uuidWithoutSpace } from "shared/src/utils";

import { Row, Col } from "reactstrap"
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import FontIcon from 'material-ui/FontIcon';
import styled from "styled-components";

import StickyFloatingButton from "../../components/StickyFloatingButton"
import Select, { Creatable } from 'react-select';
import { getContractorUsers, getContractorProjects } from "shared/src/api/contractors";
import { isContractorAdmin } from "shared/src/selectors/roles";
import { getVal } from "react-redux-firebase";


import {
    Card,
    CartHeader,
    DetailsRow,
    Detail,
    InputLabel,
    InputGroup,
    Button
} from "../../styles/stylesheet"
import GooglePlacesAutocomplete from "../../components/GooglePlacesAutocomplete";

class Projects extends Component {

    constructor(props) {
        super(props);

        this.defaultProject = {
            data: {
                id: null,
                address: "",
                name: "",
                notes: "",
                budget: 0
            },
            rooms: {},
            suppliers: {},
            stuff: {},
            projectManagers: {},
            disabled: false,
            disableTime: null

        }

        this.state = {
            isModalOpen: false,
            modalContext: null,
            modalProject: this.defaultProject,
            modalProjectSnapshot: this.defaultProject,
            isAdmin: false,
            contractorUsers: {},
            projects: [],
            addressError: false,
            nameError: false,
            suppliersError: false
        }


    }

    initPermissions = () => {
        const {appUser, contractorId} = this.props;
        this.setState({isAdmin: isContractorAdmin(appUser, contractorId)})
    }

    initUsers = () => {
        const {contractors, firebase, contractorId} = this.props;
        const contUsers = contractors[contractorId] && contractors[contractorId].users ?
            contractors[contractorId].users : {}
        getContractorUsers(firebase, Object.keys(contUsers), contractorId).then(contractorUsers => {
            this.setState({contractorUsers})
        })
    }

    initProjects = () => {
        const {firebase, contractorId, appUser} = this.props;
        getContractorProjects(firebase, contractorId, appUser).then(projects => {
            this.setState({projects})
        })
    }

    componentDidMount() {
        this.fetchScreenData()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.contractor, this.props.contractor) || !_.isEqual(prevProps.contractorId, this.props.contractorId)) {
            this.fetchScreenData()
        }
    }

    fetchScreenData = () => {
        this.initUsers()
        this.initProjects()
        this.initPermissions()
    }

    renderProject = (id, project) => {
        return (
            <Card onClick={() => {
                this.openProjectModal(Object.assign({}, this.defaultProject, {id}, project))
            }}>
                <Row>
                    <Col md={4}>
                        <DetailsRow>
                            <FontIcon style={{fontSize: 18}} color={"#636363"}
                                      className={"material-icons"}>location_city</FontIcon>
                            <Detail>
                                {project.data.name}
                            </Detail>
                        </DetailsRow>

                        <DetailsRow>
                            <FontIcon style={{fontSize: 18}} color={"#636363"}
                                      className={"material-icons"}>place</FontIcon>
                            <Detail>{project.data.address}</Detail>
                        </DetailsRow>

                    </Col>
                    <Col md={4}>
                        <Detail>מנהלים</Detail>
                        <DetailsRow>
                            {this.renderPMs(project)}
                        </DetailsRow>
                    </Col>
                    <Col md={4}>
                        <Detail>אנשי צוות</Detail>
                        <DetailsRow>
                            {this.renderUsers(project)}
                        </DetailsRow>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        {project.data.notes ? (
                            <div>
                                <Detail>הערות לפרוייקט</Detail>
                                <DetailsRow>
                                    {project.data.notes}
                                </DetailsRow>
                            </div>
                        ) : null}
                    </Col>
                </Row>
            </Card>
        )
    }

    renderPMs = (project) => {

        const toRender = [];

        console.log("project PM", project)
        const {contractorUsers} = this.state;
        if (project.projectManagers && contractorUsers) {
            for (let key in project.projectManagers) {
                toRender.push(this.renderUser(contractorUsers[key]))
            }
        }

        return toRender;
    }

    renderUsers = (project) => {

        const toRender = [];

        console.log("project PM", project)
        const {contractorUsers} = this.state;
        if (project.users && contractorUsers) {
            for (let key in project.users) {
                toRender.push(this.renderUser(contractorUsers[key]))
            }
        }

        return toRender;
    }

    renderUser = (user) => {
        user = user || {}

        const formated = `${user.displayName || ""} - ${user.email || user.phoneNumber || ""}`
        return (
            <div>
                <FontIcon style={{fontSize: 18}} color={"#636363"}
                          className={"material-icons"}>person</FontIcon>
                <Detail>{formated}</Detail>
            </div>

        )
    }

    openProjectModal = (project) => {
        this.setState({
            isModalOpen: true,
            modalProject: project,
            modalProjectSnapshot: project
        })
        console.log("modalProject", project)
    }

    renderProjects = () => {
        const projects = this.state.projects;
        console.log("projects", projects)
        let toRender = [];
        const list = [];

        for (let key in projects) {
            const name = projects[key].data.name;
            list.push({name, key})
        }
        const sorted = orderBy(list, ["name"]);
        if (sorted) {
            toRender = sorted.map((item, index) => {
                return this.renderProject(item.key, projects[item.key])
            })
            // toRender.push(this.renderSupplier(key, suppliers[key]))
        }

        /* if (projects) {
             for (let key in projects) {
                 toRender.push(this.renderProject(key, projects[key]))
             }
         }*/
        return toRender;
    }

    transformToSchema = (project, id) => {
        if (!project.id) {
            project.id = id;
        }
        if (!project.data.id) {
            project.data.id = id;
        }

        return project;
    }

    transformSelectListToObject = (list) => {
        const obj = {};
        for (let key in list) {
            const row = list[key];
            if (row.__isNew__) {
                const id = uuidWithoutSpace();
                obj[id] = {id: id, name: row.label}
            } else {
                obj[row.value] = {id: row.value, name: row.label}
            }
        }
        return obj;
    }

    transformSelectedUserToObject = (user, isAdmin = true) => {
        console.log("Transform", user)
        /* const obj = {
             [user[0].value]: {
                 admin: 200
             }
         };
         return obj;*/


        const obj = {};
        for (let key in user) {
            const row = user[key];
            if (isAdmin) {
                obj[row.value] = {id: row.value}
            } else {
                obj[row.value] = {id: row.value}
            }


        }
        return obj;
    }

    transformObjectToSelectList = (obj, type = "suppliers") => {
        const list = [];
        for (let key in obj) {
            const row = obj[key];
            const item = {value: key};
            if (type === "suppliers") {
                // get supplier name
                const {suppliers} = this.props;
                if (suppliers && suppliers[key]) {
                    item.label = _.get(suppliers, `${key}.name`);
                } else {
                    item.label = "לא ידוע"
                }
            } else if (type === "users") {
                // console.log("user item", row);
                item.label = `${row.displayName || ""} - ${row.email}`;
            } else if (type === "projectUsers") {
                console.log("user item", row);
                const contractorUsers = this.state.contractorUsers;
                const contractorUser = contractorUsers[key];
                if (!contractorUser) {
                    continue;
                }
                item.label = `${contractorUser.displayName || ""} - ${contractorUser.email}`;
            } else {
                item.label = row.name;
            }
            list.push(item);

        }
        return list;

    }

    onSuppliersChange = (value) => {
        console.log("onSuppliersChange", value);
        const {modalProject} = this.state;
        const project = _.cloneDeep(modalProject);
        const suppliers = this.transformSelectListToObject(value);
        console.log(suppliers);
        project.suppliers = suppliers;
        this.setState({modalProject: project});

    }

    onProjectManagerChange = (value) => {
        console.log("onProjectManagerChange", value);
        const {modalProject} = this.state;
        const project = _.cloneDeep(modalProject);
        const pm = this.transformSelectedUserToObject(value);
        console.log(pm);
        project.projectManagers = pm;
        this.setState({modalProject: project});

    }

    onCrewMembersChange = (value) => {
        console.log("onCrewMembersChange", value);
        const {modalProject} = this.state;
        const project = _.cloneDeep(modalProject);
        const pm = this.transformSelectedUserToObject(value, false);
        console.log(pm);
        project.users = pm;
        this.setState({modalProject: project});

    }

    onRoomChange = (newValue, actionMeta) => {
        console.group('Value Changed');
        console.log(newValue);
        console.log(`action: ${actionMeta.action}`);

        const {modalProject} = this.state;
        const project = _.cloneDeep(modalProject);
        const rooms = this.transformSelectListToObject(newValue);
        console.log(rooms);
        console.groupEnd();
        project.rooms = rooms;
        this.setState({modalProject: project});
    }

    getProjectUsersDiff(newList, oldList, toAdd, toDelete) {
        newList = newList || {}
        oldList = oldList || {}
        const newStaffKeys = Object.keys(newList);
        const oldStuffKeys = Object.keys(oldList);

        newStaffKeys.forEach((id, index) => {
            if (!oldList[id]) {
                toAdd.push(id);
            }
        });

        oldStuffKeys.forEach((id, index) => {
            if (!newList[id]) {
                toDelete.push(id);
            }
        });
    }

    render() {

        const {isModalOpen, modalProject, contractorUsers, isAdmin, addressError, nameError, modalProjectSnapshot, suppliersError} = this.state;
        const {firebase, suppliers, contractorId, contractorSuppliers} = this.props;

        console.log("contractorSuppliers", contractorSuppliers)

        const initialProjectId = modalProject.data.id;
        return (
            <div>
                <div>
                    {this.renderProjects()}
                </div>
                {isAdmin && (
                    <StickyFloatingButton
                        icon={"add"}
                        onPress={() => {
                            this.openProjectModal(this.defaultProject)
                        }}
                    />
                )}
                <Modal isOpen={isModalOpen}>
                    <ModalHeader>
                        <span>{initialProjectId ? "עריכת פרוייקט" : "יצירת פרוייקט"}</span>
                        <ModalCloseButtonWrapper onClick={() => {
                            this.setState({
                                isModalOpen: false,
                                addressError: false,
                                nameError: false,
                                suppliersError: false
                            })
                        }}>
                            <FontIcon color={"#636363"} className={"material-icons"}>close</FontIcon>
                        </ModalCloseButtonWrapper>
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col md={6}>
                                <InputGroup>
                                    <InputLabel>
                                        שם
                                    </InputLabel>
                                    <input style={{width: "100%", height: 34}} type={"text"} onChange={(e) => {
                                        const project = _.cloneDeep(modalProject);
                                        project.data.name = e.target.value;
                                        this.setState({modalProject: project})
                                    }}
                                           value={modalProject.data.name}
                                    />
                                    {nameError && (<div style={{color: "red"}}>אנא הזינו את שם הפרוייקט</div>)}
                                </InputGroup>
                            </Col>
                            <Col md={6}>
                                <InputGroup>
                                    <InputLabel>
                                        ספקים
                                    </InputLabel>
                                    <Select
                                        onChange={(selectedOptions) => {
                                            this.onSuppliersChange(selectedOptions)
                                        }}
                                        value={this.transformObjectToSelectList(modalProject.suppliers)}
                                        isMulti={true}
                                        options={this.transformObjectToSelectList(contractorSuppliers)}
                                    />
                                    {suppliersError && (<div style={{color: "red"}}>אנא בחרו לפחות ספק אחד</div>)}
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={7}>
                                <InputGroup>
                                    <InputLabel>
                                        כתובת
                                    </InputLabel>
                                    {
                                        // <input style={{width: "100%", height: 34}} type={"text"} onChange={(e) => {
                                        //     const project = _.cloneDeep(modalProject);
                                        //     project.data.address = e.target.value;
                                        //     this.setState({modalProject: project})
                                        // }}
                                        //        value={modalProject.data.address}
                                        // />
                                    }
                                    <GooglePlacesAutocomplete
                                        onDone={(value) => {
                                            const project = _.cloneDeep(modalProject);

                                            project.data.address = value.name;
                                            project.data.addressObject = value;

                                            this.setState({ modalProject: project })
                                        }}
                                        currentAddress={modalProject.data.addressObject || modalProject.data.address}
                                    />
                                    {addressError && (<div style={{color: "red"}}>אנא הזינו את כתובת הפרוייקט</div>)}
                                </InputGroup>

                                {!!modalProject.data.addressObject && (
                                    <div>
                                        <InputGroup>דירה</InputGroup>
                                        <input
                                            style={{height: 34 }}
                                            type={"number"}
                                            onChange={(e) => {
                                                const apt = e.target.value
                                                const project = _.cloneDeep(modalProject);
                                                if (!!apt && apt.trim() !== "") {
                                                    const addressComponents = project.data.addressObject.name.split(",")

                                                    let address = addressComponents[0].concat(
                                                        // ",",
                                                        " ",
                                                        "דירה",
                                                        " ",
                                                        apt
                                                    )

                                                    for (let i = 1; i < addressComponents.length; i++) {
                                                        address += ","
                                                        address += addressComponents[i]
                                                    }

                                                    project.data.addressObject.apt = apt
                                                    project.data.address = address
                                                } else {
                                                    delete project.data.addressObject.apt
                                                    project.data.address = project.data.addressObject.name
                                                }

                                                this.setState({ modalProject: project })
                                            }}
                                            value={modalProject.data.addressObject.apt}
                                        />
                                    </div>
                                )}
                            </Col>
                            <Col md={5}>
                                <InputGroup>חדרים</InputGroup>
                                <Creatable
                                    isMulti={true}
                                    onChange={this.onRoomChange}
                                    options={[]}
                                    value={this.transformObjectToSelectList(modalProject.rooms, "rooms")}
                                />
                            </Col>
                        </Row>
                        {initialProjectId && (
                            <Fragment>
                                <Row>
                                    <Col md={12}>
                                        <InputGroup>
                                            <InputLabel>
                                                מנהלי פרוייקט
                                            </InputLabel>
                                            <Select
                                                isDisabled={true}
                                                onChange={(selectedOptions) => {
                                                    this.onProjectManagerChange(selectedOptions)
                                                }}
                                                value={this.transformObjectToSelectList(modalProject.projectManagers, "projectUsers")}
                                                isMulti={true}
                                                options={this.transformObjectToSelectList(contractorUsers, "projectUsers")}
                                            />
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <InputGroup>
                                            <InputLabel>
                                                אנשי צוות
                                            </InputLabel>
                                            <Select
                                                isDisabled={true}

                                                onChange={(selectedOptions) => {
                                                    this.onCrewMembersChange(selectedOptions)
                                                }}
                                                value={this.transformObjectToSelectList(modalProject.users, "projectUsers")}
                                                isMulti={true}
                                                options={this.transformObjectToSelectList(contractorUsers, "projectUsers")}
                                            />
                                        </InputGroup>
                                    </Col>
                                </Row>
                            </Fragment>

                        )}


                        <Row>
                            <Col md={12}>
                                <InputGroup>
                                    <InputLabel>
                                        הערות
                                    </InputLabel>
                                    <textarea style={{width: "100%", height: 34}} onChange={(e) => {
                                        const project = _.cloneDeep(modalProject);
                                        project.data.notes = e.target.value;
                                        this.setState({modalProject: project})
                                    }}
                                              value={modalProject.data.notes || ""}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={12}>
                                <InputGroup>
                                    <InputLabel>
                                        הערות
                                    </InputLabel>
                                    <textarea style={{width: "100%", height: 34}} onChange={(e) => {
                                        const project = _.cloneDeep(modalProject);
                                        project.data.notes = e.target.value;
                                        this.setState({modalProject: project})
                                    }}
                                              value={modalProject.data.notes || ""}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={6}>
                                <InputGroup>
                                    <InputLabel>
                                        פעיל
                                    </InputLabel>
                                    <input
                                        style={{width: 20, height: 20}}
                                        type={"checkbox"}
                                        onChange={(e) => {
                                            const disabled = !e.target.checked;
                                            if (disabled) {
                                                const shouldDisable = window.confirm("אתם עומדים לנטרל את הפרוייקט, האם אתם בטוחים?")
                                                if (!shouldDisable) {
                                                    return;
                                                }
                                            }
                                            const updatedProj = Object.assign({}, modalProject, {
                                                disabled,
                                                disableTime: disabled ? Date.now() : null
                                            })
                                            this.setState({
                                                modalProject: updatedProj
                                            })
                                        }}
                                        checked={!modalProject.disabled}
                                    />
                                </InputGroup>
                            </Col>
                            <Col md={6}>
                                <InputGroup>
                                    <InputLabel>
                                        תקציב
                                    </InputLabel>
                                    <input style={{width: "100%", height: 34}} type={"number"} onChange={(e) => {
                                        const project = _.cloneDeep(modalProject);
                                        project.data.budget = e.target.value;
                                        this.setState({modalProject: project})
                                    }}
                                           value={modalProject.data.budget}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>

                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={() => {
                            let isValid = true;
                            if (!modalProject.data.address) {
                                isValid = false
                                this.setState({addressError: true})
                            }

                            if (!modalProject.data.name) {
                                isValid = false
                                this.setState({nameError: true})
                            }

                            if (_.isEmpty(modalProject.suppliers)) {
                                isValid = false
                                this.setState({suppliersError: true})
                            }

                            if (!isValid) {
                                return;
                            }


                            let projectId = modalProject.id;
                            if (!modalProject.id) {
                                // edit
                                projectId = uuidWithoutSpace();
                            }

                            // get the diff of the users lists
                            let crewMembersToDelete = [];
                            let newCrewMembers = [];

                            let managersToDelete = [];
                            let newManagers = [];

                            const {users, projectManagers} = modalProject;

                            this.getProjectUsersDiff(users, modalProjectSnapshot.users, newCrewMembers, crewMembersToDelete);
                            this.getProjectUsersDiff(projectManagers, modalProjectSnapshot.projectManagers, newManagers, managersToDelete);

                            firebase.set(`projects/${contractorId}/${projectId}`, this.transformToSchema(modalProject, projectId), (result) => {
                                //console.log(("complete save project", result)

                                this.setState({loading: true})

                                /*  const promises = [];
                                 crewMembersToDelete.forEach((userId)=>{
                                      promises.push(firebase.delete(`contractors/${contractorId}/users/${userId}/permissions/byProject/${projectId}`))
                                  })

                                  managersToDelete.forEach((userId)=>{
                                      promises.push(firebase.delete(`contractors/${contractorId}/users/${userId}/permissions/byProject/${projectId}`))
                                  })

                                  newCrewMembers.forEach((userId)=>{
                                      promises.push(firebase.set(`contractors/${contractorId}/users/${userId}/permissions/byProject/${projectId}`, "crewMember"))
                                  })

                                  newManagers.forEach((userId)=>{
                                      promises.push(firebase.set(`contractors/${contractorId}/users/${userId}/permissions/byProject/${projectId}`, "projectManager"))
                                  })

                                  Promise.all(new Set(promises)).then(()=>{
                                      this.initProjects();
                                  }).catch((e)=>{
                                      console.error("error saving permissions",e);
                                  }).finally(()=>{
                                      this.setState({isModalOpen: false, modalProject: this.defaultProject, modalProjectSnapshot: this.defaultProject, loading: false});
                                  })*/
                                this.initProjects();
                                this.setState({
                                    isModalOpen: false,
                                    modalProject: this.defaultProject,
                                    modalProjectSnapshot: this.defaultProject,
                                    loading: false
                                });

                            });

                        }}>שמירה</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }


}

Projects.propTypes = {
    // projects: PropTypes.object,
    contractorId: PropTypes.string.isRequired
};

Projects.defaultProps = {};

const ModalCloseButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
`;

const mapStateToProps = (state, props) => {
    return {
        contractors: state.firebase.data.contractors,
        contractorSuppliers: getVal(state.firebase.data, `contractors/${props.contractorId}/suppliers`),
        appUser: state.roles.roles
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default compose(
    firebaseConnect((props) => ([
        `contractors/${props.contractorId}/users`,
        `contractors/${props.contractorId}/suppliers`,
        // `projects/${props.contractorId}`,
        // "suppliers"
        // "userProfiles"
    ])),
    connect(
        mapStateToProps, mapDispatchToProps
    )
)(Projects);
