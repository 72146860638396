import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {compose} from "redux"
import { firebaseConnect } from 'react-redux-firebase'
import { map, get } from 'lodash'
import Dropzone from 'react-dropzone'
import {injectIntl, FormattedMessage} from 'react-intl';
import {bindActionCreators} from 'redux';
import {onArticleFieldChange} from "shared/src/modules/article";

import {upload} from "../../utils/firebaseUploader"

import {
    Modal, ModalHeader, ModalBody, ModalFooter, Button
} from 'reactstrap';

import MediaUploader from "../../components/Form/ImageUploader";


const mapDispatchToProps = dispatch => bindActionCreators({
    onArticleFieldChange
}, dispatch);
// Component Enhancer that adds props.firebase and creates a listener for
// files them passes them into props.uploadedFiles
const enhance = compose(
    connect(({ firebase: { data, auth }, article}) => ({
        uploadedFiles: get(data, `news.${article.id}.data.internalMedia`),
        articleId: article.id
    }), mapDispatchToProps),
    firebaseConnect((props) =>([
        `news/${props.articleId}/data/internalMedia`
    ])),
)



class Uploader extends React.Component{

    constructor() {
        super();


        this.deleteFile = this.deleteFile.bind(this);
    }


    deleteFile(file, key){
        const {firebase, dbPath, storagePath} = this.props;
        return firebase.deleteFile(file.fullPath, `${dbPath}/${key}`)
    }



    render() {

        const {uploadedFiles, firebase, dbPath, storagePath, onArticleFieldChange} = this.props;


        const onFilesDrop = (files) => {
            upload(firebase,storagePath,files.slice(0, 1)[0], dbPath, (item)=>{
                onArticleFieldChange("internalMedia", {[item.name]: item})
            });


            //limit to only 1 file
            // uploadFiles(storagePath, files, dbPath)
           /* return firebase.uploadFiles(storagePath, files.slice(0, 1), dbPath).then((response)=>{
                console.log("upload files:", response);
                onArticleFieldChange("internalMedia", {[response[0].key]: response[0].File})
            });*/
        }

        // Deletes file and removes metadata from database


        return (
            <MediaUploader
                onDeleteFile={this.deleteFile}
                onFilesDrop={onFilesDrop}
                uploadedFiles={uploadedFiles}
                showSidePreview={false}
                showAddIconOnPreview={false}
                relativeDimensions={true}
                dropzoneHeight={"287px"}
                dropzoneBg={"#fff"}
            />
        )
    }
}




Uploader.propTypes = {
    firebase: PropTypes.object.isRequired,
    uploadedFiles: PropTypes.object,
    filesPath: PropTypes.string
}

export default enhance(injectIntl(Uploader))