export default {
    fonts,
    colors
}

export const fonts = {
    OpenSansHebrew: "OpenSansHebrew",
    NachlieliCLM: "NachlieliCLM"
}

export const colors = {
    greyish: "#b4b4b4",
    warmGrey: "#9d9d9d",
    yellow: "#fbcd13",
    red: "#ff1010",
    charcoal: "#2e423d",
    black: "#0c120f",
    vibrantGreen90: "rgba(39,205, 0, 0.9)",
    dark_gray: "rgba(77, 91, 87, 0.9)",
};

export const OpenSansHebrew = "OpenSansHebrew, sans-serif";