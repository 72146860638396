import React, { Component } from 'react';
import PropTypes from 'prop-types'

import { Row, Col } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { colors, fonts } from "../../styles/styleguide";

import ConfirmButton from "../../components/ConfirmButton";
import RoundButton from "../../components/RoundButton";


class DeliveryOptionsModal extends Component {

    constructor() {
        super();
        this.state = {
            prevValue: null
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        const {
            value,
            isOpen,
        } = this.props;

        // save last value before open for the case of canceling the modal changes
        if(isOpen && isOpen !== prevProps.isOpen){
            this.setState({prevValue: value})
        }
    }

    render() {
        const {
            value,
            isOpen,
            options,
            onSubmit,
            closeHandler,
            onChange
        } = this.props;

        //console.log("DeliveryOptionsModal", options);
        return (
            <Modal
                isOpen={isOpen}
            >
                <ModalHeader>בחרו את סוג המשלוח</ModalHeader>
                <ModalBody>
                    {
                        options.map((item, index) => {
                            let isSelected = value && value.value === item.value;
                            let parentStyle = {
                                display: "flex",
                                paddingTop: 10.5,
                                paddingBottom: 10.5,
                                flexDirection: "row",
                                justifyContent: "space-between",
                                cursor: "pointer"
                            }
                            if (isSelected) {
                                parentStyle = Object.assign({}, parentStyle, {
                                    borderTop: "1px solid #2e423d",
                                    borderBottom: "1px solid #2e423d",
                                    backgroundColor: "#eee"
                                })
                            }
                            return (
                                <div key={index} onClick={() => {
                                    onChange(item)
                                }} style={parentStyle}>
                                    <div style={{
                                        fontSize: 15.5,
                                        fontFamily: fonts.OpenSansHebrew,
                                        color: colors.dark_gray
                                    }}>{item.label}</div>
                                </div>
                            )
                        })
                    }
                </ModalBody>
                <ModalFooter>
                    <ConfirmButton
                        label={"אישור"}
                        onClick={() => {
                            onSubmit();
                            closeHandler();
                        }}
                    />
                    <RoundButton
                        label={"ביטול"}
                        onClick={() => {
                            onChange(this.state.prevValue);
                            closeHandler();
                        }}
                        backgroundColor={"#fff"}
                    />
                </ModalFooter>
            </Modal>
        );
    }
}

DeliveryOptionsModal.propTypes = {
    value: PropTypes.object,
    isOpen: PropTypes.bool,
    options: PropTypes.array,
    onSubmit: PropTypes.func,
    onChange: PropTypes.func,
    closeHandler: PropTypes.func
};

DeliveryOptionsModal.defaultProps = {};


export default DeliveryOptionsModal;
