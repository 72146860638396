import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux';
import { firebaseConnect } from 'react-redux-firebase'


import {
    Container,
    Row,
    Col,
} from 'reactstrap';

import styled from 'styled-components';
import { Card, CartHeader } from "../../styles/stylesheet";
import {fonts, colors} from "../../styles/styleguide";

class SMS extends Component {

    componentDidMount() {
        const {firebase} = this.props;
        console.log("rendering recaptcha", this.recaptcha)
        firebase.auth().languageCode = 'iw';
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(this.recaptcha, {
            'size': 'invisible',
            'callback': (response) => {
                // reCAPTCHA solved, allow signInWithPhoneNumber.
                // ...
                console.log("response", response)
                this.verifyCallback(response)
            },
            'expired-callback': function () {
                // Response expired. Ask user to solve reCAPTCHA again.
                // ...
            }
        });
        window.recaptchaVerifier.render().then(function (widgetId) {
            window.recaptchaWidgetId = widgetId;
        }).catch(e => console.log(e));

        window.recaptchaVerifier.verify()
    }


    insertParam = (key, value) => {
        key = encodeURI(key);
        value = encodeURI(value);

        let kvp = window.location.search.substr(1).split('&');

        let i = kvp.length;
        let x;
        while (i--) {
            x = kvp[i].split('=');

            if (x[0] == key) {
                x[1] = value;
                kvp[i] = x.join('=');
                break;
            }
        }

        if (i < 0) {
            kvp[kvp.length] = [key, value].join('=');
        }

        //this will reload the page, it's likely better to store this until finished
        window.location.search = kvp.join('&');
    }


    verifyCallback = (recaptchaToken) => {
        // Here you will get the final recaptchaToken!!!
        console.log(recaptchaToken, "<= your recaptcha token")
        let appUrl = getParam("appurl")
     //   console.log("redirecting..." , `${appUrl}?code=${recaptchaToken}`)
        //window.location.href = `${appUrl}?code=${recaptchaToken}`;

       // let phoneNumber = getParam("phoneNumber");
     //   console.log("phoneNumber", phoneNumber)
        this.insertParam("token", recaptchaToken)
       /* if(phoneNumber){
            this.getCode("+"+phoneNumber, recaptchaToken)
        }*/

    }

    render() {
       // let token = getParam("token")
        let token = getParam("token");
        let phoneNumber = getParam("phoneNumber");
        console.log("token", token);

        return (

            <BG>
                <Container>
                    <Row className="justify-content-center">
                        <Col md="9">
                            <Row className="justify-content-center">
                                <div>
                                    <img src={"img/logo-punct.png"}  onClick={() => {
                                        window.location.href = '/about';
                                    }} style={{cursor: "pointer"}}/>
                                </div>
                            </Row>
                            <Row className="justify-content-center">
                                <div style={{
                                    fontFamily: fonts.NachlieliCLM,
                                    fontSize: 19.5,
                                    fontWeight: 300,
                                    color: colors.charcoal
                                }}>CONTROL YOUR SITE</div>
                            </Row>
                            <Card style={{position: "relative"}}>
                               <CartHeader>אנא המתינו</CartHeader>

                            </Card>
                        </Col>
                    </Row>
                </Container>
                <div ref={(ref)=>this.recaptcha=ref}></div>
            </BG>

        );
    }
}

const getParam = (name) => {
    let url_string = window.location.href; //window.location.href
    let url = new URL(url_string);
    let param = url.searchParams.get(name);
    return param;
}


const BG = styled.div`
    display: flex;
    min-height: 100vh;
    align-items: center ;
    flex-direction: row ;
    background-image:  url("img/login_bg.png");
    background-size: cover;
    padding: 50px;
`;

SMS.propTypes = {};

SMS.defaultProps = {};

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = dispatch => bindActionCreators({

}, dispatch);

export default firebaseConnect()(connect(mapStateToProps, mapDispatchToProps)(SMS));
