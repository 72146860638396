import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { bindActionCreators, compose } from 'redux';
import { firebaseConnect, isLoaded, getVal } from 'react-redux-firebase'
import _, { cloneDeep } from "lodash"

import { uuidWithoutSpace } from "shared/src/utils";
import { isUsersAdmin } from "shared/src/selectors/roles";
import { Row, Col } from "reactstrap"
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReactTable from 'react-table'
import styled from "styled-components";

import { toastr } from "react-redux-toastr";

import ROUTES from "../../constants/routes";
import Select, { Creatable } from 'react-select';

import { validateTaxId } from "../../utils/taxId";

import { Card, CartHeader, CardTitle, InputLabel, InputGroup } from "../../styles/stylesheet"
import ConfirmButton from "../../components/ConfirmButton"
import AddButton from "../../components/AddButton";
import IconInput, { IconWrapper } from "../../components/Form/IconInput";
import ImageUploader from "./ImageUploader";

import { sendInvite } from "../../utils/invite"
import { normalizePhoneNumber, isValid as isValidPhone } from "shared/src/utils/phone";


import {
	Button,
} from 'reactstrap';
import nav from "../../components/Sidebar/_nav";

import Branding from "./Tabs/Branding";
import Deliveries from "./Tabs/Deliveries";
import Agents from "./Tabs/Agents";


const tabs = {
	default: "default",
	contractor: "contractor",
	deliveries: "deliveries",
	branding: "branding",
	agents: "agents"
}

const modalContextTypes = {
	notifications: "notifications",
	users: "users",
	contractors: "contractors"
}

class SupplierForm extends Component {

	constructor( props ) {
		super(props);

		this.defaultSupplier = {
			id: null,
			location: {
				address: "",
			},
			name: "",
			phoneNumber: "",
			//   contractorManagers: {},

		}


		this.state = {
			supplier: this.defaultSupplier,
			isInviteModalOpen: false,
			inviteEmail: "",
			inviteName: "",
			inviteNameError: "",
			invitePOEmails: [],
			inviteEmailError: "",
			permissionError: "",
			invitePermissionUsers: false,
			invitePermissionOrders: false,
			invitePermissionCatalog: false,
			invitePermissionDriver: false,
			invitePermissionDelivery: false,
			users: {},
			inviteCompanyName: "",
			inviteErpId: "",
			inviteIsPOCustomer: false,
			inviteIsNotifyOnOrder: false,
			inviteCompanyNameError: "",
			inviteAddtionalInfo: "",
			invitePhone: "",
			invitePhoneError: "",
			phoneAndEmailError: "",
			notifyId: null,
			modalContext: modalContextTypes.users,
			tab: tabs.default,
			contractor: this.defaultContractor,
			shouldValidateTaxId: true,
			isValidTaxId: true,
			deliveryOptions: [],
			changes: {},
			deliveryOptionName: "",
			deliveryOptionPrice: "",
			deliveryOptionNameError: false,
			deliveryOptionPriceError: false,
			deleteInvidedUserModalOpen: false,
			inviteModalContext: "",
			editUserModalOpen: false,
			editUserModalContext: null,
			inviteAddress: "",
			inviteAddressError: "",
		}
	}

	initSupplier() {
		const { supplier, firebase, users } = this.props;
		const tempSup = supplier || this.defaultSupplier
		this.setState({
			supplier: tempSup
		})

		this.mergeUsers();
	}

	mergeUsers = () => {
		const { users } = this.props;
		const { changes } = this.state;
		//console.log("merging changes into", users);
		const result = _.merge(users, changes);
		//console.log("merge result", result);
		this.setState({ users: result });


	}

	getUsers = () => {
		const { firebase } = this.props;
		firebase.ref(`usersBySupplier/${ this.props.match.params.supplierId }`).on("value", ( snapshot ) => {
			//console.log("get users", snapshot.val())
			this.setState({ users: snapshot.val() })
		})
	}

	componentDidMount() {
		this.initSupplier()
		// this.getUsers()
	}

	componentDidUpdate( prevProps, prevState, snapshot ) {
		console.log("componentDidUpdate")
		console.log("this.state", this.state)

		if (!_.isEqual(prevProps.supplier, this.props.supplier) || !_.isEqual(prevProps.users, this.props.users)) {
			this.initSupplier()
		}
		if (!_.isEqual(prevProps.deliveryOptionsBySupplier, this.props.deliveryOptionsBySupplier)) {
			this.setState({ deliveryOptions: this.props.deliveryOptionsBySupplier })
		}
	}

	transformToSchema = ( supplier, id ) => {
		if (!supplier.id) {
			supplier.id = id;
		}
		return supplier;
	}

	transformSelectedUserToObject = ( user, isAdmin = true ) => {

		const obj = {};
		for (let key in user) {
			const row = user[ key ];
			if (isAdmin) {
				obj[ row.value ] = { admin: 200 }
			} else {
				obj[ row.value ] = { id: row.value }
			}


		}
		return obj;
	}

	onCrewMembersChange = ( value ) => {
		//console.log("onCrewMembersChange", value);
		const { supplier } = this.state;
		const clone = _.cloneDeep(supplier);
		const pm = this.transformSelectedUserToObject(value, false);
		clone.users = pm;
		this.setState({ supplier: clone });

	}

	closeInviteModal = () => {
		this.setState({
			inviteEmail: "",
			inviteName: "",
			inviteNameError: "",
			invitePOEmails: [],
			inviteEmailError: "",
			isInviteModalOpen: false,
			invitePermissionUsers: false,
			invitePermissionOrders: false,
			invitePermissionCatalog: false,
			invitePermissionDriver: false,
			invitePermissionDelivery: false,
			permissionError: "",
			inviteAddtionalInfo: "",
			inviteCompanyName: "",
			inviteErpId: "",
			inviteIsPOCustomer: false,
			inviteIsNotifyOnOrder: false,
			inviteCompanyNameError: "",
			inviteAddress: "",
			inviteAddressError: "",
			invitePhone: "",
			invitePhoneError: "",
			phoneAndEmailError: "",
			inviteTaxId: "",
			inviteTaxIdError: "",
			notifyId: null,
			modalContext: "users",
			deleteNotificationUser: null,
			isDeleteModalOpen: false,
		})
	}

	closeEditCustomerModal = () => {
		this.setState({
			editUserModalOpen: false,
			editUserModalContext: null,
			editCustomerPOError: ""
		})
	}

	inviteUser = () => {
		const { firebase } = this.props;
		const {
			      invitePermissionUsers,
			      invitePermissionOrders,
			      invitePermissionCatalog,
			      invitePermissionDriver,
			      invitePermissionDelivery,
			      modalContext,
			      inviteIsPOCustomer,
			      inviteAddtionalInfo
		      } = this.state;
		const {
			      inviteEmail,
			      inviteName,
			      inviteCompanyName,
			      invitePhone,
			      inviteIsNotifyOnOrder,
			      notifyId,
			      supplier,
			      inviteTaxId,
			      inviteErpId,
			      invitePOEmails,
			      inviteAddress
		      } = this.state
		if (this.validateUser(modalContext)) {
			let phone = null;
			if (invitePhone) {
				phone = normalizePhoneNumber(invitePhone)
			}
			// add to firebase
			const invitationId = uuidWithoutSpace();
			switch (modalContext) {
				case modalContextTypes.notifications:

					const uid = notifyId || invitationId;
					let id = supplier.id || this.props.match.params.supplierId;

					console.log("inviteIsNotifyOnOrder", inviteIsNotifyOnOrder)

					firebase.set(`suppliersPrivateData/${ id }/notify/${ uid }`, {
						name: inviteName,
						sms: invitePhone || null,
						email: inviteEmail || null,
						isNotifyOnOrder: inviteIsNotifyOnOrder
					}, ( result ) => {
						//console.log("complete save notify", result)
						toastr.success('השינויים נשמרו בהצלחה');
						this.closeInviteModal()
					})
					break;
				case modalContextTypes.users:
					const invitation = {
						email: this.state.inviteEmail,
						name: this.state.inviteName,
						phone: phone,
						permissions: {
							users: invitePermissionUsers,
							orders: invitePermissionOrders,
							catalog: invitePermissionCatalog,
							driver: invitePermissionDriver,
							delivery: invitePermissionDelivery
						}
					};
					if (inviteAddtionalInfo) {
						invitation[ "additionalInfo" ] = inviteAddtionalInfo;
					}
					firebase.set(
						`pendingInvitationsBySupplier/${ this.props.match.params.supplierId }/${ invitationId }`,
						invitation,
						( result ) => {
							console.log("complete save invitation", result)
							// show success notification
							toastr.success('ההזמנה נשלחה בהצלחה');
							// close modal
							this.closeInviteModal()
						}
					);
					break;
				case modalContextTypes.contractors: {
					const invitation = {
						name: inviteCompanyName,
						phone: phone || null,
						taxId: inviteTaxId,
						address: inviteAddress,
						contactPersonName: inviteName || null,
						// email: inviteEmail || null,
						erpId: inviteErpId || null,
						// po: inviteIsPOCustomer || false,
						// poEmails: this.transformPOEmails(invitePOEmails)

					};
					firebase.set(
						`pendingContractorInvitationsBySupplier/${ this.props.match.params.supplierId }/${ invitationId }`,
						invitation,
						( result ) => {
							console.log("complete save invitation", result)
							// show success notification
							toastr.success('ההזמנה נשלחה בהצלחה');
							// close modal
							this.closeInviteModal()
						}
					);
					break;
				}
			}
		}
	}

	resendContractorInvite = ( inviteId ) => {
		sendInvite(this.props.match.params.supplierId, inviteId, this.props.token).then(( res ) => {
			return res.json();
		}).then(( res ) => {
			console.log("resendContractorInvite  res", res);
			toastr.success('ההזמנה נשלחה בהצלחה');
		}).catch(( e ) => {
			console.log("resendContractorInvite  error", e)
			toastr.error('לא הצלחנו לשלוח את ההזמנה, אנא נסו שוב');
		})
	}

	transformPOEmails = ( emailsArr ) => {
		if (!emailsArr || (
			emailsArr && emailsArr.length === 0
		)) {
			return null
		}
		const emails = emailsArr.map(e => e.value)

		return emails;
	}

	transformPOEmailsToArray = ( emailsArr ) => {
		const emails = []
		if (!emailsArr) {
			return emails;
		}
		const map = emailsArr.map(( e ) => {
			console.log("in map", e)
			return { value: e, label: e }
		});
		console.log("emails map", map)
		return map;
	}

	saveCustomer = () => {
		const { firebase, supplierContractors } = this.props;
		const { editUserModalContext, supplier } = this.state
		let id = supplier.id || this.props.match.params.supplierId;

		if (!editUserModalContext.id) {
			this.closeEditCustomerModal();
			return;
		}

		const customer = supplierContractors[ editUserModalContext.id ];

		const emails = this.transformPOEmails(editUserModalContext.emails)
		/* if(_.isEmpty(emails) && editUserModalContext.po){
		 this.setState({editCustomerPOError: "יש להזין לפחות כתובת מייל אחת למשלוח הצעות מחיר"});
		 return;
		 }*/
		//  console.log("po emails", emails);
		const edited = Object.assign({}, customer, {
			erpId: editUserModalContext.erpId || null,
			po: editUserModalContext.po || false,
			poEmails: emails
		})
		//  console.log("edited", edited);
		firebase.set(`suppliersPrivateData/${ id }/contractors/${ editUserModalContext.id }`, edited
			, ( result ) => {
				console.log("complete saveCustomer", result)
				toastr.success('השינויים נשמרו בהצלחה');
				this.closeEditCustomerModal()
			})

		this.closeEditCustomerModal();
	}

	validateEmail = ( email ) => {
		const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase())

	}

	validatePOEmails = () => {
		const { invitePOEmails } = this.state;
		if (!invitePOEmails || (
			invitePOEmails && invitePOEmails.length === 0
		)) {
			this.setState({ invitePOEmailsError: "חובה להזין לפחות כתובת אימייל אחת" })
			return false
		}
		return true;
	}

	validateUser = ( context ) => {
		const {
			      invitePermissionUsers,
			      invitePermissionOrders,
			      invitePermissionCatalog,
			      invitePermissionDriver,
			      invitePermissionDelivery,
			      inviteCompanyName,
			      invitePhone,
			      inviteIsPOCustomer,
			      inviteEmail,
			      invitePOEmails,
			      inviteTaxId,
			      shouldValidateTaxId,
			      inviteAddress,
			      inviteName,
		      } = this.state;

		let isValid = true;
		if (!inviteName) {
			this.setState({ inviteNameError: "יש להזין שם" });
			isValid = false;
		}
		if (!inviteEmail && !invitePhone && !inviteIsPOCustomer) {
			// this.setState({ phoneAndEmailError: "יש להזין כתובת אימייל או מספר טלפון" });
			this.setState({ phoneAndEmailError: "יש להזין שם ומספר טלפון נייד" });
			isValid = false;
		} else {
			if (inviteEmail && !inviteIsPOCustomer) {
				if (!this.validateEmail(inviteEmail)) {
					this.setState({ inviteEmailError: "כתובת אימייל לא חוקית" });
					isValid = false;
				}
			}
			if (invitePhone && !isValidPhone(invitePhone) && !inviteIsPOCustomer) {
				this.setState({ invitePhoneError: "אנא ודאו שמספר הטלפון תקין" });
				isValid = false;
			}
		}

		if (context === modalContextTypes.contractors) {
			if (shouldValidateTaxId) {
				this.validateTaxId(inviteTaxId)
				return isValid;
			}
			if (!inviteAddress) {
				this.setState({ inviteAddressError: "יש להזין כתובת" })
				isValid = false;
			}
			if (!inviteName) {
				this.setState({ inviteNameError: "נא להזין שם איש קשר" });
				isValid = false;
			}
			/* if(inviteIsPOCustomer && !this.validatePOEmails()){
			 isValid = false;
			 }*/
		}

		if (context === modalContextTypes.users) {
			if (!invitePermissionUsers
				&& !invitePermissionOrders
				&& !invitePermissionCatalog
				&& !invitePermissionDriver
				&& !invitePermissionDelivery) {
				this.setState({ permissionError: "יש לבחור לפחות הרשאה אחת" });
				isValid = false;
			}
			if (!inviteEmail && !invitePhone) {
				this.setState({ phoneAndEmailError: "יש להזין כתובת אימייל או מספר טלפון" });
				// this.setState({ phoneAndEmailError: "יש להזין מספר טלפון נייד" });
				isValid = false;
			} else if (inviteEmail && invitePhone) {
				this.setState({ phoneAndEmailError: "יש להזין כתובת אימייל או מספר טלפון (רק אחד מהשניים)" });
				isValid = false;
			} else {
				if (inviteEmail && !this.validateEmail(inviteEmail)) {
					this.setState({ inviteEmailError: "כתובת אימייל לא חוקית" });
					isValid = false;
				}
				if (invitePhone && !isValidPhone(invitePhone)) {
					this.setState({ invitePhoneError: "אנא ודאו שמספר הטלפון תקין" });
					isValid = false;
				}
			}
		}
		return isValid;
	}

	renderUsersList = ( list, returnObj = false ) => {
		const toRender = [];
		console.log("render user list", list)
		for (let key in list) {
			let isObject = typeof list[ key ] === 'object';
			let label = "";
			if (isObject) {
				label = `${ list[ key ].name || "" } ${ list[ key ].email || "" }`
			} else {
				label = list[ key ]
			}

			if (returnObj) {
				toRender.push({
					key,
					label
				})
			} else {
				toRender.push(<div key={ key }>{ label }</div>);
			}
		}
		return toRender;
	}

	renderCustomers = () => {
		const { contractors, supplierContractors } = this.props;

		console.log("customers", supplierContractors, contractors);
		const list = [];
		for (let key in supplierContractors) {
			const item = { value: key };
			if (contractors[ key ]) {
				item.label = contractors[ key ].name
			} else {
				item.label = "לא ידוע"
			}

			list.push({
				key,
				label: item.label
			});

		}
		return list;
	}

	setUserPermission = ( userKey, permissionKey, value ) => {
		//const supplierId = this.props.match.params.supplierId;
		//const {firebase} = this.props;

		/* firebase.set(`usersBySupplier/${supplierId}/${userKey}/permissions/${permissionKey}`, value, (result) => {
		 console.log("result", result)
		 });*/

		const users = _.cloneDeep(this.state.users);
		const changes = _.cloneDeep(this.state.changes);
		users[ userKey ].permissions[ permissionKey ] = value;
		if (!changes[ userKey ]) {
			changes[ userKey ] = { permissions: {} }
		}
		changes[ userKey ].permissions[ permissionKey ] = value;
		this.setState({ users, changes })
	}

	setUserData = ( userKey, dataKey, value ) => {
		//const supplierId = this.props.match.params.supplierId;
		//const {firebase} = this.props;

		/* firebase.set(`usersBySupplier/${supplierId}/${userKey}/permissions/${permissionKey}`, value, (result) => {
		 console.log("result", result)
		 });*/

		const users = _.cloneDeep(this.state.users);
		const changes = _.cloneDeep(this.state.changes);
		users[ userKey ][ dataKey ] = value;
		if (!changes[ userKey ]) {
			changes[ userKey ] = { [ dataKey ]: null }
		}
		changes[ userKey ][ dataKey ] = value;
		this.setState({ users, changes })
	}

	renderUsersWithPermissions = ( list ) => {
		const toRender = [];
		console.log("render user list", list)
		for (let key in list) {
			//let isObject = typeof list[key] === 'object';
			const user = list[ key ];
			let label = user.email;
			let obj = {
				original: user,
				key,
				name: user.name,
				phone: user.phone,
				email: user.email,
				permissions: user.permissions
			}

			toRender.push(obj);

		}
		return toRender;
	}

	renderUserPermissions = ( user ) => {
		return (
			<Row>
				<Col md={ 2 }>
					<InputGroup>
						<input type={ "checkbox" }
						       onChange={ ( e ) => {
							       this.setUserPermission(user.key, "users", !user.permissions.users)
						       } }
						       checked={ user.permissions.users }
						/> ניהול משתמשים
					</InputGroup>
				</Col>
				<Col md={ 2 }>
					<InputGroup>
						<input type={ "checkbox" }
						       onChange={ ( e ) => {
							       //   this.setState({invitePermissionOrders: !invitePermissionOrders})
							       this.setUserPermission(user.key, "orders", !user.permissions.orders)
						       } }
						       checked={ user.permissions.orders }
						/> ניהול הזמנות
					</InputGroup>
				</Col>
				<Col md={ 2 }>
					<InputGroup>
						<input type={ "checkbox" }
						       onChange={ ( e ) => {
							       //  this.setState({invitePermissionCatalog: !invitePermissionCatalog})
							       this.setUserPermission(user.key, "catalog", !user.permissions.catalog)
						       } }
						       checked={ user.permissions.catalog }
						/> ניהול קטלוג
					</InputGroup>
				</Col>
				<Col md={ 2 }>
					<InputGroup>
						<input type={ "checkbox" }
						       onChange={ ( e ) => {
							       this.setUserPermission(user.key, "delivery", !user.permissions.delivery)
						       } }
						       checked={ user.permissions.delivery }
						/> ניהול משלוחים
					</InputGroup>
				</Col>
				<Col md={ 2 }>
					<InputGroup>
						<input type={ "checkbox" }
						       onChange={ ( e ) => {
							       this.setUserPermission(user.key, "driver", !user.permissions.driver)
						       } }
						       checked={ user.permissions.driver }
						/> נהג
					</InputGroup>
				</Col>
				<Col md={ 2 }>
					<InputGroup>
						<input type={ "text" }
						       onChange={ ( e ) => {
							       this.setUserData(user.key, "additionalInfo", e.target.value)
						       } }
						       value={ user.original.additionalInfo }
						       placeholder={ "מידע נוסף" }
						/>
					</InputGroup>
				</Col>
			</Row>
		)
	}

	getRegisteredUsersColumns = () => {
		return [
			{
				id: 'email',
				Header: 'משתמשים רשומים',
				accessor: u => `${ u.name ? u.name + " - " : "" }${ u.email || u.phone }`, // String-based value accessors!,
			}, {
				id: 'permissions',
				Header: 'הרשאות',
				accessor: u => u.permissions, // String-based value accessors!,
				Cell: props => this.renderUserPermissions(props.original)//<EllipsisText text={props.value || ''} length={18}/>, // Custom cell components!
			}
		]
	}

	renderNotificationUsers = () => {
		const { notificationUsers } = this.props;
		console.log("notificationUsers", notificationUsers)
		if (!notificationUsers) {
			return null;
		}

		const users = Object.values(notificationUsers);
		const ids = Object.keys(notificationUsers);
		console.log("users", users)
		return users.map(( item, index ) =>
			(
				<Row key={ index } style={ { marginTop: 15 } }>

					<Col md={ 12 }>
						<div style={ { display: "flex", flexDirection: "row", alignItems: "space-around" } }>
							<div>
								<span>שם:</span>
								<span style={ { marginRight: 5 } }>{ item.name || "" }</span>
							</div>
							<div style={ { marginRight: 15 } }>
								<span>טלפון:</span>
								<span style={ { marginRight: 5 } }>{ item.sms || "" }</span>
							</div>
							<div style={ { marginRight: 15 } }>
								<span>אימייל:</span>
								<span style={ { marginRight: 5 } }>{ item.email || "" }</span>
							</div>
							<div style={ { marginRight: 15, cursor: "pointer" } } onClick={ () => {
								this.setState({
									modalContext: "notifications",
									inviteName: item.name,
									invitePhone: item.sms,
									inviteEmail: item.email,
									inviteIsNotifyOnOrder: item.isNotifyOnOrder,
									notifyId: ids[ index ],
									isInviteModalOpen: true
								})
							} }>
								<i className={ "fa fa-edit" }/>
							</div>
							<div style={ { marginRight: 15, cursor: "pointer" } }
							     onClick={ () => {
								     this.setState({
									     deleteNotificationUser: {
										     name: item.name,
										     id: ids[ index ]
									     },
									     isDeleteModalOpen: true
								     })
							     } }
							>
								<i className={ "fa fa-trash" }/>
							</div>
						</div>
					</Col>
				</Row>
			)
		)
	}

	deleteNotificationUser = () => {
		const { deleteNotificationUser, supplier } = this.state;
		const { firebase } = this.props;
		let id = supplier.id || this.props.match.params.supplierId;
		firebase.remove(`suppliersPrivateData/${ id }/notify/${ deleteNotificationUser.id }`, ( result ) => {
			console.log("complete delete notify", result)
			toastr.success('השינויים נשמרו בהצלחה');
			this.closeDeleteModal()
		})

	}

	save( successCallback ) {
		const { supplier, users, modalContext } = this.state;
		const { firebase } = this.props;
		let id = supplier.id || this.props.match.params.supplierId;

		firebase.set(`suppliers/${ id }`, this.transformToSchema(supplier, id), ( result ) => {
			if (successCallback) {
				successCallback(result)
			}
		});
		this.setState({ changes: {} });
		firebase.set(`usersBySupplier/${ id }`, users)
	}

	closeDeleteModal = () => {
		this.setState({
			deleteNotificationUser: null,
			isDeleteModalOpen: false
		})
	}

	switchTab = ( tab ) => {
		this.setState({ tab })
	}

	getModalTitle = ( modalContext ) => {
		return modalContext === modalContextTypes.notifications ? "נוטיפיקציות" : modalContext
		=== modalContextTypes.users ? "הזמנת משתמש" : "הזמנת לקוח";
	}

	validateTaxId = ( taxId, successCB ) => {
		this.setState({
			validatingTaxId: true
		})
		validateTaxId(taxId, ( companyName ) => {
			this.setState({
				isValidTaxId: true,
				shouldValidateTaxId: false,
				validatingTaxId: false
			});
			if (!this.state.inviteCompanyName) {
				this.setState({ inviteCompanyName: companyName });
			}
			if (successCB) {
				successCB();
			}
		}, () => {
			this.setState({
				isValidTaxId: false,
				shouldValidateTaxId: false,
				validatingTaxId: false
			})
		})

	}

	getModalBody = ( modalContext ) => {
		const {
			      supplier,
			      inviteEmail,
			      inviteName,
			      inviteNameError,
			      isInviteModalOpen,
			      inviteEmailError,
			      invitePermissionUsers,
			      inviteAddtionalInfo,
			      invitePOEmails,
			      invitePermissionOrders,
			      invitePermissionCatalog,
			      invitePermissionDriver,
			      invitePermissionDelivery,
			      permissionError,
			      inviteCompanyName,
			      inviteAddress,
			      inviteAddressError,
			      inviteErpId,
			      inviteIsPOCustomer,
			      inviteCompanyNameError,
			      invitePhone,
			      invitePhoneError,
			      phoneAndEmailError,
			      inviteIsNotifyOnOrder,
			      isDeleteModalOpen,
			      deleteNotificationUser,
			      tab,
			      inviteTaxId,
			      inviteTaxIdError,
			      isValidTaxId
		      } = this.state;
		const { users } = this.props;
		switch (modalContext) {
			case modalContextTypes.notifications:
			case modalContextTypes.contractors:

				return (
					<Fragment>
						{ modalContext === modalContextTypes.contractors ? (
							<Fragment>
								<InputGroup>
									<InputLabel>
										<span style={ { fontWeight: "bold" } }>
													{ "פרטי החברה" }
										</span>
									</InputLabel>
								</InputGroup>
								{
									// <InputGroup>
									//     <InputLabel>{"לקוח הצעת מחיר בקליק"}</InputLabel>
									//     <input
									//         style={{width: 34, height: 34}}
									//         type={"checkbox"}
									//         onChange={(e) => {
									//             this.setState({
									//                 inviteIsPOCustomer: e.target.checked
									//             })
									//         }}
									//         checked={inviteIsPOCustomer}
									//     />
									// </InputGroup>
								}
								<InputGroup>
									<InputLabel>
										שם חברה
									</InputLabel>
									<input style={ { width: "100%", height: 34 } } type={ "text" }
									       onChange={ ( e ) => {
										       this.setState({ inviteCompanyName: e.target.value })
									       } }
									       value={ inviteCompanyName }
									/>
									<div style={ { color: "red" } }>{ inviteCompanyNameError }</div>
								</InputGroup>
								<InputGroup>
									<InputLabel>
										ח.פ
									</InputLabel>
									<input style={ { width: "100%", height: 34 } }
									       type={ "text" }
									       onChange={ ( e ) => {
										       this.setState({
											       inviteTaxId: e.target.value,
											       shouldValidateTaxId: true
										       })
									       }
									       }
									       value={ inviteTaxId }
									       onBlur={ ( e ) => {
										       console.log("blur", e.target.value)
										       this.validateTaxId(e.target.value);
									       } }
									/>
									{ !isValidTaxId && (
										<div style={ { color: "red" } }>{ "ח.פ לא תקין" }</div>
									) }
								</InputGroup>
								<InputGroup>
									<InputLabel>
										כתובת
									</InputLabel>
									<input style={ { width: "100%", height: 34 } }
									       type={ "text" }
									       onChange={ ( e ) => {
										       this.setState({ inviteAddress: e.target.value })
									       } }
									       value={ inviteAddress }
									/>
									<div style={ { color: "red" } }>{ inviteAddressError }</div>
								</InputGroup>
								<InputGroup>
									<InputLabel>
										מפתח חשבון לקוח
									</InputLabel>
									<input
										style={ { width: "100%", height: 34 } }
										type={ "text" }
										onChange={ ( e ) => {
											this.setState({
												inviteErpId: e.target.value
											})
										}
										}
										value={ inviteErpId }
									/>
								</InputGroup>

								{
									// <InputGroup>
									//     <InputLabel>
									//         כתובות מייל למשלוח הצעה
									//     </InputLabel>
									//     <Creatable
									//         isMulti={true}
									//         onChange={(newValue, actionMeta) => {
									//             if (actionMeta.action === "create-option") {
									//                 const last = _.last(newValue)
									//                 if (last.__isNew__) {
									//                     //validate last email
									//                     if (!this.validateEmail(last.value)) {
									//                         toastr.warning("כתובת אימייל לא חוקית");
									//                         return
									//                     }
									//
									//                 }
									//             }
									//
									//             this.setState({
									//                 invitePOEmails: newValue
									//             })
									//         }}
									//         options={[]}
									//         value={invitePOEmails}
									//     />
									// </InputGroup>
								}


							</Fragment>
						) : null }

						{ !inviteIsPOCustomer && (
							<Fragment>
								{
									modalContext !== modalContextTypes.notifications && (
										<InputGroup>
											<InputLabel>
												<div style={ { margin: "56px 0 0 0" } }>
													<span style={ { fontWeight: "bold" } }>
														{ "פרטי איש קשר לשליחת זימון" }
													</span>
												</div>
											</InputLabel>
										</InputGroup>
									)
								}
								{
									// modalContext === modalContextTypes.notifications ? (
									// 	<InputGroup>
									// 		<InputLabel>
									// 			שם
									// 		</InputLabel>
									// 		<input style={ { width: "100%", height: 34 } } type={ "text" }
									// 		       onChange={ ( e ) => {
									// 			       this.setState({ inviteName: e.target.value })
									// 		       } }
									// 		       value={ inviteName }
									// 		/>
									// 		<div style={ { color: "red" } }>{ inviteNameError }</div>
									// 	</InputGroup>
									// ) :
									// // null
									// (
									// 	<InputGroup>
									// 		<InputLabel>
									// 			<div style={ { margin: "56px 0 0 0" } }>
									// 			<span style={ { fontWeight: "bold" } }>
									// 				{ "פרטי איש קשר לשליחת זימון" }
									// 			</span>
									// 			</div>
									// 		</InputLabel>
									// 	</InputGroup>
									// )
								}

								<InputGroup>
									<InputLabel>
										שם
									</InputLabel>
									<input style={ { width: "100%", height: 34 } } type={ "text" }
									       onChange={ ( e ) => {
										       this.setState({ inviteName: e.target.value })
									       } }
									       value={ inviteName }
									/>
									<div style={ { color: "red" } }>{ inviteNameError }</div>
								</InputGroup>

								<InputGroup>
									<InputLabel>
										{ "טלפון נייד" }
									</InputLabel>
									<input dir={ "ltr" } style={ { width: "100%", height: 34 } } type={ "phone" }
									       onChange={ ( e ) => {
										       this.setState({ invitePhone: e.target.value })
									       } }
									       value={ invitePhone }
									/>
									<div style={ { color: "red" } }>{ invitePhoneError }</div>
								</InputGroup>
								<div style={ { color: "red" } }>{ phoneAndEmailError }</div>

								{ modalContext === modalContextTypes.notifications && (
									<div>
										<InputGroup>
											<InputLabel>
												כתובת אימייל
											</InputLabel>
											<input dir={ "ltr" } style={ { width: "100%", height: 34 } }
											       type={ "email" }
											       onChange={ ( e ) => {
												       this.setState({ inviteEmail: e.target.value })
											       } }
											       value={ inviteEmail }
											/>
											<div style={ { color: "red" } }>{ inviteEmailError }</div>
										</InputGroup>
										<InputGroup>
											<InputLabel>{ "קבלת עדכונים על הזמנות חדשות" }</InputLabel>
											<input
												style={ { width: 34, height: 34 } }
												type={ "checkbox" }
												onChange={ ( e ) => {
													console.log("onChange")
													console.log("event", e)
													console.log("e.target.checked", e.target.checked)

													this.setState({
														inviteIsNotifyOnOrder: e.target.checked
													})
												} }
												checked={ inviteIsNotifyOnOrder }
											/>
										</InputGroup>
									</div>
								) }
							</Fragment>
						) }

					</Fragment>
				)

			case modalContextTypes.users:
				return (
					<Fragment>
						<Row>
							<Col md={ 12 }>
								<InputGroup>
									<InputLabel>
										שם
									</InputLabel>
									<input style={ { width: "100%", height: 34 } } type={ "text" }
									       onChange={ ( e ) => {
										       this.setState({ inviteName: e.target.value })
									       } }
									       value={ inviteName }
									/>
									<div style={ { color: "red" } }>{ inviteNameError }</div>
								</InputGroup>
								<InputGroup>
									<InputLabel>
										כתובת אימייל
									</InputLabel>
									<input dir={ "ltr" } style={ { width: "100%", height: 34 } } type={ "email" }
									       onChange={ ( e ) => {
										       this.setState({ inviteEmail: e.target.value })
									       } }
									       value={ inviteEmail }
									/>
									<div style={ { color: "red" } }>{ inviteEmailError }</div>
								</InputGroup>
								<InputGroup>
									<InputLabel>
										או
									</InputLabel>
								</InputGroup>
								<InputGroup>
									<InputLabel>
										טלפון
									</InputLabel>
									<input dir={ "ltr" } style={ { width: "100%", height: 34 } } type={ "phone" }
									       onChange={ ( e ) => {
										       this.setState({ invitePhone: e.target.value })
									       } }
									       value={ invitePhone }
									/>
									<div style={ { color: "red" } }>{ invitePhoneError }</div>
								</InputGroup>
								<div style={ { color: "red" } }>{ phoneAndEmailError }</div>
							</Col>
						</Row>
						<Row>
							<Col md={ 12 }>הרשאות</Col>
						</Row>
						<Row>
							<Col md={ 3 }>
								<InputGroup>
									<input type={ "checkbox" }
									       onChange={ ( e ) => {
										       this.setState({ invitePermissionUsers: !invitePermissionUsers })
									       } }
									       checked={ invitePermissionUsers }
									/> ניהול משתמשים
								</InputGroup>
							</Col>
							<Col md={ 3 }>
								<InputGroup>
									<input type={ "checkbox" }
									       onChange={ ( e ) => {
										       this.setState({ invitePermissionOrders: !invitePermissionOrders })
									       } }
									       checked={ invitePermissionOrders }
									/> ניהול הזמנות
								</InputGroup>
							</Col>
							<Col md={ 3 }>
								<InputGroup>
									<input type={ "checkbox" }
									       onChange={ ( e ) => {
										       this.setState({ invitePermissionCatalog: !invitePermissionCatalog })
									       } }
									       checked={ invitePermissionCatalog }
									/> ניהול קטלוג
								</InputGroup>
							</Col>
							<Col md={ 3 }>
								<InputGroup>
									<input type={ "checkbox" }
									       onChange={ ( e ) => {
										       this.setState({ invitePermissionDelivery: !invitePermissionDelivery })
									       } }
									       checked={ invitePermissionDelivery }
									/> ניהול משלוחים
								</InputGroup>
							</Col>
							<Col md={ 3 }>
								<InputGroup>
									<input type={ "checkbox" }
									       onChange={ ( e ) => {
										       this.setState({ invitePermissionDriver: !invitePermissionDriver })
									       } }
									       checked={ invitePermissionDriver }
									/> נהג
								</InputGroup>
							</Col>
						</Row>
						<div style={ { color: "red" } }>{ permissionError }</div>
						<Row>
							<Col md={ 12 }>
								<InputGroup>
									<InputLabel>
										מידע נוסף
									</InputLabel>
									<input style={ { width: "100%", height: 34 } }
									       type={ "text" }
									       onChange={ ( e ) => {
										       this.setState({ inviteAddtionalInfo: e.target.value })
									       } }
									       value={ inviteAddtionalInfo }
									/>
								</InputGroup>
							</Col>
						</Row>

					</Fragment>
				);

		}
		return null;
	}

	renderTab = () => {
		const {
			      supplier, tab
		      } = this.state;
		const { pendingInvitations, pendingContractors } = this.props;

		const users = this.state.users || {};


		const usersWithPermissions1 = this.renderUsersWithPermissions(users);
		const pendingInvititationsUsers = this.renderUsersList(pendingInvitations, true);
		const customers = this.renderCustomers();
		const pendingCustomers = this.renderUsersList(pendingContractors, true);

		switch (tab) {
			case tabs.default:
				return (
					<Row>
						<Col md={ 12 }>
							<Card>
								<CartHeader>פרטי הספק</CartHeader>
								<Row>
									<Col md={ 2 }>
										<InputGroup>
											<InputLabel>שם</InputLabel>
											<IconInput
												type={ "text" }
												onChange={ ( text ) => {
													const clone = _.cloneDeep(supplier);
													clone.name = text;
													this.setState({ supplier: clone })
												} }
												value={ supplier.name }
												icon={ <img src={ "img/icons/person.svg" }/> }
											/>
										</InputGroup>
									</Col>
									<Col md={ 2 }>
										<InputGroup>
											<InputLabel>כתובת</InputLabel>
											<IconInput
												type={ "text" }
												onChange={ ( text ) => {
													const clone = _.cloneDeep(supplier);
													clone.location = {
														...clone.location,
														address: text
													}
													this.setState({ supplier: clone })
												} }
												value={ supplier && supplier.location ? supplier.location.address : "" }
												icon={ <img src={ "img/icons/location.svg" }/> }
											/>
										</InputGroup>
									</Col>
									<Col md={ 2 }>
										<InputGroup>
											<InputLabel>טלפון</InputLabel>
											<IconInput
												type={ "text" }
												onChange={ ( text ) => {
													const clone = cloneDeep(supplier);
													clone.phoneNumber = text;
													this.setState({ supplier: clone })
												} }
												value={ supplier.phoneNumber }
												icon={ <img src={ "img/icons/phone.svg" }/> }
											/>
										</InputGroup>
									</Col>
									<Col md={ 2 }>
										<div style={ { marginTop: 36 } }>
											<ConfirmButton
												onClick={ () => {
													this.save(( result ) => {
														console.log("complete save contractor", result)
														toastr.success('השינויים נשמרו בהצלחה')
														if (_.findIndex(nav.items, { name: 'ניהול חנות' }) === -1) {
															this.props.history.push(ROUTES.SUPPLIERS)
														}

													});
												} }
												label={ "שמירה" }
											/>
										</div>
									</Col>
								</Row>
							</Card>
						</Col>
						<Col md={ 12 }>
							<div style={ { position: "relative", paddingBottom: 50, marginTop: 36 } }>
								<CardTitle>ניהול משתמשים</CardTitle>
								<Row>
									<Col md={ 8 }>
										<div>
											<ReactTable
												data={ usersWithPermissions1 || [] }
												columns={ this.getRegisteredUsersColumns() }
												showPagination={ false }
												pageSize={ usersWithPermissions1 ? usersWithPermissions1.length : 0 }
												filterable={ false }
											/>
										</div>
									</Col>
									<Col md={ 4 }>
										<div>
											<ReactTable
												style={ { minHeight: 168 } }
												data={ pendingInvititationsUsers || [] }
												columns={ [
													{
														id: 'label',
														Header: 'משתמשים מוזמנים',
														accessor: 'label', // String-based value accessors!,
													},
													{
														id: "delete",
														Header: "",
														width: 50,
														Cell: props => {
															console.log("invited user", props)
															return (
																<div
																	onClick={ () => {
																		this.setState({
																			deleteInvidedUserModalOpen: true,
																			inviteModalContext: props.original
																		})
																	} }
																>
																	<i className={ "fa fa-ban" }/>
																</div>
															)
														}
													}
												] }
												showPagination={ false }
												pageSize={ pendingInvititationsUsers
													? pendingInvititationsUsers.length
													: 0 }
												filterable={ false }
											/>

										</div>
									</Col>
									<div style={ { marginTop: 15, marginRight: 15 } }>
										<AddButton label={ "הזמינו עוד" } onClick={ () => {
											if (!supplier.id) {
												this.save();
											}
											this.setState({
												isInviteModalOpen: true,
												modalContext: modalContextTypes.users
											})
										} }/>
									</div>
								</Row>


							</div>
							<Card style={ { position: "relative", paddingBottom: 50 } }>
								<CartHeader>ניהול נוטיפיקציות</CartHeader>
								<Row>
									<Col md={ 12 }>
										{ this.renderNotificationUsers() }
									</Col>
								</Row>
							</Card>
							<Row>
								<div style={ { marginTop: 15, marginRight: 15 } }>
									<AddButton label={ "הוספה" } onClick={ () => {
										if (!supplier.id) {
											this.save();
										}
										this.setState({
											isInviteModalOpen: true,
											modalContext: modalContextTypes.notifications
										})
									} }/>
								</div>
							</Row>
						</Col>
						<Col md={ 12 }>

						</Col>
					</Row>
				);
			case tabs.contractor:
				return (
					<Row>
						<Col md={ 12 }>
							<CardTitle>לקוחות</CardTitle>
							<Row>
								<Col md={ 3 }>
									<div style={ { marginTop: 15, marginBottom: 15 } }>
										<AddButton label={ "הזמנה" } onClick={ () => {
											if (!supplier.id) {
												this.save();
											}
											this.setState({
												isInviteModalOpen: true,
												modalContext: modalContextTypes.contractors
											})
										} }/>
									</div>
								</Col>
							</Row>
							<Row>
								<Col md={ 8 }>
									<div>
										<ReactTable
											style={ { minHeight: 168 } }
											data={ customers || [] }
											columns={ [
												{
													id: 'label',
													Header: 'לקוחות רשומים',
													accessor: 'label', // String-based value accessors!,
												},
												{
													id: 'erpId',
													Header: 'מפתח חשבון לקוח',
													Cell: props => (
														this.props.supplierContractors[ props.original.key ][ "erpId" ]
														|| ""
													), // String-based value accessors!,
												},
												{
													id: 'poCustomer',
													Header: 'הצעות מחיר',
													Cell: props => (
														<InputGroup>
															<input type={ "checkbox" }
															       disabled={ true }
															       onChange={ ( e ) => {
																       // this.setUserPermission(user.key, "users", !user.permissions.users)
															       } }
															       checked={ this.props.supplierContractors[ props.original.key ][ "po" ]
															       === true }
															/>
															{ this.props.supplierContractors[ props.original.key ][ "poEmails" ]
															&& this.props.supplierContractors[ props.original.key ][ "poEmails" ][ 0 ]
																?
																<span>{ this.props.supplierContractors[ props.original.key ][ "poEmails" ][ 0 ] }</span>
																: ""
															}
														</InputGroup>
													), // String-based value accessors!,
												},
												{
													id: "edit",
													Header: "",
													Cell: props => {
														return (
															<div onClick={ () => {
																this.setState({
																	editUserModalOpen: true,
																	editUserModalContext: Object.assign(
																		{},
																		props.original,
																		this.props.supplierContractors[ props.original.key ],
																		{ emails: this.transformPOEmailsToArray(this.props.supplierContractors[ props.original.key ][ "poEmails" ]) }
																	)
																})
															} }>
																<i className={ "fa fa-edit" }/>
															</div>
														)
													},
													width: 50
												}
											] }
											showPagination={ false }
											pageSize={ customers ? customers.length : 0 }
											filterable={ false }
										/>
									</div>
								</Col>
								<Col md={ 4 }>
									<div>
										<ReactTable
											style={ { minHeight: 168 } }
											data={ pendingCustomers || [] }
											columns={ [
												{
													id: 'label',
													Header: 'לקוחות מוזמנים',
													accessor: 'label', // String-based value accessors!,
												},
												{
													id: "resend",
													Header: "",
													width: 50,
													Cell: props => {
														console.log("resend", props.original)
														return (
															<div
																onClick={ () => {
																	console.log("resend", props.original)
																	this.resendContractorInvite(props.original.key)
																} }
															>
																<i className={ "fa fa-share" }/>
															</div>
														)
													}
												},
												{
													id: "delete",
													Header: "",
													width: 50,
													Cell: props => {
														console.log("invited contractor", props)
														return (
															<div
																onClick={ () => {
																	this.setState({
																		deleteInvidedUserModalOpen: true,
																		inviteModalContext: Object.assign(
																			{},
																			props.original,
																			{ pendingContractor: true }
																		)
																	})
																} }
															>
																<i className={ "fa fa-ban" }/>
															</div>
														)
													}
												}
											] }
											showPagination={ false }
											pageSize={ pendingCustomers ? pendingCustomers.length : 0 }
											filterable={ false }
										/>
									</div>
								</Col>

							</Row>

						</Col>
					</Row>
				);
			case tabs.deliveries:
				return (
					<Deliveries supplierId={ this.props.match.params.supplierId }/>
				)
			case tabs.branding:
				return (
					<Branding supplierId={ this.props.match.params.supplierId }/>
				)
			case tabs.agents:
				return (
					<Agents supplierId={ this.props.match.params.supplierId }/>
				)
		}
	}

	closeDeleteInviteUserModal = () => {
		this.setState({
			deleteInvidedUserModalOpen: false,
			inviteModalContext: ""
		})
	}

	deleteInviteUser = ( invitationId, isPendingContractor ) => {
		const { firebase } = this.props;
		//console.log("deleteInviteUser", invitationId)
		let path = `pendingInvitationsBySupplier/${ this.props.match.params.supplierId }/${ invitationId }`
		if (isPendingContractor) {
			path = `pendingContractorInvitationsBySupplier/${ this.props.match.params.supplierId }/${ invitationId }`
		}

		firebase.remove(
			path,
			( result ) => {
				console.log("complete delete invitation", result)
				// show success notification
				toastr.success('ההזמנה נמחקה בהצלחה');
				// close modal
				this.closeDeleteInviteUserModal()
			}
		);
	}

	render() {

		const {
			      supplier, isInviteModalOpen,
			      modalContext,
			      isDeleteModalOpen, deleteNotificationUser, tab,
			      deleteInvidedUserModalOpen,
			      inviteModalContext,
			      editUserModalOpen,
			      editUserModalContext,
			      editCustomerPOError
		      } = this.state;


		console.log("supplier", supplier);
		console.log("editUserModalOpen", editUserModalOpen);


		return (
			<div style={ { position: "relative" } }>
				<img src={ "img/bgLogo.png" } style={ {
					position: "absolute",
					bottom: -50,
					right: -150,
					width: 660,
					height: 771,
				} }/>
				<Row>
					<Col md={ 12 }>
						<CardTitle>ניהול חנות - { supplier.name }</CardTitle>
					</Col>
					<Col md={ 12 }>
						<div>
							<Button color="link" className={ tab === tabs.default ? "px-0 font-weight-bold" : "px-0" }
							        onClick={ () => {
								        this.switchTab(tabs.default)
							        } }>כללי</Button> |
							<Button color="link"
							        className={ tab === tabs.contractor ? "px-0 font-weight-bold" : "px-0" }
							        onClick={ () => {
								        this.switchTab(tabs.contractor)
							        } }>לקוחות</Button> |
							<Button color="link"
							        className={ tab === tabs.deliveries ? "px-0 font-weight-bold" : "px-0" }
							        onClick={ () => {
								        this.switchTab(tabs.deliveries)
							        } }>משלוחים</Button> |
							<Button color="link" className={ tab === tabs.branding ? "px-0 font-weight-bold" : "px-0" }
							        onClick={ () => {
								        this.switchTab(tabs.branding)
							        } }>מיתוג</Button> |
							<Button color="link" className={ tab === tabs.agents ? "px-0 font-weight-bold" : "px-0" }
							        onClick={ () => {
								        this.switchTab(tabs.agents)
							        } }>סוכנים</Button>
						</div>
					</Col>
				</Row>
				{ this.renderTab() }


				<Modal isOpen={ editUserModalOpen } toggle={ this.closeEditCustomerModal }>
					<ModalHeader>{ "עריכת לקוח - " + (
						editUserModalContext ? editUserModalContext.label : ""
					) }</ModalHeader>
					<ModalBody>
						<InputGroup>
							<InputLabel>
								מפתח חשבון לקוח
							</InputLabel>
							<input style={ { width: "100%", height: 34 } }
							       type={ "text" }
							       onChange={ ( e ) => {
								       editUserModalContext.erpId = e.target.value;
								       this.setState({
									       editUserModalContext
								       })
							       } }
							       value={ editUserModalContext ? editUserModalContext.erpId : "" }
							/>
						</InputGroup>

						<InputGroup>
							<InputLabel>
								הצעות מחיר
							</InputLabel>
							<input type={ "checkbox" }
							       onChange={ ( e ) => {
								       // this.setUserPermission(user.key, "users", !user.permissions.users)
								       console.log("e", e.target.checked)
								       editUserModalContext.po = e.target.checked;
								       this.setState({
									       editUserModalContext
								       })
							       } }
							       checked={ editUserModalContext ? editUserModalContext.po : false }
							/>
							<div style={ { color: "red" } }>{ editCustomerPOError }</div>
						</InputGroup>

						<InputGroup>
							<InputLabel>
								כתובות מייל למשלוח הצעה
							</InputLabel>
							<Creatable
								isMulti={ true }
								onChange={ ( newValue, actionMeta ) => {

									if (actionMeta.action === "create-option") {
										const last = _.last(newValue)
										if (last.__isNew__) {
											//validate last email
											if (!this.validateEmail(last.value)) {
												toastr.warning("כתובת אימייל לא חוקית");
												return
											}
										}
									}

									editUserModalContext.emails = newValue;
									this.setState({
										editUserModalContext
									})

								} }
								options={ [] }
								value={ editUserModalContext && editUserModalContext.emails
									? editUserModalContext.emails
									: [] }
							/>
						</InputGroup>


					</ModalBody>
					<ModalFooter>
						<SendButton
							onClick={ this.saveCustomer }>{ "שמירה" }</SendButton>
						<SendButton style={ { backgroundColor: "#818181" } }
						            onClick={ this.closeEditCustomerModal }>{ "ביטול" }</SendButton>
					</ModalFooter>
				</Modal>

				<Modal isOpen={ isInviteModalOpen } toggle={ this.closeInviteModal }>
					<ModalHeader>{ this.getModalTitle(modalContext) }</ModalHeader>
					<ModalBody>
						{ this.getModalBody(modalContext) }
					</ModalBody>
					<ModalFooter>
						<SendButton
							onClick={ this.inviteUser }>{ modalContext === modalContextTypes.notifications
							? "שמירה"
							: "הזמנה" }</SendButton>
						<SendButton style={ { backgroundColor: "#818181" } }
						            onClick={ this.closeInviteModal }>{ "ביטול" }</SendButton>
					</ModalFooter>
				</Modal>
				<Modal isOpen={ isDeleteModalOpen } toggle={ this.closeDeleteModal }>
					<ModalHeader>מחיקה</ModalHeader>
					<ModalBody>האם אתם בטוחים שאתם רוצים למחוק את
						המשתמש { deleteNotificationUser ? deleteNotificationUser.name : "" } מקבלת התראות?</ModalBody>
					<ModalFooter>
						<SendButton style={ { backgroundColor: "red" } }
						            onClick={ this.deleteNotificationUser }>{ "מחיקה" }</SendButton>
						<SendButton style={ { backgroundColor: "#818181" } }
						            onClick={ this.closeDeleteModal }>{ "ביטול" }</SendButton>
					</ModalFooter>
				</Modal>
				<Modal isOpen={ deleteInvidedUserModalOpen } toggle={ this.closeDeleteInviteUserModal }>
					<ModalHeader>{ "מחיקת משתמש מוזמן" }</ModalHeader>
					<ModalBody>
						<div>{ `אתם עומדים למחוק את ההזמנה של ${ inviteModalContext
							? inviteModalContext.label
							: "" } האם אתם בטוחים?` }</div>
					</ModalBody>
					<ModalFooter>
						<SendButton onClick={
							() => {
								this.deleteInviteUser(inviteModalContext.key, inviteModalContext.pendingContractor)
							} }>כן</SendButton>
						<SendButton style={ { backgroundColor: "#818181" } }
						            onClick={ this.closeDeleteInviteUserModal }>{ "ביטול" }</SendButton>
					</ModalFooter>
				</Modal>

			</div>
		);
	}


}


const SendButton = styled.div`
  border-radius: 3px;
  font-family: 'Rubik', sans-serif;
  font-size: 16px;
  text-align: center;
  color: #FFFFFF;
  height: 50px;
  line-height: 50px;
  padding: 0 11px;
  cursor: ${ props => props.disabled ? "default" : "pointer" };
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1);
  background-color: ${ props => props.disabled ? "#818181" : "#319CD8" };
`;


SupplierForm.propTypes = {
	supplierId: PropTypes.string
};

SupplierForm.defaultProps = {};

const mapStateToProps = ( state, props ) => {
	const supplier = getVal(state.firebase.data, `suppliers/${ props.match.params.supplierId }`)
	return {
		supplier,
		isUsersAdmin: isUsersAdmin(state.roles.roles),
		pendingInvitations: getVal(
			state.firebase.data,
			`pendingInvitationsBySupplier/${ props.match.params.supplierId }`
		),
		pendingContractors: getVal(
			state.firebase.data,
			`pendingContractorInvitationsBySupplier/${ props.match.params.supplierId }`
		),
		supplierContractors: getVal(
			state.firebase.data,
			`suppliersPrivateData/${ props.match.params.supplierId }/contractors`,
			{}
		),
		deliveryOptionsBySupplier: getVal(
			state.firebase.data,
			`deliveryOptionsBySupplier/${ props.match.params.supplierId }`,
			[]
		),
		users: getVal(state.firebase.data, `usersBySupplier/${ props.match.params.supplierId }`),
		notificationUsers: getVal(
			state.firebase.data,
			`suppliersPrivateData/${ props.match.params.supplierId }/notify`,
			{}
		),
		contractors: getVal(state.firebase.data, `contractors`, {}),
		token: state.account.token,
		printMarginTop: getVal(
			state.firebase.data,
			`suppliersPrivateData/${ props.match.params.supplierId }/printMarginTop`,
			0
		),

	};
};

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);


export default compose(
	firebaseConnect(( props ) => (
		[
			`contractors`,
			`suppliers/${ props.match.params.supplierId }`,
			`usersBySupplier/${ props.match.params.supplierId }`,
			`pendingInvitationsBySupplier/${ props.match.params.supplierId }`,
			`pendingContractorInvitationsBySupplier/${ props.match.params.supplierId }`,
			`deliveryOptionsBySupplier/${ props.match.params.supplierId }`,
			`suppliersPrivateData/${ props.match.params.supplierId }/notify`,
			`suppliersPrivateData/${ props.match.params.supplierId }/contractors`,
			`suppliersPrivateData/${ props.match.params.supplierId }/printMarginTop`
		]
	)),
	connect(
		mapStateToProps, mapDispatchToProps
	)
)(SupplierForm);
