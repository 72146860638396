import React from 'react';
import PropTypes from 'prop-types'
import _ from "lodash";
import styled from 'styled-components';
import moment from "moment";


import Ellipsis from "../../components/Elipsis";
import {Card, CardActions, CardHeader, CardMedia, CardTitle, CardText} from 'material-ui/Card';
import {injectIntl, FormattedMessage} from 'react-intl';

import {noProductThumb} from "shared/src/constants/config";


class ArticleListItem extends React.Component{

    constructor() {
        super()
    }

    render() {

        const {
            item, onItemEdit,
            onItemDelete
        } = this.props;

        return (
            <Card initiallyExpanded={false} style={{padding: "0 !important"}}>
                <CardHeader showExpandableButton={true} style={{padding: 0}}>
                    <Item>
                        <ItemSpecifier>{item.isPublished ? "Published" : "Unpublished"}</ItemSpecifier>
                        <ItemSpecifier><Ellipsis maxWidth={400} text={item.title}/></ItemSpecifier>
                        <ItemSpecifier>{moment(item.lastUpdated).format("MMM Do YYYY, HH:mm:ss")}</ItemSpecifier>
                        <ItemAction onClick={() => onItemEdit(item)}><i className={"fa fa-edit"}/></ItemAction>
                        <ItemAction onClick={() => onItemDelete(item)}><i className={"fa fa-remove"}/></ItemAction>
                    </Item>
                </CardHeader>
                <CardText expandable={true} style={{padding: 0}}>
                    <RTL>

                    </RTL>
                </CardText>
            </Card>
        )
    }
}

const Item = styled.div`
    display: flex;
    flex-direction: row;
`;

const ItemSpecifier = styled.div`
    display: flex;
    flex-direction: column;
    img{
        max-height: 40px;
        max-width: 40px;
    }
    margin: 10px;
`;

const ItemAction = styled.div`
    display: flex;
    flex-direction: column;
    cursor: pointer;
    margin: 10px;
`;

const RTL = styled.div`
    direction: rtl;
    text-align: right;
`;

const Images = styled.div`
    display: flex;
    flex-direction: row;
    img{
        display: flex;
        flex-direction: column;
        margin: 10px;
        max-height: 70px;
        max-width: 70px;
        width: auto;
        height: auto;
    }
`;

const Group = styled.div`
    padding: 10px;
    background: 
`;

const ItemLabel = styled.span`
    font-weight: 800;
`;

ArticleListItem.propTypes = {
    item: PropTypes.object,
    onItemEdit: PropTypes.func,
    onItemDelete: PropTypes.func
};

export default ArticleListItem;