import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { bindActionCreators, compose } from "redux";
import { firebaseConnect, isLoaded, getVal } from 'react-redux-firebase'
import styled from "styled-components";
import memoize from "memoize-one";
import moment from "moment"
import _ from "lodash";

import { sendPo, converPOToOrder, getPOUsers } from "shared/src/api/pos"
import { fetchPoItems } from "shared/src/api/orderItems"
import {
    initPoById,
} from "shared/src/modules/pos";
import { getItemPackage, getNumberOfCartItems, getOrderTotalPrice } from "shared/src/selectors/orders"

import ROUTES from "../../../constants/routes";
import { isMobileOrTablet } from "../../../utils/UA";
import { Col, Row } from "reactstrap";
import OrderListItem, { ORDER_LIST_ITEM_CONTEXT_TYPES } from "../../OrdersManagement/orderListItem";
import { noProductImageUrl, PRODUCT_ACTION_TYPES } from "shared/src/constants/orders";
import { Card, CardTitle, InputError, InputGroup, InputLabel } from "../../../styles/stylesheet";
import { List } from "material-ui/List";
import IconInput, { IconWrapper } from "../../../components/Form/IconInput";
import { SingleDatePicker } from "react-dates";
import { getStatusLabel } from "shared/src/utils/po";

import { history } from "../../../store";
import { extractLatestStatus } from "shared/src/utils/orders";
import { getContractorUsers } from "shared/src/api/contractors";
import { getRoutParams as getParams } from "../../../utils/navigtion"

import ProjectSelectionModal from "./projectSelectionModal"
import SuccessModal from "../../../components/SuccessModal"
import RoundButton from "../../../components/RoundButton"

const getRoutParams = () => {
    return getParams(ROUTES.PO)
}

class ContractorPOForm extends Component {

    constructor() {
        super()
        this.state = {
            lastSelectedIndex: false,
            isOpenOrderSendModal: false,
            isOpenProductModal: false,
            isOpenSendValidationModal: false,
            productModalItem: "",
            orderSendModalStatus: true,
            isDateTimePickerVisible: false,
            selectedDeliveryOption: null,
            isOpenDeliveryOptionsModal: false,
            isOpenProjectSelection: false,
            convertPOAction: "send",
            selectedProject: null,
            users: [],
            isLoadingUsers: false
        }

    }

    componentDidMount = () => {

        const {po, posLoading, posContextLoaded, initPoById} = this.props;
        const {supplierId, customerId, poId} = getRoutParams();
        if (!po.id && !posLoading && posContextLoaded) {
            initPoById(poId)
        }
        this.fetchScreenData()
    }

    componentDidUpdate = (prevProps, prevState) => {
        const {po, posLoading, posContextLoaded, initPoById} = this.props;
        if (!po.id && (posLoading !== prevProps.posLoading || posContextLoaded !== prevProps.posContextLoaded) && !posLoading && posContextLoaded) {
            const {supplierId, customerId, poId} = getRoutParams();
            initPoById(poId)
            this.fetchScreenData();
        }
    }

    fetchScreenData = () => {
        const {fetchPoItems, getPOUsers, firebase, po} = this.props;
        const {supplierId, customerId, poId} = getRoutParams();
        // getPOUsers({customerId, supplierId});
        const userId = _.get(po, "requestedByUser.id", null)
        if (userId) {
            this.setState({isLoadingUsers: true})
            getContractorUsers(firebase, [userId], customerId).then(users => {
                console.log("get users success", users)
                this.setState({users: Object.values(users), isLoadingUsers: false})
            })
        }

        fetchPoItems({supplierId, customerId, poId});
    }

    onAccept = () => {
        const {converPOToOrder, po} = this.props;
        const {convertPOAction, selectedProject} = this.state;
        const {supplierId, customerId, poId} = getRoutParams();
        console.log("Accepting PO", selectedProject);
        const shouldRedirect = convertPOAction === "edit";

        converPOToOrder({
            customerId,
            supplierId,
            poId
        }, selectedProject, convertPOAction, shouldRedirect);
        this.closeProjectSelection()

    }

    acceptContract = () => {
        const {converPOToOrder, po} = this.props;
        const {supplierId, customerId, poId} = getRoutParams();


        converPOToOrder({
            customerId,
            supplierId,
            poId
        }, undefined, undefined, false, true);

    }

    openProjectSelection = (action = "send") => {
        this.setState({
            isOpenProjectSelection: true,
            convertPOAction: action
        })
    }

    closeProjectSelection = () => {
        this.setState({
            isOpenProjectSelection: false,
            convertPOAction: "send",
            selectedProject: null
        })
    }

    filterProjects = memoize(
        (list) => {
            if (!list) {
                return []
            }
            const {supplierId, customerId, poId} = getRoutParams();
            let filtered = Object.values(list).filter((item) => {
                // console.log("filter project", item)
                const supplierProjects = item.suppliers ? Object.keys(item.suppliers).filter(sup => sup === supplierId) : [];
                // console.log("supplierProjects",supplierId, supplierProjects)
                return supplierProjects.length > 0
            })

            return filtered.map(project => {
                return {
                    value: project.data.id,
                    label: project.data.name
                }
            })
        }
    );

    closeSendIndicationModal = () => {
        const {orderSendModalStatus} = this.state
        this.setState({isOpenOrderSendModal: false});
        let route = ""
        switch (orderSendModalStatus) {
            case "success":
                route = ROUTES.ORDERS;
                break;
            case "edit":
                route = ROUTES.ORDER_SUMMARY;
                break;
            default:
                return;

        }
        history.push(route)
    }

    renderSummary = () => {
        const {po} = this.props;

        let itemsPerRow = isMobileOrTablet() ? 1 : 3;
        let colSize = Math.ceil(12 / itemsPerRow);
        let row = []
        let rows = [];
        let numOfAddonItemsAddedToList = 0;

        let itemsCount = getNumberOfCartItems(po.items);
        const {price, count} = getOrderTotalPrice(po, true);

        const addItemToOrderSummay = (index, key, component) => {
            row.push(
                <Col md={colSize} key={key}>
                    {component}
                </Col>
            )

            if (row.length === itemsPerRow || index + 1 === itemsCount) {
                rows.push(<Row style={{marginBottom: 100}}>{row}</Row>)
                row = [];
            }
        }


        po.items.map((item, index) => {

            addItemToOrderSummay(index + numOfAddonItemsAddedToList, `item_${item.id}_${item.room ? item.room.id : index}_${index}`, (
                <OrderListItem
                    catalogId={item.catalogId}
                    imgSrc={item.downloadURL || noProductImageUrl}
                    name={item.name}
                    packageInfo={getItemPackage(item)}
                    count={item.count}
                    price={item.default_price}
                    context={ORDER_LIST_ITEM_CONTEXT_TYPES.PO_SUMMARY}
                    item={item}
                    disabled={true}
                    hidePrices={false}
                />))

            if (!po.isPOContract && !_.isEmpty(item.addOnProducts)) {
                //console.log("adding addon products to order sumamry", item.addOnProducts);
                item.addOnProducts.forEach((addon, j) => {
                    //    console.log("adding addon", addon.item , index, j);
                    if (addon.item) {
                        console.log("adding addon product", addon.item, index, j);
                        const image = _.get(addon.item, "images[0].downloadURL", noProductImageUrl);

                        addItemToOrderSummay(index + j + 1 + numOfAddonItemsAddedToList, `item_${item.id}_${item.room ? item.room.id : index}_${index}_addon_${j}`, (
                                <OrderListItem
                                    catalogId={addon.item.catalogId}
                                    imgSrc={image}
                                    packageInfo={getItemPackage(addon.item)}
                                    name={addon.item.name}
                                    count={item.count}
                                    price={addon.item.default_price}
                                    context={ORDER_LIST_ITEM_CONTEXT_TYPES.PO_SUMMARY}
                                    disabled={true}
                                    item={item}
                                    itemActions={[
                                        {type: PRODUCT_ACTION_TYPES.ADD_ON_ITEM, data: item}
                                    ]}
                                    hidePrices={false}
                                />
                            )
                        )
                        numOfAddonItemsAddedToList++;
                    }

                })

            }

        })

        console.log("rows", po.items, rows)

        return (
            <Fragment>
                <CardTitle>סיכום הצעת מחיר</CardTitle>
                <Card>
                    <div style={{backgroundColor: "#f9f9f9"}}>
                        <div>
                            <div>
                                <TotalItems>
                                    <span>{`${count} פריטים`}</span>
                                </TotalItems>
                                <div style={{display: "flex", justifyContent: "space-between"}}>
                                    <TotalPrice>&#8362;{price}</TotalPrice>
                                </div>
                            </div>
                        </div>
                        <List style={{paddingRight: 124}}>{rows}</List>
                    </div>
                </Card>
            </Fragment>

        )
    }

    renderActionButtons = () => {
        const {
            po
        } = this.props;
        return (
            <div style={{display: "flex", justifyContent: "space-around"}}>
                {/* {!po.isPOContract && (
                    <RoundButton
                        style={{marginLeft: 10}}
                        label={"יצירת הזמנה ועריכה"}
                        onClick={() => this.openProjectSelection("edit")}
                    />
                )}*/}
                <RoundButton
                    style={{marginLeft: 10}}
                    onClick={() => {
                        po.isPOContract ? this.acceptContract() : this.openProjectSelection("send")
                    }}
                    backgroundColor={"#319CD8"}
                    label={"קבלת הצעה"}
                />
            </div>
        )
    }


    render() {

        const {po, projects} = this.props;
        const {orderSendModalStatus, users, isLoadingUsers} = this.state;
        const latestStatus = extractLatestStatus(po.status);

        return (
            <div>
                <CardTitle>הצעת מחיר</CardTitle>
                <Card>
                    <Row>
                        <Col md={12}>
                            <div style={{display: "flex", justifyContent: "flex-end"}}>
                                {this.renderActionButtons()}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={2}>
                            <InputGroup>
                                <InputLabel>תאריך יצירה</InputLabel>
                                <div style={{position: "relative"}}>
                                    <IconWrapper style={{top: 0, right: 8}}><img
                                        src={"img/icons/date.svg"}/></IconWrapper>
                                    <SingleDatePicker
                                        disabled={true}
                                        date={moment(po.createdDate)} // momentPropTypes.momentObj or null
                                        onDateChange={date => setFieldValue("currentPo.createdDate", moment(date).toDate())} // PropTypes.func.isRequired
                                        focused={this.state.focused} // PropTypes.bool
                                        onFocusChange={({focused}) => this.setState({focused})} // PropTypes.func.isRequired
                                        id="deliveryDate2" // PropTypes.string.isRequired,
                                        isRTL={true}
                                        withPortal={true}
                                        appendToBody={true}
                                        disableScroll={true}
                                        small={true}
                                        block={true}
                                        displayFormat={"D/MM/YY"}
                                        isOutsideRange={() => false}
                                    />
                                </div>
                            </InputGroup>
                        </Col>
                        <Col md={2}>
                            <InputGroup>
                                <InputLabel>סטטוס</InputLabel>
                                <IconInput
                                    type={"text"}
                                    value={getStatusLabel(latestStatus, po.isPOContract)}
                                    disabled={true}
                                    icon={<img src={"img/icons/location.svg"}/>}
                                />
                            </InputGroup>
                        </Col>
                        <Col md={3}>
                            <InputGroup>
                                <InputLabel>ספק</InputLabel>
                                <IconInput
                                    type={"text"}
                                    value={_.get(po, "supplier.name", "")}
                                    disabled={true}
                                    icon={<img src={"img/icons/location.svg"}/>}
                                />
                            </InputGroup>
                        </Col>
                        <Col md={2}>
                            <InputGroup>
                                <InputLabel>הצעת הסכם מסגרת</InputLabel>
                                <input
                                    style={{width: 34, height: 34}}
                                    type={"checkbox"}
                                    disabled={true}
                                    checked={po.isPOContract}
                                />
                            </InputGroup>
                        </Col>


                    </Row>
                    <Row>
                        {!po.isPOContract && (
                            <Col md={4}>
                                <InputGroup>
                                    <InputLabel>המזמין</InputLabel>
                                    <IconInput
                                        type={"select"}
                                        options={users}
                                        value={_.get(po, "requestedByUser.id", null)}
                                        disabled={true}
                                        icon={<img src={"img/icons/person.svg"}/>}
                                        valueAccessor={"id"}
                                        labelAccessor={(user) => {
                                            return `${user.displayName} ${user.phoneNumber || ""}`
                                        }}
                                        isLoading={isLoadingUsers}
                                    />
                                </InputGroup>
                            </Col>
                        )}

                        <Col md={4}>
                            <InputGroup>
                                <InputLabel>טופל ע"י</InputLabel>
                                <IconInput
                                    type={"text"}
                                    value={_.get(po, "lastUpdatedBy.displayName", null)}
                                    disabled={true}
                                    icon={<img src={"img/icons/person.svg"}/>}
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <InputGroup>
                                <InputLabel>הערות</InputLabel>
                                <textarea
                                    disabled={true}
                                    style={{
                                        width: "100%",
                                        minHeight: 72,
                                        borderRadius: 3,
                                        border: "1px solid #E0E0E0",
                                        resize: "none"
                                    }}
                                    value={_.get(po, "note", "")}
                                />
                            </InputGroup>
                        </Col>
                        <Col md={6}>
                            <InputGroup>
                                <InputLabel>הנחיות מיוחדות</InputLabel>
                                <textarea
                                    disabled={true}
                                    style={{
                                        width: "100%",
                                        minHeight: 72,
                                        borderRadius: 3,
                                        border: "1px solid #E0E0E0",
                                        resize: "none"
                                    }}
                                    value={_.get(po, "specialRequest", "")}
                                />
                            </InputGroup>
                        </Col>

                    </Row>
                </Card>

                {this.renderSummary()}

                <ProjectSelectionModal
                    disabled={!this.state.selectedProject}
                    onSubmit={this.onAccept}
                    isOpen={this.state.isOpenProjectSelection}
                    selectLabel={"אנא בחרו פרוייקט"}
                    options={this.filterProjects(projects)}
                    onClose={this.closeProjectSelection}
                    onChane={(selected, action) => {
                        // console.log(action, selected);
                        this.setState({selectedProject: selected.value});
                    }}
                />

                <SuccessModal
                    isOpen={this.state.isOpenOrderSendModal}
                    onSubmit={this.closeSendIndicationModal}
                    message={orderSendModalStatus === "edit" ? "ההזמנה נוצרה" : "ההצעה התקבלה"}
                    messageBold={"בהצלחה"}
                />
            </div>
        );
    }
}

const TotalItems = styled.div`
  font-family: 'Rubik', sans-serif;
  font-size: 14px;
  color: #828282;
  margin-top: 11px;
`;

const TotalPrice = styled.div`
  font-family: 'Rubik', sans-serif;
  font-size: 36px;
  color: #4F4F4F;
`;

const SendButton = styled.div`
 
    border-radius: 3px;
    font-family: 'Rubik', sans-serif;
    font-size: 18px;
    text-align: center;
    color: #FFFFFF;
    height: 50px;
    line-height: 50px;
    padding: 0 11px;
    cursor:  ${props => props.disabled ? "default" : "pointer"};
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1);
    background-color: ${props => props.disabled ? "#818181" : props.backgroundColor ? props.backgroundColor : "#319CD8"};
`

ContractorPOForm.propTypes = {};

ContractorPOForm.defaultProps = {};

const mapStateToProps = (state) => {
    const {supplierId, customerId, poId} = getRoutParams();
    return {
        po: state.pos.currentPo,
        posLoading: state.pos.posLoading,
        //   poUsers: state.pos.poUsers,
        //   isLoadingUsers: state.pos.isLoadingUsers,
        posContextLoaded: state.pos.posContextLoaded,
        itemsLoading: state.pos.itemsLoading,
        items: state.pos.currentPo.items,
        auth: state.firebase.auth,
        projects: getVal(state.firebase.data, `projects/${customerId}`, {}),
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({
    converPOToOrder,
    fetchPoItems,
    sendPo,
    getPOUsers,
    initPoById
}, dispatch);


export default compose(
    firebaseConnect((props) => {
        const {supplierId, customerId, poId} = getRoutParams();
        return [
            `projects/${customerId}`,
        ]
    }),
    connect(
        mapStateToProps, mapDispatchToProps
    )
)(ContractorPOForm);

