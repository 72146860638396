
export function upload(firebaseRef ,storagePath, file, dbPath, successCB) {
    console.log("uploading file", firebaseRef, storagePath,file, dbPath);
    // generate push id for the file
    let newKey = firebaseRef.database().ref().child(dbPath).push().key;
    let updates = {};
    // upload the file
    firebaseRef.storage().ref(`${storagePath}/${newKey}`)
        .put(file)
        .then(function (snapshot) {
            const { metadata: { name, fullPath, downloadURLs } } = snapshot;
            let item = { name, fullPath }
            snapshot.ref.getDownloadURL().then(function(downloadURL) {
                //console.log('File available at', downloadURL);
                item["downloadURL"] = downloadURL;
                updates[`${dbPath}/${newKey}`] = item;
                // save the file metadata to database
                firebaseRef.database().ref().update(updates)
                    .then(function () {
               //         console.log("updated", updates, item)
                        successCB(item)
                    });

            });

        })
}