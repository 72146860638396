import React from 'react';
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {injectIntl, FormattedMessage, formatMessage} from 'react-intl'
import {compose} from "redux";
import {firebaseConnect} from "react-redux-firebase";
import _ from "lodash";
import styled from 'styled-components';

import ReactQuill from 'react-quill';
import Delta from "quill-delta";
import 'react-quill/dist/quill.snow.css';


import {
    Modal, ModalHeader, ModalBody, ModalFooter, Button
} from 'reactstrap';

import TextField from 'material-ui/TextField';
import Toggle from 'material-ui/Toggle';
import RaisedButton from 'material-ui/RaisedButton';
import ProductRefSelect from "../../components/Form/ProductRefSelect";
import ProductRefsGroup from "../../components/Form/ProductRefsGroup";
import ImageUploader from "./ImageUploader";
import Tags from "./Tags"
import ProductSearch from "../../components/Form/ProductSearch"
import RelatedProducts from "../../components/Form/RelatedProducts";

import {
    onArticleFieldChange, addRelatedProduct, updateRelatedProduct, deleteRelatedProduct, updateArticle,
    clearArticle, addTag,
    deleteTag
} from "shared/src/modules/article";

import {getCatalog, clearCatalog} from "shared/src/modules/catalog";

import Categories from "shared/src/constants/news/categories"

import {Row, Col} from 'reactstrap';


const editorModules = {
    toolbar: [
        [{font: []}, {size: []}],
        [{align: []}, 'direction'],
        ['bold', 'italic', 'underline', 'strike'],
        [{color: []}, {background: []}],
        [{script: 'super'}, {script: 'sub'}],
        ['blockquote', 'code-block'],
        [{list: 'ordered'}, {list: 'bullet'}, {indent: '-1'}, {indent: '+1'}],
        ['link', 'image', 'video'],
        ['clean']
    ]
};

const style = {
    nameField: {height: 48, background: "#fff", marginBottom: 17, borderRadius: 6, paddingRight: 5, paddingLeft: 5},
    summaryField: {height: 160, background: "#fff", marginBottom: 17, borderRadius: 6, paddingRight: 5, paddingLeft: 5},
    switch: {height: 48, background: "#fff", marginTop: 17, borderRadius: 6, padding: " 10px 20px"},
}

class ArticleForm extends React.Component {

    constructor() {
        super();
        this.state = {
            text: new Delta(),
            formError: [],
            isModalOpen: false
        };
        this.handleChange = this.handleChange.bind(this)
        this.onSave = this.onSave.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);

    }

    componentDidMount() {
        const {id, isLoading, history} = this.props;
        if (!id && !isLoading) {
            history.push("/");
        }
       // let editor = this.quill.getEditor();
        //console.log("editor", e);
       // editor.format('direction', 'rtl');
       // editor.format('align', 'right');
    }

    componentWillUnmount() {
        const {clearArticle} = this.props;
        clearArticle();
    }

    handleChange(content, delta, source, editor) {
        const {onArticleFieldChange} = this.props;
        onArticleFieldChange("articleText", editor.getContents());
    }

    onSave() {
        const {userId, updateArticle, history} = this.props;
        if(this.validateForm()){
            updateArticle(userId);
            history.push("/article-management");
        }

    }

    validateForm(){
        const {
            title, isPublished, internalMedia,
            externalMedia, summary, articleText, itemReference,
            newsCategories, relatedProducts, tags, intl
        } = this.props;

        if(!externalMedia && (!internalMedia || internalMedia.length === 0)){
            this.setState({
                formError: [intl.formatMessage({id: "article.form.validation.images"})],
                isModalOpen: true
            })
            return false;
        }
        else {
            this.setState({
                formError: [],
            })
            return true;
        }
    }

    openModal() {
        this.setState({
            isModalOpen: true,
        })
    }

    closeModal() {
        this.setState({
            isModalOpen: false,
            formError: [],
        })
    }

    render() {
        const {
            id, isLoading, title, isPublished, internalMedia,
            externalMedia, summary, articleText, itemReference,
            newsCategories, relatedProducts, intl, onArticleFieldChange, suppliers,
            addRelatedProduct, updateRelatedProduct, deleteRelatedProduct, userId,
            tags, addTag, deleteTag, searchResults ,isLoadingSearch, getCatalog, clearCatalog
        } = this.props;

        const {formError} = this.state;

        return (
            <Container>
                <Row>
                    <Col lg={9}>
                        <Row>
                            <Col lg={8}>
                                <Row>
                                    <Col lg={12}>
                                        <TextField
                                            value={title}
                                            hintText={intl.formatMessage({id: "article.form.title"})}
                                            onChange={(e, value) => {
                                                onArticleFieldChange("title", value)
                                            }}
                                            underlineShow={false}
                                            fullWidth={true}
                                            style={style.nameField}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12}>
                                        <TextField
                                            value={summary}
                                            hintText={intl.formatMessage({id: "article.form.summary"})}
                                            onChange={(e, value) => {
                                                onArticleFieldChange("summary", value)
                                            }}
                                            multiLine={true}
                                            rows={5}
                                            rowsMax={5}
                                            underlineShow={false}
                                            fullWidth={true}
                                            style={style.summaryField}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12}>
                                        <TextField
                                            value={externalMedia}
                                            hintText={intl.formatMessage({id: "article.form.externalMedia"})}
                                            onChange={(e, value) => {
                                                onArticleFieldChange("externalMedia", value)
                                            }}
                                            fullWidth={true}
                                            style={style.nameField}
                                            underlineStyle={{width: "98%"}}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={4}>
                                <ImageUploader
                                    dbPath={`news/${id}/data/internalMedia`}
                                    storagePath={`application/news/${id}/internalMedia`}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <ReactQuill
                                    value={articleText}
                                    onChange={this.handleChange}
                                    modules={editorModules}
                                >

                                  {/*  <div className={"my-rtl-quill"} style={{direction: "rtl", textAlign: "right"}}/>*/}
                                </ReactQuill>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={3}>
                        <Row>
                            <Col lg={12}>
                                <Tags
                                    buttonLabel={"הוסף"}
                                    title={"תגיות"}
                                    addTag={addTag}
                                    limit={4}
                                    removeTag={deleteTag}
                                    tags={tags}
                                />
                            </Col>
                        </Row>
                      <Row>
                            <Col lg={12}>
                                <RelatedProducts
                                    onClear={()=>{clearCatalog()}}
                                    onResultSelection={(result, supplier)=>{addRelatedProduct(result, supplier)}}
                                    onSearch={getCatalog}
                                    results={searchResults}
                                    isLoading={isLoadingSearch}
                                    suppliers={suppliers}
                                    relatedProducts={relatedProducts}
                                    label={"מוצרים קשורים"}
                                    onRemoveProduct={deleteRelatedProduct}
                                />
                            </Col>
                        </Row>
                        {/*<div>
                            <Group>
                                <ProductRefSelect
                                    mainLabel={intl.formatMessage({id: "article.form.itemReference"})}
                                    itemIdLabel={intl.formatMessage({id: "product.id"})}
                                    itemIdValue={itemReference.itemId}
                                    onItemIdChange={(value) => {
                                        onArticleFieldChange("itemReference.itemId", value)
                                    }}
                                    onSupplierChange={(value) => onArticleFieldChange("itemReference.supplierId", value)}
                                    suppliersList={suppliers}
                                    suppliersValue={itemReference.supplierId}
                                />
                            </Group>
                        </div>*/}
                        <Row>
                            <Col lg={12}>
                                <Toggle
                                    style={style.switch}
                                    labelStyle={{fontSize: 24, color: "#707070"}}
                                    label={intl.formatMessage({id: "article.form.isPublished"})}
                                    toggled={isPublished}
                                    onToggle={(e, value) => {
                                        onArticleFieldChange("isPublished", value)
                                    }}
                                />
                            </Col>
                        </Row>


                        <RaisedButton
                            onClick={() => this.onSave()}
                            label={"save"}
                            primary={true}
                        />
                    </Col>
                </Row>
                <Modal isOpen={this.state.isModalOpen} className={'modal-warning '}>
                    <ModalHeader><FormattedMessage id="article.form.validation.modal.title"/></ModalHeader>
                    <ModalBody>
                        {
                            formError.length > 0 ? formError.map((e)=>(
                                <div>{e}</div>
                            )) : null
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.closeModal}><FormattedMessage
                            id="article.form.validation.modal.close"/></Button>
                    </ModalFooter>
                </Modal>
            </Container>
        )
    }
}

const Container = styled.div`

`;

const Group = styled.div`
    border: 1px dashed #29363d;
    margin-top: 10px;
    padding: 10px;
`;

const Field = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const SelectWrapper = styled.div`
    padding: 10px;
`;
const mapStateToProps = state => ({
    id: state.article.id,
    userId: state.article.userId,
    isLoading: state.article.isLoading,
    title: state.article.title,
    isPublished: state.article.isPublished,
    internalMedia: state.article.internalMedia,
    externalMedia: state.article.externalMedia,
    summary: state.article.summary,
    articleText: state.article.articleText,
    itemReference: state.article.itemReference,
    newsCategories: state.article.newsCategories,
    tags: state.article.tags,
    relatedProducts: state.article.relatedProducts,
    suppliers: state.firebase.data.suppliers,
    auth: state.firebase.auth,
    searchResults: state.catalog.catalog,
    isLoadingSearch: state.catalog.isLoading,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    onArticleFieldChange,
    addRelatedProduct,
    updateRelatedProduct,
    deleteRelatedProduct,
    updateArticle,
    clearArticle,
    addTag,
    deleteTag,
    getCatalog,
    clearCatalog
}, dispatch);


export default compose(
    firebaseConnect([
        'suppliers'
    ]),
    connect(
        mapStateToProps, mapDispatchToProps
    )
)(injectIntl(ArticleForm));

