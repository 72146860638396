import React, {Component} from 'react';
import PropTypes from 'prop-types'
import styled from 'styled-components';

import FloatingActionButton from 'material-ui/FloatingActionButton';
import FontIcon from 'material-ui/FontIcon';

class StickyFloatingButton extends Component{

    constructor() {
        super()
    }

    render() {

        const {icon, onPress, position} = this.props;

        return (
            <StickyFloating position={position}>
                <FloatingActionButton onClick={onPress}>
                    <FontIcon className="material-icons">{icon}</FontIcon>
                </FloatingActionButton>
            </StickyFloating>
        )
    }
}

StickyFloatingButton.propTypes = {
    icon: PropTypes.string,
    onPress: PropTypes.func,
    position: PropTypes.oneOf(["fixed", "absolute"])
};

StickyFloatingButton.defaultProps = {
    position: "fixed"
}

const StickyFloating = styled.div`
    position: ${props => props.position};
    bottom: ${props => props.position === "fixed" ? "50px" : "25px"};
    left: ${props => props.position === "fixed" ? "50px" : "25px"};
`;


export default StickyFloatingButton;