import React, {Component} from 'react';
import PropTypes from 'prop-types'

const ios = "https://apps.apple.com/us/app/punct/id1411197876";
const android = "https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=xyz.ibuildit.native.app&ddl=1"


class Invite extends Component {

    componentDidMount()
    {
        const invitationId = getParam("invId");

        if(navigator.userAgent.toLowerCase().indexOf("android") > -1){
            window.location.href = android;
        }else if(navigator.userAgent.toLowerCase().indexOf("iphone") > -1){
            window.location.href = ios;
        }else {
            let fallback = "/signup";
            if(invitationId){
                fallback = fallback + "?" + invitationId
            }
            this.props.history.push(fallback)
        }
    }

  render() {

      return (
      <div></div>
    );
  }
}

const getParam = (name) => {
    let url_string = window.location.href; //window.location.href
    let url = new URL(url_string);
    let param = url.searchParams.get(name);
    return param;
}

Invite.propTypes = {
};

Invite.defaultProps = {
};


export default Invite;