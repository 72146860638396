import React from "react";
import { fonts, colors } from "../../styles/styleguide";

const SearchInput = ({ inputProps, iconSrc, inputStyle }) => (
  <div
    style={Object.assign(
      {},
      {
        width: "auto",
        backgroundColor: "#fff",
        borderRadius: 26.5,
        paddingLeft: 17,
        paddingRight: 17,
        marginRight: 10,
        marginLeft: 10,
        paddingTop: 10,
        paddingBottom: 10,
        position: "relative",
      },
      inputStyle
    )}
  >
    {!!iconSrc && (
      <div
        style={{
          borderRadius: "50%",
          backgroundColor: colors.yellow,
          position: "absolute",
          top: 10,
          right: 5,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: 30,
          height: 30,
        }}
      >
        <img src={iconSrc} />
      </div>
    )}
    <input
      style={{
        border: "none",
        outline: "none",
        textAlign: "center",
        fontFamily: fonts.OpenSansHebrew,
        color: colors.charcoal,
        backgroundColor: "#fff",
        fontSize: 16,
        fontWeight: 400,
        width: "calc( 100% - 17px )",
      }}
      {...inputProps}
    />
  </div>
);

export default SearchInput;
