import React, { Component } from 'react';
import { Route, Switch } from "react-router-dom";
import ROUTES from "../../constants/routes";
import Contracts from "./contracts"
import Contract from "./contract"


class Nav extends Component {
    render() {
        return (
            <div>
                <Switch>
                    <Route exact path={ROUTES.PRICE_LIST} name="PO List" render={(props) => (<Contracts {...this.props}/>)}/>
                    <Route path={ROUTES.PRICE_LIST_ITEMS} name="PO Form" render={(props) => (<Contract  {...this.props}/>)}/>
                </Switch>
            </div>
        );
    }
}

export default Nav;