import styled from 'styled-components';
import {fonts, colors} from "./styleguide";

export const Card = styled.div`
    padding: 15px;
    margin-top: 17px;
    border-radius: 10px;
    box-shadow: -16.5px 9.5px 40px 0 rgba(205, 205, 205, 0.25);
    background-color: #fefefe;
    position: relative;
    `;

export const CartHeader = styled.div`
  height: 50px;
  box-shadow: -16.5px 9.5px 40px 0 rgba(205, 205, 205, 0.25);
  background-color: #fff;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  font-family: ${fonts.OpenSansHebrew};
  color: ${colors.warmGrey};
  font-size: 25px;
  padding-right: 26px;
  padding-left: 26px;
  line-height: 50px;
  margin-top: -15px;
  margin-left: -15px;
  margin-right: -15px;
  
    
  @media (min-width: 320px) and (max-width: 767px) {
        font-size: 21px;
    }
`;

export const CardTitle = styled.div`
    font-family: ${fonts.OpenSansHebrew};
    font-size: 26px;
    font-weight: bold;
    color: ${colors.greyish};
`;

export const InputLabel = styled.div`
    font-family: ${fonts.OpenSansHebrew};
    font-size: 17px;
    color: ${colors.warmGrey};
    margin-bottom: 6px; 
`;

export const InputGroup = styled.div`
  margin-top: 10px;
`;

export const Button = styled.div`
    border-radius: 3px;
    font-family: 'Rubik', sans-serif;
    font-size: 18px;
    text-align: center;
    color: #FFFFFF;
    height: 50px;
    line-height: 50px;
    padding: 0 11px;
    cursor:  ${props => props.disabled ? "default" : "pointer"};
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1);
    background-color: ${props => props.disabled ? "#818181" : props.backgroundColor ? props.backgroundColor : "#319CD8"};
`

export const InputError = styled.div`
   font-family: ${fonts.OpenSansHebrew};
    font-size: 14px;
    color: ${colors.red};
`

export const DetailsRow = styled.div`
    font-family: 'Rubik', sans-serif;
    font-size: 18px;
    color: #636363;
`;

export const Detail = styled.span`
    font-family: 'Rubik', sans-serif;
    font-size: 18px;
    color: #636363;
    margin-right: 7px;
    vertical-align: text-bottom;
`;
