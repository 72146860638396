import React from 'react';
import PropTypes from 'prop-types'
import TextField from 'material-ui/TextField';
import IconButton from 'material-ui/IconButton';
import FontIcon from 'material-ui/FontIcon';

class SearchField extends React.Component{

    constructor() {
        super()
    }

    
    render() {
        const { value, onChange, onSubmit, label} = this.props;
        return (
            <div>
                <TextField value={value}
                           onChange={(e, value)=>{onChange(value)}}
                           floatingLabelText={label}
                           onKeyPress={
                               (event) => {
                                   const ENTER_KEY = 13;
                                   if (event.charCode  === ENTER_KEY) {
                                       event.preventDefault();
                                       onSubmit();
                                   }}
                           }
                />
                <FontIcon onClick={onSubmit} className="material-icons">search</FontIcon>
            </div>
        )
    }
}

SearchField.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    onSubmit: PropTypes.func,
    label: PropTypes.string
};

export default SearchField;