import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import {fonts,colors} from "../styles/styleguide";

class CollapsibleGroup extends Component {

    constructor(props){
        super(props)

        this.state = {
            isOpen: props.isSearching === undefined ? false : !props.isSearching
        }
    }

    toggle = () => {
      this.setState({isOpen: !this.state.isOpen })
    }

    renderTitle = () => {
        const {title} = this.props;
        if(typeof title === 'string'){
            return(
                <Title>{title}</Title>
            )
        }if(typeof title === 'function'){
            return title();
        }

        return null;

    }

    componentWillReceiveProps(nextProps) {
      if (nextProps.isSearching !== undefined && nextProps.isSearching) {
        this.setState({isOpen: false})
      } else if (!!nextProps.shouldOpen && !this.state.isOpen) {
        this.setState({isOpen:true})
      }


    }

    componentDidUpdate(prevProps) {
      if (!!this.props.onOpen && this.props.isOpen && !prevProps.isOpen) {
        this.props.onOpen()
      }
    }

    render() {
        const {isOpen} = this.state;
        const { children, isSearching, disabled} = this.props;


        return (
            <div>
                <Header onClick={this.toggle}>
                    {this.renderTitle()}
                    {!disabled && (
                        <CollapseButton >
                            <img alt={"collapse"} src={isOpen ? "img/icons/arrow-up.svg" : "img/icons/arrow-down.svg"}/>
                        </CollapseButton>
                    )}
                </Header>
                <Body isOpen={isOpen}>
                    {children}
                </Body>
            </div>
        );
    }
}

CollapsibleGroup.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    defaultOpen: PropTypes.bool,
    disabled: PropTypes.bool,
    isSearching: PropTypes.bool
};

CollapsibleGroup.defaultProps = {
    defaultOpen: true,
    disabled: false,
    // isSearching: false
}

const Header = styled.div`
  background-color: #f9f9f9;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  margin: 0 -15px;
  cursor: pointer;
`

const Body = styled.div`
  height: ${props => props.isOpen ? 'auto' : '0'};
  visibility: ${props => props.isOpen ? 'visible' : 'hidden'};
  padding:  ${props => props.isOpen ? '30px 0px' : '0'};
`

const Title = styled.div`
  font-family: ${fonts.OpenSansHebrew};
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.33;
  letter-spacing: 0.72px;
  color: ${colors.charcoal};
`;

const CollapseButton = styled.div`
  cursor: pointer;

`

export default CollapsibleGroup;
