import React, {Component} from 'react';
import PropTypes from 'prop-types'
import styled from 'styled-components';
import TextField from 'material-ui/TextField';

class TextFieldWithButton extends Component{

    constructor() {
        super();
        this.state = {
            value: ""
        }
        this.submit = this.submit.bind(this)
    }

    submit(){
        const {buttonClickHandler, clearValueOnSubmit} = this.props;
        const {value} = this.state;
        buttonClickHandler(value);
        if(clearValueOnSubmit){
            this.setState({value: ""});
        }

    }


    render() {

        const {hintText, buttonLabel, errorText, disabled, buttonRenderer, underlineStyle,
            style} = this.props;
        const {value} = this.state;

        return (
            <Wrapper>
                <TextField
                    value={value}
                    hintText={hintText}
                    fullWidth={true}
                    onChange={(e, value)=>{this.setState({value})}}
                    errorText={errorText}
                    disabled={disabled}
                    onKeyPress={
                        (event) => {
                            const ENTER_KEY = 13;
                            if (event.charCode  === ENTER_KEY) {
                                event.preventDefault();
                                this.submit();
                            }}
                    }
                    underlineStyle={underlineStyle || null}
                    style={style || null}
                />
                <ButtonWrapper>
                    {
                        buttonLabel ? (<StyledButton onClick={this.submit} disabled={disabled}>{buttonLabel}</StyledButton>) :
                            buttonRenderer ? buttonRenderer(this.submit) : null
                    }
                </ButtonWrapper>
            </Wrapper>
        )
    }
}

const Wrapper = styled.div`
    position: relative;
`;

const ButtonWrapper = styled.div`
    position: absolute;
    left: 0;
    top: 0;
`;

const StyledButton = styled.button`
    color: #fff;
    font-size: 12px;
    background: #1492D9;
    border: none;
    outline: none;
    border-radius: 4px;
    padding: 10px;
`;

TextFieldWithButton.propTypes = {
    hintText: PropTypes.string,
    buttonLabel: PropTypes.string,
    errorText:  PropTypes.string,
    buttonClickHandler: PropTypes.func,
    disabled: PropTypes.bool,
    buttonRenderer: PropTypes.func,
    clearValueOnSubmit: PropTypes.bool,
    underlineStyle: PropTypes.object,
    style: PropTypes.object,
};

TextFieldWithButton.defaultProps = {
    disabled: false,
    clearValueOnSubmit: true
}

export default TextFieldWithButton;