import React from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import SwitchComponent from "react-switch";

import {colors, fonts} from "../../styles/styleguide";

const Switch = ({checked, onChange, label}) => {
    return (
        <SwitchWrapper checked={checked}>
            <Label htmlFor="small-radius-switch">
                <SwitchComponent
                    checked={checked}
                    onChange={onChange}
                    handleDiameter={28}
                    offColor={"#e3e2e4"}
                    onColor={"#FECD08CC"}
                    offHandleColor={"#fff"}
                    onHandleColor={"#fff"}
                    height={40}
                    width={70}
                    className="react-switch"
                    id="small-radius-switch"
                    uncheckedIcon={false}
                    checkedIcon={false}
                />
                <LabelText>{label}</LabelText>
            </Label>
        </SwitchWrapper>

    );
};

const SwitchWrapper = styled.div`
  
/*  .react-switch{
      .react-switch-handle::after{
         content: ${props => props.checked ? "⦿" : "•"};
         color: ${props => props.checked ? colors.yellow : "#e3e2e4"};;        
      }
  }*/
`

const Label = styled.label`
    display: flex;
    align-items: center;
`


const LabelText = styled.span`
  font-family: ${fonts.OpenSansHebrew};
  font-size: 21px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: ${colors.charcoal};
  margin: 0 10px;
`

export default Switch;