import React from 'react';
import PropTypes from 'prop-types'
import {injectIntl, FormattedMessage, formatMessage} from 'react-intl'
import styled from 'styled-components';

import IconButton from 'material-ui/IconButton';
import FontIcon from 'material-ui/FontIcon';
import RaisedButton from 'material-ui/RaisedButton';

import TextField from 'material-ui/TextField';

import {TabHeader} from "./Tab"

import List from "./List";
import { fonts } from "../../styles/styleguide";

const style = {
    raisedButton: {
        marginLeft: 10
    },
    iconButton:{
        marginLeft: 10,
    },
    iconGray:{
        color: "#707070"
    }
}

class Tabs extends React.Component {

    constructor() {
        super();
        this.state = {
            fieldNameToAdd: "טאב חדש",
            groupNameToAdd: {},
            selectedGroup: null
        }

        this.setFieldNameToAdd = this.setFieldNameToAdd.bind(this);
        this.setGroupNameToAdd = this.setGroupNameToAdd.bind(this);
        this.setActiveGroup = this.setActiveGroup.bind(this);
    }

    componentDidMount(){
       this.initTabsHeader(this.props)
    }

    componentWillReceiveProps(nextProps){
        this.initTabsHeader(nextProps);
    }

    initTabsHeader(props) {
        if (!this.state.selectedGroup && props.items && props.items.length > 0) {
            this.setActiveGroup(props.items[0].key)
        }
    }

    setActiveGroup(groupKey){
        console.log("setting active group", groupKey)
        this.setState({
            selectedGroup: groupKey
        })
    }

    setFieldNameToAdd(value) {
        this.setState({fieldNameToAdd: value});
    }

    setGroupNameToAdd(value, groupKey) {
        const {groupNameToAdd} = this.state;
        groupNameToAdd[groupKey] = value;
        this.setState({groupNameToAdd: groupNameToAdd});
    }

    onAddItem() {
        console.log("on add item");
        const {onAddItem, isSubGroups} = this.props;
        const {fieldNameToAdd} = this.state;
        if(isSubGroups && !fieldNameToAdd){
            return;
        }
        onAddItem(fieldNameToAdd, isSubGroups);
        //this.setFieldNameToAdd("");
    }

    onAddGroupItem(subGroupKey){
        const {onAddSubGroupItem} = this.props;
        onAddSubGroupItem("", subGroupKey);
    }

    render() {

        const {mainInputLabel, title, isSubGroups, intl, items, onRemoveItem, onItemChange,removeSubGroupItem, isEditable, mainInputPlaceholder, disabled,onSubGroupItemChange,
            primaryButtonLabel, onEditItem , addTabLabel, viewOnly,
            addFieldLabel} = this.props;
        const {selectedGroup} = this.state;

        return (
            <Group>
                <TabsHeader>
                    <div style={{display: "flex", flexDirection: "row"}}>
                        {items.map((group)=>(
                            <TabHeader
                                isActive={selectedGroup === group.key}
                                label={group.name}
                                onSelect={()=>{this.setActiveGroup(group.key)}}
                                onLabelChange={(value)=>{onEditItem(group.key, value)}}
                                onDelete={()=>{
                                    if(!disabled){
                                        this.setState({selectedGroup: null});
                                        onRemoveItem(group.key)
                                    }
                                }}
                                viewOnly={viewOnly}
                            />
                        ))}
                    </div>

                    {!viewOnly && (
                        <AddButton
                            onClick={()=>{
                                if(!disabled){
                                    this.onAddItem()
                                }
                            }}
                        >
                            <Img src={"img/icons/add_black.svg"}/>
                            <Label>{addTabLabel}</Label>
                        </AddButton>
                    )}
                </TabsHeader>
                {
                    items.map((group)=>(
                        <TabBody isActive={selectedGroup === group.key}>
                            <div>
                                <List
                                    items={group.value}
                                    disabled={disabled}
                                    onAddItem={()=>{
                                        this.onAddGroupItem(group.key)}
                                    }
                                    onRemoveItem={(item) => {
                                        isSubGroups ? removeSubGroupItem(item.key, group.key) : onRemoveItem(item.key)
                                    }}
                                    onItemChange={(item, value)=>{isSubGroups ? onSubGroupItemChange(item.key, group.key, value) : onItemChange(item.key, value)}}
                                    itemLabelKey={"value"}
                                    inputLabelText={"הוסף שורה כאן..."}
                                    addButtonLabel={addFieldLabel || intl.formatMessage({id: "product.form.group.addFieldButton"})}
                                />
                            </div>
                        </TabBody>
                    ))
                }

            </Group>
        )
    }
}

const AddButton = styled.div`
  cursor: pointer;
   display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-right: 10px;
  padding-left: 10px;
`

const Img = styled.img`
  width: 29px;
  height: 29px;
  object-fit: contain;
`;

const Label = styled.div`
  font-family: ${fonts.OpenSansHebrew};
  font-size: 21px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 1px;
  margin-right: 5px;
`;
const TabsHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const TabBody  = styled.div`
    display: flex;
    display: ${props => props.isActive ? 'block' : 'none'};
    margin-top: 10px;
    padding: 10px;
`;

const Group = styled.div`
    margin-top: 10px;
    padding: 10px;

`;

const Field = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

Tabs.propTypes = {
    title: PropTypes.string,
    onAddItem: PropTypes.func,
    onRemoveItem: PropTypes.func,
    onItemChange: PropTypes.func,
    onEditItem: PropTypes.func,
    items: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.any,
        name: PropTypes.string,
        value: PropTypes.string
    })),
    isEditable: PropTypes.bool,
    mainInputLabel: PropTypes.string,
    mainInputPlaceholder: PropTypes.string,
    isSubGroups: PropTypes.bool,
    onAddSubGroupItem: PropTypes.func,
    onSubGroupItemChange: PropTypes.func,
    removeSubGroupItem: PropTypes.func,
    primaryButtonLabel: PropTypes.string,
    disabled: PropTypes.bool,
    addTabLabel: PropTypes.string,
    addFieldLabel: PropTypes.string,
    viewOnly: PropTypes.bool
};

Tabs.defaultProps = {
    isEditable: true,
    isSubGroups: false,
    disabled: false,
    addTabLabel: "הוספת טאב",
    addFieldLabel: "הוספת שדה",
    viewOnly: false
};

export default injectIntl(Tabs);
