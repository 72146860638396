import React, { useEffect, useMemo, useRef, useState } from "react"
import readXlsxFile from "read-excel-file"
// import XLSX from 'xlsx';
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import styled from "styled-components";
import Select from "react-select"
import FontIcon from "material-ui/FontIcon";
import parse from "csv-parse/lib/sync"
// import assert from "assert"

import LoadingSpinner from "../../components/Loader";
import { colors } from "../../styles/styleguide";
import ConfirmButton from "../../components/ConfirmButton";
import { host } from "shared/src/constants/config";

// const TEST_DATA = [
// 	[ 'מק"ט', "שם מוצר", "מחיר", "ברקוד" ],
// 	[ ".131303", "טונר שחור 2820/115 samsung", null, null ],
// 	[ "000001", "אקטזיים מונע סתימות 100 גרם", 80, null ],
// 	[ "000002", "אקטיזיים מונע ופותח סתימות 450 גרם", 189, null ],
// 	[ "0001", "משקוף 80/12 שמאל עץ אורן", 179, null ],
// 	[ "0002", "כנף לבן בונסאי 74 שמואל", 339, null ],
// 	[ "0003", "הלבשה 30ישרה אורן fj", 15, null ],
// 	[ "00033", "ממטרון /עמדה לנוי70*360", 7, null ],
// 	[ "0003706411020", "מגן ברכיים עם סקוטש אמריקאי", 115, null ],
// 	[ "00038", "סוללה V3 ליתיום CR123אנרגייזר", 20, "8888021300086" ],
// 	[ "0004", "ידית אלום טבעי 236+שלט מפתח", 39, null ],
// 	[ "0005", "לוח חסין אש 12.5/120/260", 54, null ],
// 	[ "0006", "לוח גבס ירוק עמיד מים 12.5/120/260", 54, null ],
// 	[ "0007", "הובלה", 250, null ]
// ]

// const ALLOWED_FILE_TYPES = [
// 	"application/excel",
// 	"application/vnd.ms-excel",
// 	"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
// 	"application/vnd.openxmlformats-officedocument.spreadsheetml.template",
// ]
//
// const musts = [
// 	"catalogId",
// 	"name",
// 	"default_price"
// ]
//
// const options = [
// 	{ value: "catalogId", label: 'מק"ט' },
// 	{ value: "name", label: 'שם' },
// 	{ value: "default_price", label: 'מחיר' },
// 	{ value: "barcode", label: 'ברקוד' },
// 	{ value: "description", label: 'תיאור' },
// 	{ value: "packagingInfo", label: 'מארז' },
// ]

const MAX_ROWS_TO_RENDER = 10

const CatalogUploader = ( { accountToken, supplierId } ) => {
	const [ properties, setProperties ] = useState()

	const acceptFileTypes = useMemo(() => {
			let data = null

			if (!!properties && !!properties.allowedFileTypes && !!properties.allowedFileTypes.length) {
				data = ".csv"

				for (const type of properties.allowedFileTypes) {
					data = data.concat(", ", type)
				}
			}

			return data
		}, [ !!properties && properties.allowedFileTypes ]
	)

	useEffect(() => {
		// console.log("accountToken", accountToken)

		if (!!accountToken && !isLoading && !properties) {
			setIsLoading(true)

			getCatalogProperties({ accountToken }).then(response => {
				setProperties(response)
				setIsLoading(false)
			})
		}
	}, [ accountToken ])

	const inputRef = useRef()
	const [ selectedFile, setSelectedFile ] = useState()


	const isCsvFile = useMemo(
		() => !!selectedFile && selectedFile.name.includes(".csv"),
		[ selectedFile && selectedFile.name ]
	)

	const isValidType = useMemo(
		() =>
			!!selectedFile
		// && !!properties
		// && !!properties.allowedFileTypes
		// && (
		// 	properties.allowedFileTypes.includes(selectedFile.type) || isCsvFile
		// )
		, [ selectedFile ]
	);

	const [ isHeaders, setIsHeaders ] = useState(true)
	const [ table, setTable ] = useState()

	const [ data, setData ] = useState({
		headers: [],
		data: []
	})

	useEffect(() => {
		if (!!selectedFile && isValidType) {
			setIsLoading(true)
			setIsModalOpen(true)

			// console.log("isCsvFile", isCsvFile)

			if (isCsvFile) {
				const reader = new FileReader();

				reader.onload = function () {
					const records = parse(reader.result, {
						skip_empty_lines: true,
					})

					console.log("records", records)

					setTable(records)
				};

				// start reading the file. When it is done, calls the onload event defined above.
				reader.readAsText(selectedFile);
			} else {
				setTimeout(() => {
					readXlsxFile(selectedFile).then(setTable)
					// setTable(TEST_DATA)
				}, 500)
			}
		}
	}, [ selectedFile ])

	const [ isLoading, setIsLoading ] = useState(false)
	const [ isModalOpen, setIsModalOpen ] = useState(false)

	const [ isSuccessful, setIsSuccessful ] = useState(false)

	// useEffect(() => {
	// 	if (isSuccessful) {
	// 		resetData()
	// 	}
	// }, [ isSuccessful ])

	useEffect(() => {
		if (!!table) {
			const startIndex = isHeaders ? 1 : 0
			const newData = table.slice(startIndex, table.length)

			setData({
				headers: newData[ 0 ].map(() => ""),
				data: newData
			})

			setIsLoading(false)
		}
	}, [ table, isHeaders ]);

	const resetData = () => {
		setIsLoading(true)

		setSelectedFile()
		setIsHeaders(true)
		setTable()
		setData({ headers: [], data: [] })

		inputRef.current.value = ""

		setIsSuccessful(false)
		setIsModalOpen(false)
		setIsLoading(false)
	}

	const onSubmit = () => {
		setIsLoading(true)

		console.log("onSubmit")
		console.log("data", data)

		// FILTER SELECTED
		const selectedData = data.data.map(( row, rowIndex ) =>
			row.filter(( value, columnIndex ) => !!data.headers[ columnIndex ])
		)

		// VALIDATE SELECTED
		console.log("selectedData", selectedData)

		postCsv({
			accountToken,
			supplierId,
			keys: data.headers.filter(value => !!value),
			table: data.data
		})
			.then(response => {
				console.log("response", response)

				setIsSuccessful(true)
				setIsLoading(false)

				// resetData()
			})
			.catch(error => {
				console.error("error", error)
			})

		// setIsLoading(false)
		// setIsSuccessful(true)

		// resetData()
	}

	return (
		<FlexDiv>
			<FlexedDiv
				style={ {
					minWidth: "100px",
					// justifyContent: "center",
					alignItems: "center"
				} }
			>
				<div
					style={ {
						// display: "flex",
						// height: "100%",
						// width: "50px",
						fontWeight: "bold",
						padding: "8px 16px",
						margin: "4px",
						// borderWidth: "1px",
						backgroundColor: colors.yellow,
						cursor: "pointer",
						textAlign: "center"
					} }
					onClick={ () => inputRef.current && inputRef.current.click() }
				>
					{ "טעינת קטלוג" }
				</div>

				{
					// !!selectedFile && !isValidType && (
					// 	<FlexDiv>
					// 		<div>
					// 			{ selectedFile.name }
					// 		</div>
					// 		<div style={ { margin: "4px" } }/>
					// 		<div style={ { fontWeight: "bold", color: "red" } }>
					// 			{ "קובץ לא תקין - רק קבצי אקסל או csv" }
					// 		</div>
					// 	</FlexDiv>
					// )
				}

				{ !!acceptFileTypes && (
					<input
						style={ { width: "0px", visibility: "hidden" } }
						ref={ ref => {
							inputRef.current = ref
						} }
						accept={ acceptFileTypes }
						type="file"
						onChange={ event => {
							const file = event.target.files[ 0 ]

							setSelectedFile(file)
						} }
					/>
				) }
			</FlexedDiv>

			<Modal
				isOpen={ isModalOpen }
				size={ "xl" }
				scrollable={ true }
			>
				<ModalHeader>
					<FlexedDiv style={ { padding: "8px", alignItems: "center" } }>
						<FlexedDiv style={ { flexDirection: "column" } }>
							<FlexedDiv style={ { alignItems: "center" } }>
								<span style={ { fontWeight: "bold" } }>{ "טעינת קטלוג" }</span>

								{ !!selectedFile && (
									<FlexDiv>
										<span style={ { padding: "0 8px" } }>{ "-" }</span>
										<span>{ selectedFile.name }</span>
									</FlexDiv>
								) }
							</FlexedDiv>

							{ !isLoading && !isSuccessful && (
								<FlexedDiv style={ { alignItems: "center", marginTop: "4px" } }>
									<span>{ "האם יש כותרות בטבלה?" }</span>
									<div style={ { margin: "4px" } }/>

									<input
										type={ "checkbox" }
										checked={ isHeaders }
										onChange={ () => {
											setIsHeaders(state => !state)
										} }
									/>
								</FlexedDiv>
							) }
						</FlexedDiv>

						<ModalButtonWrapper onClick={ resetData }>
							<FontIcon
								color={ colors.charcoal }
								className={ "material-icons" }
							>
								close
							</FontIcon>
						</ModalButtonWrapper>
					</FlexedDiv>
				</ModalHeader>

				<ModalBody style={ { flex: 1 } }>
					{ isLoading || !data || !data.data
						? (
							<LoadingSpinner/>
						) : isSuccessful
							? (
								<FlexedDiv>
									{ "ההעלאת הקטלוג התחילה - עדכונים ישלחו למייל/טלפון איתו נרשמת" }
								</FlexedDiv>
							) : (
								<FlexedDiv style={ { flexDirection: isHeaders ? "row" : "column-reverse" } }>
									<FlexDiv
										style={
											isHeaders
												? {
													width: "30%",
													flexDirection: "column",
													padding: "4px 0"
												} : {
													flex: 1,
													flexDirection: "column",
													padding: "0 4px"
												}
										}
									>
										{ isHeaders
											? isLoading || !table
												? <LoadingSpinner/>
												: table[ 0 ].map(cellValue => (
													<CellValue>
														{ cellValue }
													</CellValue>
												))
											: data.data
											      .slice(0, MAX_ROWS_TO_RENDER)
											      .map(row => (
												      <FlexedDiv style={ { alignItems: "center" } }>
													      { row.map(cellValue => (
														      <CellValue>
															      { cellValue }
														      </CellValue>
													      )) }
												      </FlexedDiv>
											      ))
										}
									</FlexDiv>
									<FlexDiv
										style={
											isHeaders
												? {
													flexDirection: "column",
													width: "50%"
												} : {
													flexDirection: "row",
													width: "100%"
												}
										}
									>
										{ data.headers.map(( value, valueIndex ) => (
											<FlexedDiv>
												<Select
													// className="basic-single"
													// classNamePrefix="select"
													styles={ {
														container: ( provided, state ) => (
															{
																...provided,
																width: "100%",
																// margin: 0,
																// padding: 0
															}
														),
														input: ( provided, state ) => (
															{
																...provided,
																// flex: 1,
																fontWeight: "bold"
															}
														)
													} }
													value={
														!!properties && properties.options.reduce(( acc, current ) =>
																current.value === value
																	? current
																	: acc
															, undefined
														)
													}
													options={
														!!properties && properties.options.filter(option =>
															option.value === value
															|| !data.headers.includes(option.value)
														)
													}
													onChange={ ( selected, action ) => {
														setData(state => (
															{
																...state,
																headers: state.headers.map(( header, headerIndex ) =>
																	headerIndex === valueIndex
																		? !!selected ? selected.value : ""
																		: header
																)
															}
														))
													} }
													isClearable={ true }
													isRtl={ true }
													isSearchable={ true }
													name={ valueIndex }
												/>
											</FlexedDiv>
										)) }
									</FlexDiv>
								</FlexedDiv>
							)
					}
				</ModalBody>

				<ModalFooter>
					{ !isLoading && (
						<FlexedDiv style={ { fontWeight: "bold" } }>
							{ !isSuccessful && (
								<FlexDiv>
									<span style={ { padding: "0 0 0 8px" } }>
										{ "חובה לציין:" }
									</span>

									{
										!!properties && properties.musts.map(( value, index ) =>
											properties.options.reduce(( acc, current ) =>
													current.value === value
														? "".concat(
														index === 0 || index === properties.musts.length - 1 ? "" : ",",
														" ",
														index === properties.musts.length - 1 ? "ו" : "",
														current.label
														)
														: acc
												, ""
											)
										)
									}
								</FlexDiv>
							) }

							<ModalButtonWrapper>
								{
									isSuccessful
										? (
											<ConfirmButton
												disabled={ false }
												onClick={ resetData }
												label={ "סיום" }
												backgroundColor={ colors.charcoal }
											/>
										) : (
											<ConfirmButton
												disabled={
													!properties || data.headers.reduce(( acc, current ) =>
															properties.musts.includes(current)
																? acc + 1
																: acc
														, 0
													) !== properties.musts.length
												}
												onClick={ onSubmit }
												label={ "שלח" }
												backgroundColor={ colors.yellow }
											/>
										)
								}
							</ModalButtonWrapper>
						</FlexedDiv>
					) }
				</ModalFooter>
			</Modal>
		</FlexDiv>
	)
}

const fetchHeaders = ( accountToken ) => (
	{
		"Content-Type": "application/json",
		// 'Content-Type': csvFile.type,
		"Authorization": `Bearer ${ accountToken }`,
	}
)

const validateResponseKeys = ( { response, keys } ) => {
	console.log("validateResponseKeys")
	console.log("response", response)
	console.log("keys", keys)

	if (!!response && !!keys && !!keys.length) {
		for (const key of keys) {
			// console.log("key", key)

			if (!response[ key ] || !response[ key ].length) {
				const message = `validateResponseKeys error: missing data ${ key }`
				throw new Error(message)
			}
		}
	} else {
		const message = "validateResponseKeys error: response or keys are empty"
		// console.error("validateResponseKeys error", message)
		throw new Error(message)
	}
}

const getCatalogProperties = ( { accountToken } ) => {
	const url = `${ host }api/v1/catalogProperties`

	return fetch(url, {
		method: "GET",
		headers: fetchHeaders(accountToken),
	})
		.then(response => response.json())
		.then(response => {
			console.log("getCatalogProperties response", response)

			validateResponseKeys({ response, keys: [ "allowedFileTypes", "musts", "options" ] })

			return response
		})
		.catch(err => {
			console.error("getCatalogProperties err", err)
		})
}

const postCsv = ( { accountToken, supplierId, keys, table } ) => {
	const url = `${ host }api/v1/uploadCatalog`
	const data = { supplierId, keys, table }

	// console.log("csvFile.type", csvFile.type)
	// console.log("JSON.stringify(data)", JSON.stringify(data))

	return fetch(url, {
		method: "POST",
		headers: fetchHeaders(accountToken),
		// body: data
		body: JSON.stringify(data)
	})
		.then(response => response.json())
	// .then(response => {
	// 	console.log("response", response)
	// })
	// .catch(error => {
	// 	console.error("error", error)
	// })
}

// const postCsv = ( { accountToken, supplierId, csvFile, csvMapping } ) => {
// 	const url = `${ host }api/v1/uploadCatalog`
// 	const data = { supplierId, csvFile: csvFile.stream(), csvMapping }
//
// 	// console.log("csvFile.type", csvFile.type)
// 	// console.log("JSON.stringify(data)", JSON.stringify(data))
//
// 	return fetch(url, {
// 		method: "POST",
// 		headers: {
// 			'Content-Type': 'application/json',
// 			// 'Content-Type': csvFile.type,
// 			'Authorization': `Bearer ${ accountToken }`,
// 		},
// 		// body: data
// 		body: JSON.stringify(data)
// 	})
// 		.then(response => response.json())
// 		.then(response => {
// 			console.log("response", response)
// 		})
// 		.catch(error => {
// 			console.error("error", error)
// 		})
// }

const FlexDiv = styled.div`
  display: flex;
`

const FlexedDiv = styled(FlexDiv)`
  flex: 1;
`

const CellValue = styled(FlexedDiv)`
  height: 100%;
  padding: 2px;
  border-width: 1px;
  border-color: ${ colors.charcoal };
  font-weight: ${ ( { isBold = false } ) => isBold ? "bold" : "normal" };
`

const ModalButtonWrapper = styled.div`
  position: absolute;
  //top: 0;
  left: 8px;
  align-self: center;
  padding: 8px;
  cursor: pointer;
`;

export default CatalogUploader