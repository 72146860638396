import React, {Component} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ProductSearch, {Product} from "./ProductSearch";
import {noProductThumb} from "shared/src/constants/config";

class RelatedProducts extends Component{

    constructor() {
        super()
    }

    render() {

        const {
            suppliers, onSearch, onMakatSearch, results, onClear,
            onResultSelection, isLoading, relatedProducts,onRemoveProduct, label, singleSupplier
        } = this.props;

        return (
            <Container>
                <TitleWrapper>
                    {label}
                </TitleWrapper>
                <ProductSearch
                    isLoading={isLoading}
                    suppliers={suppliers}
                    singleSupplier={singleSupplier}
                    results={results}
                    onSearch={onSearch}
                    onMakatSearch={onMakatSearch}
                    onResultSelection={onResultSelection}
                    onClear={onClear}
                />
                <SelectedProducts>
                    {
                        relatedProducts.map((p)=> (
                            <ProductWrapper>
                                <Product
                                    key={ p.key }
                                    img={ p.images && p.images.length ? p.images[ 0 ].downloadURL : noProductThumb }
                                    label={ p.name }
                                    catalogId={ p.catalogId }
                                    supplier={ suppliers[ p.supplierId ].name }
                                />
                                <ProductDeleteButton onClick={ () => onRemoveProduct(p.key) }>
                                    X
                                </ProductDeleteButton>
                            </ProductWrapper>

                        ))
                    }
                </SelectedProducts>
            </Container>
        )
    }
}

const Container = styled.div`
    background: #fff;
    border-radius: 6px;
    padding: 15px;
    margin-top: 17px;
    
`

const TitleWrapper = styled.div`
    font-size: 16px;
    font-weight: bold;
    color: #404040;
    padding-bottom: 5px;
    border-bottom: 1px solid #E4E5E6;
    margin-bottom: 20px;
`;



const SelectedProducts = styled.div`

`;

const ProductWrapper = styled.div`
    position: relative;
`;

const ProductDeleteButton = styled.div`
    position: absolute;
    left: 0;
    top: 33%;
    cursor: pointer;
    border-radius: 50%;
    background-color: #DFD8D8;
    height: 24px;
    width: 24px;
    color: #fff;
    font-size: 16px;
    text-align: center;
    vertical-align: middle;
    
`



RelatedProducts.propTypes = {
    suppliers: PropTypes.array,
    singleSupplier: PropTypes.string, // supplierId
    onSearch: PropTypes.func,
    onMakatSearch: PropTypes.func,
    results: PropTypes.array,
    onClear: PropTypes.func,
    onResultSelection: PropTypes.func,
    isLoading: PropTypes.bool,
    relatedProducts: PropTypes.array,
    onRemoveProduct: PropTypes.func,
    label: PropTypes.string
};

export default RelatedProducts;