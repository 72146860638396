import React, { Component, useMemo, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import moment from "moment";
import _, { cloneDeep, isEqual } from "lodash";
import { firebaseConnect, withFirestore } from "react-redux-firebase";
import { getContractorIds } from "shared/src/selectors/roles";
// import {injectIntl} from "react-intl";
import { Route, Switch } from 'react-router-dom';
import ROUTES from "../../constants/routes";
import styled from "styled-components";
import { colors, fonts } from "../../styles/styleguide";
import LoadingSpinner from "../../components/Loader";
import FirebaseObjectListSelect from "../../components/Form/FirebaseObjectListSelect";
// import { getOrdersHistory } from "shared/src/api/orders"
import { getOrderById } from "shared/src/api/ordersHistory"

import { Col, Modal, ModalBody, ModalHeader, Row, } from "reactstrap";
import { CardTitle, InputLabel } from "../../styles/stylesheet";
import { setFieldValue, resetCurrentOrder } from "shared/src/modules/orders";
import OrderForm from "../History/orderForm";
import { ORDER_MANAGEMENT_CONTEXT } from "shared/src/constants/orders";
import { getContractors } from "shared/src/api/contractors";

// import { OrderSummaryLabels, TotalPriceSupplier } from "../OrdersManagement/orderForm";

class InvoiceReport extends Component {
	constructor() {
		super();
		this.state = {
			report: [],
			isLoadingReport: false,
			showContractorSelection: false,
			selectedContractor: null,
			diff: null,
			isLoadingDiff: false,
			isOpenDiff: false,
			queryInvoiceId: null,
			// contractorId: null,
			// supplierId: null,
		};
	}

	componentDidMount() {
		const { contractorIds, contractors, resetCurrentOrder } = this.props;
		resetCurrentOrder()

		this.initScreen(contractorIds);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.isApplicationOrdersAdmin !== prevProps.isApplicationOrdersAdmin || !isEqual(this.props.contractorIds, prevProps.contractorIds) || !isEqual(this.props.contractors, prevProps.contractors)) {
			this.initContractorsList();
		}
	}

	componentDidUpdate( prevProps, prevState, snapshot ) {
		const { contractorIds, contractors } = this.props;

		if (!_.isEqual(prevProps.contractorIds, contractorIds)) {
			this.initScreen(contractorIds);
		}

		if (this.props.isApplicationOrdersAdmin !== prevProps.isApplicationOrdersAdmin || !isEqual(this.props.contractorIds, prevProps.contractorIds) || !isEqual(this.props.contractors, prevProps.contractors)) {
			this.initContractorsList();
		}
	}

	initScreen = ( contractorIds ) => {
		let contractorIdsArr = _.get(contractorIds, "contractorIds", []);
		// console.log("contractorIdsArr", contractorIdsArr, contractorIds);
		if (this.props.isApplicationOrdersAdmin || contractorIdsArr.length > 1) {
			this.setState({ showContractorSelection: true });
		} else {
			if (contractorIdsArr[ 0 ]) {
				this.fetchScreenData(contractorIdsArr[ 0 ]);
			}
			this.setState({ showContractorSelection: false });
		}
	};

	fetchScreenData = async ( contractorId ) => {
		const { firestore } = this.props;
		// default date - 2 months ago as unix timestamp
		const date = moment().subtract(2, "months").valueOf();
		// console.log("fetch report for", contractorId, date);
		try {
			this.setState({
				isLoadingReport: true,
			});
			const now = Date.now();
			// console.log("Firestore: ", now);
			let snapshot = await firestore
				.collection(`invoicesByContractor/${ contractorId }/invoices`)
				.where("invoiceData.date", ">=", date)
				.orderBy("invoiceData.date", "desc")
				.get();

			let list = [];
			// let scs = {}
			snapshot.forEach(( doc ) =>
				list.push({ invoice: doc.data().invoiceData, ref: doc.ref })
			);
			// console.log("data", Date.now() - now, list);
			this.setState({
				isLoadingReport: false,
				report: list,
			});
		} catch (e) {
			console.error("error fetchScreenData", e);
			this.setState({
				isLoadingReport: false,
			});
		}
	};

	fetchSCDiff = ( scId, itemRef ) => {
		// const {firestore} = this.props;
		let diff = null;
		this.setState({
			isLoadingDiff: true,
		});
		itemRef
			.collection("invoiceDocs")
			.doc(scId.toString())
			.get()
			.then(( querySnapshot ) => {
				if (querySnapshot.exists) {
					diff = querySnapshot.data().doc;
					diff.itemsDiff = this.normalizeDoc(querySnapshot.data().doc);
				}
				// console.log("DIFF: ", scId, diff);
				this.setState({ isLoadingDiff: false, diff });
			});
	};

	normalizeDoc = ( diff ) => {
		const itemsDiff = {};

		if (diff.doc && diff.doc.items) {
			diff.doc.items.forEach(( i, index ) => {
				if (itemsDiff[ i.part ]) {
					// already exists
					if (!itemsDiff[ i.part ].docList) {
						itemsDiff[ i.part ].docList = [ cloneDeep(itemsDiff[i.part].docData) ]
					}
				} else {
					itemsDiff[ i.part ] = {}
				}
				itemsDiff[ i.part ].docData = {
					catalogId: i.part,
					name: i.partDes,
					price: i.price,
					discount: i.DiscountPrc,
					count: i.quant,
					index
				}
				itemsDiff[ i.part ].docDiff = this.compare(null, null)
				itemsDiff[ i.part ].docExists = 1

				if (itemsDiff[ i.part ].docList) {
					itemsDiff[ i.part ].docList.push(cloneDeep(itemsDiff[i.part].docData))
				}
			});
		}


		if (diff.punctDoc && diff.punctDoc.items) {
			diff.punctDoc.items.forEach(( i, index ) => {
				// catalogId: "10104"
				// count: "2"
				// isGeneric: false
				// name: "שק ענק ריק חיוב"
				// price: 13.02
				// price_data: {spacialPrice: 13.02, original_price: 14.7}
				if (itemsDiff[ i.catalogId ]) {
					// find punct doc in list if exists
					if (itemsDiff[ i.catalogId ].docList) {
						let isMatched = false

						itemsDiff[ i.catalogId ].docList.forEach(tdoc => {
							if (!isMatched && Number(i.count) === tdoc.count) {
								itemsDiff[ i.catalogId ].docData = tdoc
								isMatched = true
							} else {
								if (itemsDiff[ i.catalogId ].otherDocs) {
									itemsDiff[ i.catalogId ].otherDocs.push(tdoc)
								} else {
									itemsDiff[ i.catalogId ].otherDocs = [ tdoc ]
								}
							}
						})
					}
					itemsDiff[ i.catalogId ].docDiff = this.compare(
						itemsDiff[ i.catalogId ].docData,
						i
					)
					itemsDiff[ i.catalogId ].docPunct = this.getPrice(i)
					itemsDiff[ i.catalogId ].docExists = 0;
				} else {
					itemsDiff[ i.catalogId ] = {
						docDiff: this.compare(null, i),
						docExists: 2,
					}
				}
				itemsDiff[ i.catalogId ].docPunct = this.getPrice(i)
			})
		}
		const toReturn = _.orderBy(Object.values(itemsDiff), [ "catalogId" ]);
		return this.spreadIfNeed(toReturn)
	};

	spreadIfNeed = ( list ) => {
		const toReturn = []
		list.forEach(item => {
			if (!item.docList) {
				return toReturn.push(item)
			}
			if (!!item.otherDocs) {
				if (item.otherDocs.length === item.docList.length) {
					item.otherDocs.pop()
				}
				toReturn.push(item)
				item.otherDocs.forEach(oitem => {
					// console.log("OtherItem: ",oitem)
					toReturn.push({
						docData: oitem,
						docDiff: this.compare(null, null),
						docExists: 1,
						docPunct: null
					})
				})
			}
		})

		return toReturn
	}

	compare = ( nItem, pItem ) => {
		const docDiff = {
			price: true,
			discount: true,
			count: true,
		};
		if (!nItem || !pItem) return docDiff;
		const { price, discount, count } = this.getPrice(pItem);
		docDiff.count = nItem.count !== count;
		docDiff.price = nItem.price !== price;
		docDiff.discount = nItem.discount !== discount;
		return docDiff;
	};

	getPrice = ( i ) => {
		let price = 0;
		let discount = 0;
		if (i.price_data && (
			!i.spacialPrice
		)) {
			if (i.price_data.spacialPrice || i.price_data.spacialPrice === 0) {
				price = i.price_data.spacialPrice;
			} else {
				price = i.price_data.original_price;
				discount = i.price_data.discount || 0;
			}
		} else {
			price = Number(i.price);
		}

		discount *= 100;
		return {
			price,
			discount,
			catalogId: i.catalogId,
			name: i.name,
			count: Number(i.count),
		};
	};

	onContractorSelection = ( contractorId ) => {
		//console.log("contractor selected", contractorId)
		// setting selected contractors to match the roles selector schema
		// will use it to fetch screen data
		const selectedContractor = {
			contractorIds: [ contractorId ],
			projectIds: [],
		};
		this.setState({
			selectedContractor: selectedContractor,
			// contractorIds: selectedContractor,
		});
		this.fetchScreenData(contractorId);
	};

	onSCSelection = ( scId, itemRef ) => {
		this.fetchSCDiff(scId, itemRef);
		this.setState({ isOpenDiff: true });
	};

	closeDiff = () => {
		this.setState({
			isOpenDiff: false,
			diff: null,
		});

		this.props.history.push(ROUTES.INVOICE_REPORT)
	};

	renderInvoices = () => {
		const { report, queryInvoiceId } = this.state;

		return (
			<div>
				<HeaderContainer
					style={ {
						justifyContent: "space-evenly",
						backgroundColor: colors.charcoal
					} }
				>
					<ColumnValue
						value={ "מס חשבונית" }
						width={ ColumnWidth.id }
						isBold={ false }
						color={ "#ffffff" }
					/>
					<ColumnValue
						value={ "תאריך" }
						width={ ColumnWidth.date }
						isBold={ false }
						color={ "#ffffff" }
					/>
					<ColumnValue
						value={ "הערות" }
						width={ ColumnWidth.remarks }
						isBold={ false }
						color={ "#ffffff" }
					/>
					<ColumnValue
						value={ "סכום חשבונית" }
						width={ ColumnWidth.totalSum }
						isBold={ false }
						color={ "#ffffff" }
					/>
					<ColumnValue
						value={ "סכום הזמנות" }
						width={ ColumnWidth.totalSum }
						isBold={ false }
						color={ "#ffffff" }
					/>
					<ColumnValue
						value={ "הפרש" }
						width={ ColumnWidth.totalSum }
						isBold={ false }
						color={ "#ffffff" }
					/>
					<ColumnValue
						value={ "תעודות משלוח" }
						width={ ColumnWidth.count }
						isBold={ false }
						color={ "#ffffff" }
					/>
					<ColumnValue
						value={ "" }
						width={ ColumnWidth.remarks }
					/>
					<ColumnValue
						value={ "" }
						width={ COLUMN_BABY_WIDTH }
					/>
				</HeaderContainer>

				{
					report.filter(
						itemData => itemData.invoice.invoiceId.toString().includes(queryInvoiceId || "")
					).map(( itemData ) => (
						<CollapsableInvoiceRow
							key={ itemData.invoice.invoiceId }
							itemData={ itemData }
							onSCSelection={ this.onSCSelection }
						/>
					))
				}
			</div>
		)
	};

	renderDiffModal = () => {
		const { getOrderById, isOrderLoading } = this.props
		const { diff, isLoadingDiff, isOpenDiff } = this.state;

		if (isLoadingDiff) {
			return <LoadingSpinner/>;
		}

		if (!diff) {
			return null;
		}

		return (
			<Modal
				isOpen={ isOpenDiff }
				toggle={ this.closeDiff }
				style={ { maxWidth: "67%" } }
				scrollable={ true }
			>
				<ModalHeader>
					<div style={ { display: "flex", flexDirection: "row" } }>
						{ "השוואת תעודת משלוח" }
						<div style={ { padding: "4px" } }/>
						<div style={
							!!diff.punctDoc
								? {
									color: "blue",
									fontWeight: "bold",
									cursor: "pointer"
								} : {
									fontWeight: "bold",
								}
						}
						     onClick={ () => {
							     const { firestore, firebase, history } = this.props;

							     const punctDoc = diff.punctDoc
							     if (!!punctDoc) {
								     const supplierId = punctDoc.supplierId
								     const contractorId = punctDoc.contractorId
								     const projectId = punctDoc.projectId
								     const orderId = punctDoc.orderId

								     getOrderById(firestore, firebase, { orderId, isSupplier: false })
									     .then(( value ) => {
										     history.push(`${ ROUTES.INVOICE_REPORT }/${ supplierId }/${ contractorId }/${ projectId }/${ orderId }`);
									     })

							     }
						     } }
						>
							{ diff.doc.DOCNUMBER }
						</div>
					</div>
					<div style={ { position: "absolute", left: "20px", top: "8px", cursor: "pointer" } }
					     onClick={ this.closeDiff }>
						<img src={ "img/icons/close_red.svg" }/>
					</div>
				</ModalHeader>

				<ModalBody style={ { backgroundColor: "#f5f5f5" } }>
					{ isOrderLoading
						? (
							<LoadingSpinner/>
						) : (
							<ListDiff
								items={ diff.itemsDiff }
								docsData={ {
									count: diff.doc.items.length,
									price: diff.doc.docTotal,
									sourceLabel: "חיוב בפועל",
								} }
								punctData={ !!diff.punctDoc && {
									count: diff.punctDoc.items.length,
									price: diff.punctTotal,
									// price: diff.punctDoc.docTotal,
									sourceLabel: "סיכום הזמנה",
								} }
							/>
						)
					}
				</ModalBody>

				{
					// <ModalFooter></ModalFooter>
				}
			</Modal>
		);
	};

	initContractorsList = () =>{
		const {firebase, user, isApplicationOrdersAdmin, context} = this.props
		let stateContractors = {};
		//   console.log('History mounted for: ', user)
		if(context === ORDER_MANAGEMENT_CONTEXT.CONTRACTOR){
			if(isApplicationOrdersAdmin){
				getContractors(firebase, user).then(contractors => {
					this.setState({contractors})
					console.log('History contractors: ', contractors)
					stateContractors = contractors;
				})
			}
			else {
				const {contractors} = this.props;
				if(!contractors){
					console.log("skipping initializing")
					return;
				}

				console.log("setting contractor contractors", this.props.contractorIds);
				this.props.contractorIds.contractorIds.map((id) => {
					stateContractors[id] = contractors[id];
				})
				this.setState({
					contractors: stateContractors
				})
			}
			const keys = Object.keys(stateContractors);
			if(keys.length === 1){
				this.onContractorSelection(keys[0]);
			}
		}

	}

	render() {
		const {
			      report,
			      isLoadingReport,
			      showContractorSelection,
			      selectedContractor,
		      } = this.state;

		const { contractorIds, contractors } = this.props;

		if (isLoadingReport) {
			return <LoadingSpinner/>;
		}

		// const filteredContractorsObject = {}
		//
		// if (!!contractors) {
		// 	for (const cId of contractorIds.contractorIds) {
		// 		filteredContractorsObject[ cId ] = contractors[ cId ]
		// 	}
		// }

		return (
			<div>
				<Row>
					{ (
						showContractorSelection || this.props.isApplicationOrdersAdmin
					) && (
						<Col md={ 4 }>
							<div
								style={ {
									display: "flex",
									flexDirection: "row",
									justifyContent: "flex-start",
									alignItems: "center",
									paddingTop: 10,
								} }
							>
								<div
									style={ {
										marginRight: 20,
										display: "flex",
										flexDirection: "row",
										justifyContent: "flex-start",
										alignItems: "flex-start",
									} }
								>
									<img
										style={ { maxHeight: 24 } }
										src={ "img/nav/nav_contractors_mgmt.svg" }
									/>
									<InputLabel style={ { marginRight: 10 } }>קבלן</InputLabel>
								</div>

								<FirebaseObjectListSelect
									// list={ filteredContractorsObject }
									list={contractors}
									onChange={ ( value ) => this.onContractorSelection(value) }
									hintText={ "בחרו קבלן" }
									useMaterialDesign={ false }
									value={
										selectedContractor
											? selectedContractor.contractorIds[ 0 ]
											: null
									}
									icon={ true }
									size={ "sm" }
								/>
							</div>
						</Col>
					) }
				</Row>
				<Row>
					<div style={ { margin: "8px" } }>
						<StyledInput
							// style={ {
							// 	width: "200px",
							// 	marginRight: 15,
							// 	marginTop: "8px",
							// 	marginBottom: "8px"
							// } }
							// type="number"
							value={ this.state.queryInvoiceId }
							placeholder={ "סנן לפי מס' חשבונית" }
							onChange={ ( e ) => {
								const text = e.target.value;

								if (!text && text === "") {
									this.setState({ queryInvoiceId: null })
								} else {
									this.setState({ queryInvoiceId: text })
								}
							} }
						/>
					</div>
				</Row>
				{ this.renderInvoices() }
				{ this.renderDiffModal() }
			</div>
		);
	}
}

const StyledInput = ( { value, onChange, placeholder } ) => (
	<input
		style={ {
			height: "40px",
			width: "300px",
			margin: "8px",
			borderRadius: "20px",
			border: "solid 1px #d1d1d1",
			backgroundColor: "#ffffff",
			textAlign: "center"
		} }
		type="number"
		value={ value }
		placeholder={ placeholder }
		onChange={ onChange }
	/>
)

const ListDiff = ( { items, docsData, punctData } ) => {
	const rows = items.map(( item ) => (
		<DiffRow key={ item.docData.catalogId }>
			<div style={ { flex: 1 } }>
				{ (
					item.docExists === 0 || item.docExists === 1
				) && (
					<ItemDiff docData={ item.docData } docDiff={ item.docDiff }/>
				) }
			</div>
			<div style={ { flex: 1 } }>
				{ (
					item.docExists === 0 || item.docExists === 2
				) && (
					<ItemDiff docData={ item.docPunct } docDiff={ item.docDiff }/>
				) }
			</div>
		</DiffRow>
	));

	const titlesDiff = {
		price: docsData.price !== punctData.price,
		count: docsData.count !== punctData.count,
	}

	return (
		<div>
			<Switch>
				<Route
					exact
					path={ ROUTES.INVOICE_REPORT }
					name={ "invoiceReport" }
					render={ props => (
						<div style={ { display: "flex", flexDirection: "column" } }>
							<DiffRow>
								<TitleDiff { ...docsData } titlesDiff={ titlesDiff }/>
								{ !!punctData
									? <TitleDiff { ...punctData } titlesDiff={ titlesDiff }/>
									: <div style={ { flex: 1 } }/>
								}
							</DiffRow>

							{ rows }
						</div>
					) }
				/>
				<Route
					path={ `${ ROUTES.INVOICE_REPORT }/:supplierId/:contractorId/:projectId/:orderId` }
					name="invoiceReport Form"
					render={ ( props ) => (
						<OrderForm  { ...props }/>
					) }/>
			</Switch>
		</div>
	);
};

const DiffRow = styled.div`
  display: flex;
  flex: 1;
  flexDirection: row;
`;

const Tile = styled.div`
    display: flex;
    width: 90%;
    margin: 4px;
    padding: 12px;
    border-radius: 10px;
`

const TitleDiff = ( { sourceLabel, count, price, titlesDiff } ) => (
	<div style={ { flex: 1 } }>
		<Tile
			style={ {
				flexDirection: "column",
				backgroundColor: "#efefef",
				boxShadow: "0 0 44.3px 9.7px rgba(0, 0, 0, 0.14)",
				border: `solid 2px ${ colors.yellow }`
			} }
		>
			<CardTitle
				style={ {
					alignSelf: "center",
					color: colors.charcoal
				} }
			>
				{ sourceLabel }
			</CardTitle>

			<div
				style={ {
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",
					alignItems: "center",
					paddingTop: "10px",
					paddingBottom: "10px",
				} }
			>
				<div
					style={ {
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
					} }
				>
					<OrderSummaryLabels style={ { marginLeft: "8px" } }>
						{ ` סה"כ` }
					</OrderSummaryLabels>

					<div
						style={ {
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							borderRadius: DIFF_BORDER_RADIUS,
							...getBackgroundStyle(titlesDiff.count)
						} }
					>
						<OrderSummaryLabels
							style={ {
								fontWeight: "bold",
								paddingRight: "8px",
								// paddingLeft: "8px"
							} }
						>
							<span dir={ "ltr" }>{ count }</span>
							<span>{ ` פריטים` }</span>
						</OrderSummaryLabels>

						{ !!titlesDiff.count && (
							<img src={ "img/icons/warning.png" }/>
						) }
					</div>
				</div>

				<OrderSummaryLabels>|</OrderSummaryLabels>

				<div
					style={ {
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
					} }
				>
					<OrderSummaryLabels style={ { marginLeft: "8px" } }>
						{ `מחיר` }
					</OrderSummaryLabels>

					<div
						style={ {
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							borderRadius: DIFF_BORDER_RADIUS,
							...getBackgroundStyle(titlesDiff.count)
						} }
					>
						<OrderSummaryLabels
							style={ {
								fontWeight: "bold",
								paddingRight: "8px",
								// paddingLeft: "8px",
								// borderRadius: DIFF_BORDER_RADIUS,
								// ...getBackgroundStyle(titlesDiff.price)
							} }
							dir={ "ltr" }
						>
							{ getFixedPrice(price) }
						</OrderSummaryLabels>

						{ !!titlesDiff.count && (
							<img src={ "img/icons/warning.png" }/>
						) }
					</div>
				</div>
			</div>
		</Tile>
	</div>
);

const DIFF_BORDER_RADIUS = "5px"

const ItemDiff = ( { docData, docDiff } ) => {
	const data = useMemo(() => [
		{
			key: "id",
			label: 'מק"ט',
			value: docData.catalogId,
			valueDir: "rtl",
			// isDiff: false,
		},
		{
			key: "name",
			label: "שם",
			value: docData.name || "",
			valueDir: "rtl",
			// isDiff: false,
		},
		{
			key: "count",
			label: "כמות",
			value: docData.count || "",
			valueDir: "ltr",
			isDiff: !!docDiff.count,
		},
		{
			key: "price",
			label: "מחיר",
			value: getFixedPrice(docData.price),
			valueDir: "ltr",
			isDiff: !!docDiff.price,
		},
		{
			key: "discount",
			label: "הנחה",
			value: docData.discount + "%",
			valueDir: "rtl",
			isDiff: !!docDiff.discount,
		},
	], [])

	return (
		<Tile
			style={ {
				flex: 1,
				flexDirection: "row",
				border: `solid 1px rgba(209, 209, 209, 0.45)`,
				backgroundColor: "#ffffff",
				boxShadow: "0px 8px 4px 0 rgba(213, 213, 213, 0.23)",
			} }
		>
			<TitlesColumn
				data={
					data.reduce(( acc, current ) =>
						acc.push({
							key: current.key,
							label: current.label,
							isDiff: !!current.isDiff
						}) && acc, []
					)
				}
			/>

			<ValuesColumn
				data={
					data.reduce(( acc, current ) =>
						acc.push({
							key: current.key,
							value: current.value,
							dir: current.valueDir,
							isDiff: !!current.isDiff
						}) && acc, []
					)
				}
			/>
		</Tile>
	)
};

const TitlesColumn = ( { data } ) => (
	<DetailsColumn style={ { paddingStart: 8 } }>
		{ data.map(row => (
			<RowDetails
				key={ row.key }
				isDiff={ row.isDiff }
				style={ {
					borderTopRightRadius: DIFF_BORDER_RADIUS,
					borderBottomRightRadius: DIFF_BORDER_RADIUS
				} }
			>
				<Label>{ row.label }</Label>
			</RowDetails>
		)) }
	</DetailsColumn>
)

const ValuesColumn = ( { data } ) => (
	<DetailsColumn style={ { flex: 1, paddingEnd: 8 } }>
		{ data.map(row => (
			<RowDetails
				key={ row.key }
				isDiff={ row.isDiff }
				style={ {
					borderTopLeftRadius: DIFF_BORDER_RADIUS,
					borderBottomLeftRadius: DIFF_BORDER_RADIUS
				} }
			>
				<Value style={ { flex: 1 } } dir={ row.dir }>{ row.value }</Value>

				{ row.isDiff && (
					<img src={ "img/icons/warning.png" }/>
				) }
			</RowDetails>
		)) }
	</DetailsColumn>
)

const DetailsColumn = styled.div`
    display: flex;
    flex-direction: column;
`;

const RowDetails = styled.div`
    display: flex;
    flex-direction: row;
    height: ${ props => props.isDiff ? 36 : 28 }px;
    justify-content: flex-start;
    align-items: center;
    background-color: ${ props => props.isDiff ? "#ef9a9a" : "#ffffff" }
    padding: 0 8px;
`

const getFixedPrice = ( value ) =>
	"₪" + " " + Number(value.toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

const getBackgroundStyle = ( isPaintRow ) => (
	{ backgroundColor: isPaintRow ? "#ef9a9a" : "transparent" }
)

const OrderSummaryLabels = styled.div`
  font-family: ${ fonts.OpenSansHebrew };
  font-size: 18px;
  color: ${ colors.charcoal };
`;

const DetailsRow = styled.div`
  display: flex;
  flex-direction: row;
  height: 20px;
  width: 100%;
`;

const Label = styled.div`
  font-family: ${ fonts.OpenSansHebrew };
  font-size: 16px;
  color: ${ colors.charcoal };
  margin-left: 12px;
`;

const Value = styled.div`
  font-family: ${ fonts.OpenSansHebrew };
  font-size: 16px;
  font-weight: bold;
  color: ${ colors.charcoal };
`;

// const DiffMessage = styled.div`
//   font-family: ${ fonts.OpenSansHebrew };
//   font-size: 16px;
//   color: ${ colors.red };
// `;

const CollapsableInvoiceRow = ( { itemData, onSCSelection } ) => {
	const [ isOpen, setIsOpen ] = useState(false)

	const invoiceData = itemData.invoice;

	return (
		<div
			style={ {
				display: "flex",
				flexDirection: "column",
				flex: 1,
				margin: "8px",
				borderRadius: DIFF_BORDER_RADIUS,
				boxShadow: "-17.3px 10px 20px 0 rgba(0, 0, 0, 0.1)"
			} }
		>
			<CollapsableHeader
				isOpen={ isOpen }
				toggle={ () => setIsOpen(state => !state) }
				invoiceData={ invoiceData }
			/>

			<CollapsableBody
				isOpen={ isOpen }
				docs={ invoiceData.docs }
				itemRef={ itemData.ref }
				onSCSelection={ onSCSelection }
			/>
		</div>
	)
}

const COLUMN_BABY_WIDTH = "50px"
const COLUMN_SMALL_WIDTH = "100px"
const COLUMN_MEDIUM_WIDTH = "150px"
const COLUMN_LARGE_WIDTH = "200px"
const COLUMN_EXTRA_WIDTH = "300px"

const ColumnWidth = {
	id: COLUMN_SMALL_WIDTH,
	date: COLUMN_SMALL_WIDTH,
	// remarks: COLUMN_LARGE_WIDTH,
	remarks: COLUMN_EXTRA_WIDTH,
	// invoiceTotal: COLUMN_MEDIUM_WIDTH,
	// punctTotal: COLUMN_MEDIUM_WIDTH,
	// diffTotal: COLUMN_MEDIUM_WIDTH,
	totalSum: COLUMN_MEDIUM_WIDTH,
	count: COLUMN_BABY_WIDTH,
	bodyStatus: COLUMN_BABY_WIDTH,
	bodyValue: COLUMN_LARGE_WIDTH,
	// diffDocCount: COLUMN_EXTRA_WIDTH,
}

const ColumnValue = ( {
	value,
	width,
	color = colors.charcoal,
	isBold = true
} ) => (
	<div
		style={ {
			width,
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			fontSize: "16px",
			fontWeight: isBold ? "bold" : "regular",
			textAlign: "center",
			color,
		} }
	>
		{ value }
	</div>
)

const HeaderContainer = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	border-radius: ${ DIFF_BORDER_RADIUS };
	padding: 12px 20px;
	align-items: center;
	box-shadow: -17.3px 10px 20px 0 rgba(0, 0, 0, 0.1)
`

const CollapsableBodyRow = styled.div`
	display: flex;
	flex-direction: row;
	border-radius: ${ DIFF_BORDER_RADIUS };
	padding: 12px 0;
	border: solid 1px rgba(209, 209, 209, 0.45);
`

const CollapsableBodyRowValue = styled.div`
	display: flex;
	flex: 1;
	flex-direction: row;
	padding: 0 20px;
	align-items: center;
`

const CollapsableHeader = ( { isOpen, toggle, invoiceData } ) => {
	const {
		      // isoTime,
		      // contractorId,
		      docDiffCount,
		      invoiceId,
		      // punctTotal,
		      total,
		      date,
		      remarks,
		      // supplierId,
		      docs,
	      } = invoiceData;

	const numberOfDocs = docs ? docs.length : 0;

	let punctTotal = 0
	!!docs && docs.forEach(item => {
		punctTotal += item.punctTotal
	})
	// punctTotal = punctTotal.toFixed(2)

	return (
		<HeaderContainer
			onClick={ toggle }
			style={ {
				// display: "flex",
				// flexDirection: "row",
				// width: "100%",
				cursor: "pointer",
				// borderRadius: DIFF_BORDER_RADIUS,
				// padding: "12px 20px",
				backgroundColor: "#ffffff",
				// alignItems: "center"
				marginBottom: isOpen ? "8px" : 0
			} }
		>
			<div
				style={ {
					flex: 1,
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "space-evenly"
				} }
			>
				<ColumnValue value={ invoiceId } width={ ColumnWidth.id }/>
				<ColumnValue value={ moment(date).format("D/M/YY") } width={ ColumnWidth.date } isBold={ false }/>
				<ColumnValue value={ remarks } width={ ColumnWidth.remarks } isBold={ false }/>
				<ColumnValue
					value={
						<span dir={ "ltr" }>
							{ getFixedPrice(total) }
						</span>
					}
					width={ ColumnWidth.totalSum }
				/>
				<ColumnValue
					value={
						<span dir={ "ltr" }>
							{ getFixedPrice(punctTotal) }
						</span>
					}
					width={ ColumnWidth.totalSum }
				/>
				<ColumnValue
					value={
						<span dir={ "ltr" }>
							{ getFixedPrice(total - punctTotal) }
						</span>
					}
					width={ ColumnWidth.totalSum }
				/>
				<ColumnValue value={ numberOfDocs } width={ ColumnWidth.count }/>
				<ColumnValue
					value={
						!!docDiffCount
							? "".concat(
							"נמצאו הבדלים ב-",
							docDiffCount,
							" ",
							"תעודות משלוח"
							)
							: ""
					}
					width={ ColumnWidth.remarks }
					color={ colors.red }
				/>
			</div>

			<div style={ { width: COLUMN_BABY_WIDTH } }>
				<img alt={ "collapse" } src={ isOpen ? "img/icons/arrow-up.svg" : "img/icons/arrow-down.svg" }/>
			</div>
		</HeaderContainer>
	)
}

const CollapsableBody = ( { isOpen, docs, itemRef, onSCSelection } ) => {
	const [ querySCId, setQuerySCId ] = useState(null)

	const sections = docs.filter(doc =>
		                     doc.doc.DOCNUMBER
		                        .toString()
		                        .includes(querySCId || "")
	                     )
	                     .map(document => (
		                     {
			                     ...document,
			                     ...getDocRowStatus({
				                     punctDoc: document.punctDoc,
				                     diff: document.diff
			                     })
		                     }
	                     ))
	                     .reduce(
		                     ( acc, current ) => acc[ current.status ].push(current) && acc
		                     , {
			                     [ DOC_STATUS_GREY ]: [],
			                     [ DOC_STATUS_RED ]: [],
			                     [ DOC_STATUS_GREEN ]: [],
		                     }
	                     )

	return (
		<div
			style={ {
				visibility: isOpen ? "visible" : "hidden",
				height: isOpen ? "auto" : "0",
				width: "100%",
				backgroundColor: "#ffffff",
			} }
		>
			{ !!docs && (
				<div
					style={ {
						display: "flex",
						flexDirection: "column",
						marginRight: "30px",
						marginLeft: "30px",
					} }
				>
					<StyledInput
						value={ querySCId }
						placeholder={ "סנן לפי מס' תעודת משלוח" }
						onChange={ ( e ) => {
							const text = e.target.value;

							if (!text && text === "") {
								setQuerySCId(null)
							} else {
								setQuerySCId(text)
							}
						} }
					/>

					{
						// <input
						// 	style={ { width: "200px", marginBottom: "8px" } }
						// 	type="number"
						// 	value={ querySCId }
						// 	placeholder={ "סנן לפי מס' תעודת משלוח" }
						// 	onChange={ ( e ) => {
						// 		const text = e.target.value;
						//
						// 		if (!text && text === "") {
						// 			setQuerySCId(null)
						// 		} else {
						// 			setQuerySCId(text)
						// 		}
						// 	} }
						// />
					}


					<CollapsableBodyRow
						style={ {
							// width: COLLAPSABLE_BODY_WIDTH,
							marginRight: "8px",
							marginLeft: "8px",
							// border: "solid 1px rgba(209, 209, 209, 0.45)",
							boxShadow: "-6.9px 4px 4px 0 rgba(213, 213, 213, 0.23)",
							backgroundColor: "#efefef"
						} }
					>

						<ColumnValue
							value={ "" }
							width={ ColumnWidth.bodyStatus }
						/>

						<CollapsableBodyRowValue>
							<ColumnValue
								value={ "מס' תעודת משלוח" }
								width={ ColumnWidth.bodyValue }
								isBold={ false }
							/>

							<ColumnValue
								value={ "חיוב בפועל" }
								width={ ColumnWidth.bodyValue }
								isBold={ false }
							/>

							<ColumnValue
								value={ "סכום הזמנה" }
								width={ ColumnWidth.bodyValue }
								isBold={ false }
							/>

							<ColumnValue
								value={ "הפרש" }
								width={ ColumnWidth.bodyValue }
								isBold={ false }
							/>
						</CollapsableBodyRowValue>
					</CollapsableBodyRow>

					{ sections[ DOC_STATUS_GREY ].length !== 0 && (
						<StatusSection
							// title={ DOC_STATUS_GREY }
							section={ sections[ DOC_STATUS_GREY ] }
							itemRef={ itemRef }
							onSCSelection={ onSCSelection }
							// color={sections[ DOC_STATUS_GREY ][0].color}
						/>
					) }

					{ sections[ DOC_STATUS_RED ].length !== 0 && (
						<StatusSection
							// title={ DOC_STATUS_RED }
							section={ sections[ DOC_STATUS_RED ] }
							itemRef={ itemRef }
							onSCSelection={ onSCSelection }
							// color={sections[ DOC_STATUS_RED ][0].color}
						/>
					) }

					{ sections[ DOC_STATUS_GREEN ].length !== 0 && (
						<StatusSection
							// title={ DOC_STATUS_GREEN }
							section={ sections[ DOC_STATUS_GREEN ] }
							itemRef={ itemRef }
							onSCSelection={ onSCSelection }
							// color={sections[ DOC_STATUS_GREEN ][0].color}
						/>
					) }

					{
						// docs.filter(
						// 	doc =>
						// 		doc.doc.DOCNUMBER
						// 		   .toString()
						// 		   .includes(querySCId || "")
						//     )
						//     .map(document => (
						// 	    {
						// 		    ...document,
						// 		    ...getDocRowStatus({
						// 			    punctDoc: document.punctDoc,
						// 			    diff: document.diff
						// 		    })
						// 	    }
						//     ))
						//     .map(( document ) => InvoiceDocument({ document, itemRef, onSCSelection }))
					}
				</div>
			) }
		</div>
	)
}

const StatusSection = ( {
	// title,
	section,
	itemRef,
	onSCSelection,
	// color
} ) => (
	<div style={ { display: "flex", flexDirection: "column" } }>
		{
			// <div
			// 	style={ {
			// 		margin: "8px 8px 0 0",
			// 		textDecorationLine: "overline underline",
			// 		textDecorationColor: color
			// 	} }
			// >
			// 	<ColumnValue
			// 		value={ title }
			// 		width={ COLUMN_LARGE_WIDTH }
			// 		isBold={false}
			// 	/>
			// </div>
		}

		{
			section.map(( document ) => InvoiceDocument({ document, itemRef, onSCSelection }))
		}
	</div>
)

const DOC_STATUS_GREY = "אין נתונים להשוואה"
const DOC_STATUS_RED = "קיימים פערים"
const DOC_STATUS_GREEN = "תקין"

const getDocRowStatus = ( { punctDoc, diff } ) =>
	!!punctDoc
		? !!diff
		? { status: DOC_STATUS_RED, color: colors.red }
		: { status: DOC_STATUS_GREEN, color: colors.vibrantGreen90 }
		: { status: DOC_STATUS_GREY, color: colors.greyish }

const InvoiceDocument = ( { document, itemRef, onSCSelection } ) => {
	const {
		      doc,
		      diff,
		      punctDoc,
		      punctTotal,
		      // status,
		      color,
	      } = document;

	const { DOCNUMBER, docTotal } = doc;

	// const { status, color } = getDocRowStatus({ punctDoc, diff })

	return (
		<CollapsableBodyRow
			key={ DOCNUMBER }
			onClick={ () => {
				onSCSelection(DOCNUMBER, itemRef);
			} }
			style={ {
				// width: COLLAPSABLE_BODY_WIDTH,
				cursor: "pointer",
				margin: "8px",
				// border: "solid 1px rgba(209, 209, 209, 0.45)",
				boxShadow: "-6.9px 4px 4px 0 rgba(213, 213, 213, 0.23)",
			} }
		>
			<div
				style={ {
					display: "flex",
					flexDirection: "row",
					width: ColumnWidth.bodyStatus
				} }
			>
				<div
					style={ {
						width: "12px",
						height: "140%",
						marginTop: "-10%",
						marginLeft: "8px",
						backgroundColor: color,
						borderTopLeftRadius: DIFF_BORDER_RADIUS,
						borderBottomLeftRadius: DIFF_BORDER_RADIUS,
					} }
				/>

				<img src={ "img/icons/invalid-name.png" }/>
			</div>

			<CollapsableBodyRowValue>
				<ColumnValue
					value={ DOCNUMBER }
					width={ ColumnWidth.bodyValue }
				/>

				<ColumnValue
					value={
						<span dir={ "ltr" }>
						{ getFixedPrice(docTotal) }
					</span>
					}
					width={ ColumnWidth.bodyValue }
				/>

				{ !!punctDoc && (
					<ColumnValue
						value={
							<span dir={ "ltr" }>
							{ getFixedPrice(punctTotal) }
						</span>
						}
						width={ ColumnWidth.bodyValue }
					/>
				) }

				{ !!punctDoc && !!diff && (
					<ColumnValue
						value={
							<span dir={ "ltr" }>
							{ getFixedPrice(docTotal - punctTotal) }
						</span>
						}
						width={ ColumnWidth.bodyValue }
					/>
				) }
			</CollapsableBodyRowValue>
		</CollapsableBodyRow>
	);
}


InvoiceReport.propTypes = {};

InvoiceReport.defaultProps = {};

const mapStateToProps = ( state ) => {
	return {
		// state,
		contractorIds: getContractorIds(state.roles.roles),
		contractors: state.firebase.data.contractors,
		isApplicationOrdersAdmin: state.ordersManagement.isApplicationOrdersAdmin,
		isOrderLoading: state.ordersHistory.isLoading,
		roles: state.roles.roles,
	};
};

const mapDispatchToProps = ( dispatch ) => bindActionCreators(
	{
		setFieldValue,
		// fetchItems,
		// getOrdersHistory,
		getOrderById,
		resetCurrentOrder
	},
	dispatch
);

export default compose(
	withFirestore,
	connect(mapStateToProps, mapDispatchToProps),
	firebaseConnect(( props ) => {
		let paths = [ "contractors" ];
		return paths;
	})
)(InvoiceReport);
