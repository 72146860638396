import React, { Component } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux';

import { firebaseConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { uuidWithoutSpace } from "shared/src/utils";
import moment from "moment"
import memoize from "memoize-one";
import ReactTable from 'react-table'

import { DateRangePicker } from 'react-dates';
import * as reactDatesConstants from "react-dates/constants"
import 'react-table/react-table.css'
import 'react-dates/lib/css/_datepicker.css';
import "../OrdersManagement/react-table-overrides.scss";


import StickyFloatingButton from "../../components/StickyFloatingButton"
import { extractLatestStatus } from "shared/src/utils/orders";
import { getStatusLabel } from "shared/src/utils/po"
import _ from "lodash";

import { createNewPo, initPoFromList } from "shared/src/modules/pos"

import { history } from "../../store";

import ContractorSelectionModal from "./contractorSelectionModal"

import {
    filterColumn,
    filterThisWeek,
    filterToday,
    filterTomorrow, onSortChange,
    setAmountFilter,
    setDates, showAllOpen, showCancelled, showClosed
} from "shared/src/modules/ordersFilter";
import { dateFilter, simpleTextFilter, statusFilter, totalFilter, booleanFilter } from "../../utils/filter";
import FontIcon from "material-ui/FontIcon";
import { ORDER_MANAGEMENT_CONTEXT, ORDER_STATUSES } from "shared/src/constants/orders";
import { PO_STATUSES } from "shared/src/constants/pos";

import DateRangeFilter from "../../components/Table/Filters/DateRangeFilter";
import TextFilter from "../../components/Table/Filters/TextFilter";
import AmountFilter from "../../components/Table/Filters/AmountFilter";


class POList extends Component {

    constructor() {
        super();
        this.state = {
            isOpenCreateModal: false,
            selectedCustomer: null,
            focusedInput: null,
            statusFilter: null,
            showDatePicker: false,
        }

        this.dateFilterOnChangeCallback = null;
    }

    onCreate = () => {
        const {supplierId, supplierContractors, createNewPo} = this.props;
        const {selectedCustomer} = this.state;

        const poId = uuidWithoutSpace();
        const customer = _.find(supplierContractors, {id: selectedCustomer})
        createNewPo(poId, customer, supplierId, null, true);
        history.push(`/po/${supplierId}/${selectedCustomer}/${poId}`)
    }

    onEdit = (po) => {
        console.log("on Edit", po)
        const {supplierId, initPoFromList} = this.props;
        initPoFromList(po);
        history.push(`/po/${supplierId}/${po.customer.id}/${po.id}`)
    }

    closeModal = () => {
        this.setState({
            isOpenCreateModal: false,
            selectedCustomer: null
        })
    }

    showDatePicker = () => {
        this.setState({showDatePicker: true, focusedInput: reactDatesConstants.START_DATE})
    }

    _filterColumn = (columnId, value) => {
        this.props.filterColumn(columnId, value, "PO")
    }

    _notesFilter = (filter, row) => {
        return simpleTextFilter(filter, row, "note");
    };

    _customerFilter = (filter, row) => {
        return simpleTextFilter(filter, row, "customer");
    };

    specialRequestFilter = (filter, row) => {
        return simpleTextFilter(filter, row, "specialRequest");
    };

    renderDateFilterColumn = (onChange) => {
        const {startDateFilter, endDateFilter} = this.props;
        return (
            <DateRangeFilter
                onChange={onChange}
                endDateFilter={endDateFilter}
                onChangeCallbackSetter={() => {
                    this.dateFilterOnChangeCallback = onChange
                }}
                onClose={() => {
                    this._filterColumn("date", {startDate: null, endDate: null})
                    this.props.setDates(null, null, "date", "PO");
                }}
                showDatePicker={this.showDatePicker}
                startDateFilter={startDateFilter}
            />
        )

    }

    renderDatePicker = () => {
        return (<DateRangePicker
            startDate={this.props.startDateFilter} // momentPropTypes.momentObj or null,
            startDateId="startDateFilter" // PropTypes.string.isRequired,
            endDate={this.props.endDateFilter} // momentPropTypes.momentObj or null,
            endDateId="endDateFilter" // PropTypes.string.isRequired,
            onDatesChange={({startDate, endDate}) => {
                this.props.setDates(startDate, endDate, "date", "PO");
            }} // PropTypes.func.isRequired,
            focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
            onFocusChange={focusedInput => {
                this.setState({focusedInput})
            }} // PropTypes.func.isRequired,
            isRTL={true}
            isOutsideRange={day => moment(day).isBefore('2018-01-01')}
            anchorDirection={reactDatesConstants.ANCHOR_RIGHT}
            showClearDates={true}
            withPortal={true}
            appendToBody={true}
            disableScroll={true}
            small={true}
            block={true}
        />)
    }

    renderAmountFilter = (column) => {
        const {amountFilterCondition, amountFilterValue, setAmountFilter} = this.props;

        return (
            <AmountFilter
                amountFilterCondition={amountFilterCondition}
                amountFilterValue={amountFilterValue}
                onConditionChange={(value) =>
                    setAmountFilter({
                        amountFilterCondition: value,
                        amountFilterValue,
                        filterName: "PO",
                        column
                    })}
                onValueChange={(value) =>
                    setAmountFilter({
                        amountFilterCondition,
                        amountFilterValue: value,
                        filterName: "PO",
                        column
                    })}
            />)

    }

    getTableData = memoize(
        (pos) => {
            return pos.filter((item) => item.isPOContract)
        }
    );


    render() {
        const {isOpenCreateModal, selectedCustomer} = this.state;
        const {
            supplierIds, supplierId, pos,
            filtered,
        } = this.props;
        let {supplierContractors} = this.props
        supplierContractors = supplierContractors || []

        console.log("props", {supplierIds, supplierId, supplierContractors})

        const tableData = this.getTableData(pos) || [];
        return (
            <div style={{marginTop: 20}}>
                <div>
                    <div style={{visibility: "hidden", height: 0}}>{this.renderDatePicker()}</div>
                </div>
                <ReactTable
                    data={tableData}
                    columns={
                        [
                            {
                                id: 'date',
                                Header: 'תאריך יצירה',
                                accessor: 'createdDate', // String-based value accessors!,
                                Cell: props => {
                                    return moment(props.value).format("D/MM/YY")
                                },
                                filterMethod: (filter, row) => dateFilter(filter, row, "date"),
                                Filter: ({filter, onChange}) => this.renderDateFilterColumn(onChange)
                            },
                            {
                                id: 'status',
                                Header: 'סטטוס הצעת מחיר',
                                accessor: po => extractLatestStatus(po.status),
                                Cell: props => <span>{getStatusLabel(props.value, props.original.isPOContract)}</span>,
                                filterMethod: statusFilter,
                                Filter: <select
                                    ref={ref => {
                                        this.status = ref
                                    }}
                                    onChange={e => {
                                        this.setState({statusFilter: e.target.value})
                                        this._filterColumn("status", {value: e.target.value, condition: "equal"})
                                    }}
                                    value={this.state.statusFilter}>
                                    <option value={""}>בחר סטטוס</option>
                                    {Object.values(PO_STATUSES).map(statusKey => (
                                        <option key={statusKey} value={statusKey}>{getStatusLabel(statusKey)}</option>
                                    ))}
                                </select>,
                            },
                            {
                                id: 'customer',
                                Header: 'לקוח',
                                accessor: po => po.customer.name,
                                filterMethod: this._customerFilter,
                                Filter: <TextFilter onChange={(value)=>this._filterColumn("customer", value)} refHandler={ref => {this.customer = ref}}/>

                            },
                            {
                                id: 'numOfItems',
                                Header: 'מספר פריטים',
                                accessor: po => po.summary.num, // String-based value accessors!,
                                filterMethod: (filter, row) => totalFilter(filter, row, 'totalPrice'),
                                Filter: ({filter, onChange}) => this.renderAmountFilter('totalPrice'),
                            },
                            {
                                id: 'totalPrice',
                                Header: 'סה"כ עלות',
                                accessor: po => po.summary.sum, // String-based value accessors!,
                                filterMethod: (filter, row) => totalFilter(filter, row, 'numOfItems'),
                                Filter: ({filter, onChange}) => this.renderAmountFilter('numOfItems'),
                            },
                            {
                                id: 'note',
                                Header: 'הערות',
                                accessor: 'note', // String-based value accessors!,
                                filterMethod: this._notesFilter,
                                Filter: <TextFilter onChange={(value)=>this._filterColumn("note", value)} refHandler={ref => {this.notes = ref}}/>
                            },
                            {
                                id: 'specialRequest',
                                Header: 'הנחיות מיוחדות',
                                accessor: 'specialRequest', // String-based value accessors!,
                                filterMethod: this.specialRequestFilter,
                                Filter: <TextFilter onChange={(value)=>this._filterColumn("specialRequest", value)} refHandler={ref => {this.specialRequest = ref}}/>
                            },
                        ]}
                    showPagination={false}
                    filterable={true}
                    filtered={filtered}
                    pageSize={pos ? pos.length : 0}
                    getTdProps={(state, rowInfo, column, instance) => {

                        const returnedObj = {
                            onClick: (e, handleOriginal) => {

                                console.log("A Td Element was clicked!");
                                console.log("it produced this event:", e);
                                console.log("It was in this column:", column);
                                console.log("It was in this row:", rowInfo);
                                console.log("It was in this table instance:", instance);
                                this.onEdit(rowInfo.original);

                                // IMPORTANT! React-Table uses onClick internally to trigger
                                // events like expanding SubComponents and pivots.
                                // By default a custom 'onClick' handler will override this functionality.
                                // If you want to fire the original onClick handler, call the
                                // 'handleOriginal' function.
                                if (handleOriginal) {
                                    handleOriginal();
                                }
                            }
                        };


                        return returnedObj;
                    }}
                />
                <StickyFloatingButton
                    onPress={() => {
                        this.setState({isOpenCreateModal: true});
                    }}
                    icon={"add"}
                />

                <ContractorSelectionModal
                    isOpen={isOpenCreateModal}
                    onSubmit={this.onCreate}
                    disabled={!selectedCustomer}
                    onChane={(selected, action) => {
                        console.log(action, selected);
                        this.setState({selectedCustomer: selected.value});
                    }}
                    onClose={this.closeModal}
                    options={supplierContractors.map(c => ({value: c.id, label: c.name}))}
                    selectLabel={"אנא בחרו לקוח"}
                />
            </div>
        );
    }
}

POList.propTypes = {
    roles: PropTypes.object,
    supplierIds: PropTypes.object,
    supplierId: PropTypes.string,
    supplierContractors: PropTypes.array
};

POList.defaultProps = {};

const mapStateToProps = (state) => {
    return {
        pos: state.pos.pos,
        filtered: state.posFilter.filtered,
        filterKey: state.posFilter.filterKey,
        startDateFilter: state.posFilter.startDateFilter,
        endDateFilter: state.posFilter.endDateFilter,
        amountFilterCondition: state.posFilter.amountFilterCondition,
        amountFilterValue: state.posFilter.amountFilterValue,
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({
    createNewPo,
    initPoFromList,
    filterColumn,
    setDates,
    setAmountFilter,
    filterToday,
    filterTomorrow,
    filterThisWeek,
    showAllOpen,
    showClosed,
    showCancelled,
    onSortChange,
}, dispatch);

export default compose(
    firebaseConnect((props) => {

        return []
    }),
    connect(
        mapStateToProps, mapDispatchToProps
    )
)(POList);
