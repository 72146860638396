import React, { Component } from 'react';
import _ from "lodash";
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { Badge, Nav, NavItem, NavLink as RsNavLink } from 'reactstrap';
import classNames from 'classnames';
import nav from '../Sidebar/_nav';
import { checkRole } from "shared/src/selectors/roles";
import { setSidebarToggleDisplay } from "../../modules/layout"

class Sidebar extends Component {

    handleClick(e) {
        e.preventDefault();
        e.target.parentElement.classList.toggle('open');
    }

    activeRoute(routeName, props) {
        // return this.props.location.pathname.indexOf(routeName) > -1 ? 'nav-item nav-dropdown open' : 'nav-item nav-dropdown';
        return props.location.pathname.indexOf(routeName) > -1 ? 'nav-item nav-dropdown open' : 'nav-item nav-dropdown';

    }

    // todo Sidebar nav secondLevel
    // secondLevelActive(routeName) {
    //   return this.props.location.pathname.indexOf(routeName) > -1 ? "nav nav-second-level collapse in" : "nav nav-second-level collapse";
    // }

    mobileSidebarToggle(e) {
        //   e.preventDefault();
       // document.body.classList.toggle('sidebar-mobile-show');
    }


    render() {

        const props = this.props;
        const activeRoute = this.activeRoute;
        const handleClick = this.handleClick;
        const {intl} = this.props;

        // badge addon to NavItem
        const badge = (badge) => {
            if (badge) {
                const classes = classNames(badge.class);
                return (<Badge className={classes} color={badge.variant}>{badge.text}</Badge>)
            }
        };

        // simple wrapper for nav-title item
        const wrapper = item => {
            const name = intl.formatMessage({id: item.name});
            return (item.wrapper && item.wrapper.element ? (React.createElement(item.wrapper.element, item.wrapper.attributes, name)) : name)
        };

        // nav list section title
        const title = (title, key) => {
            const classes = classNames('nav-title', title.class);
            return (<li key={key} className={classes}>{wrapper(title)} </li>);
        };

        // nav list divider
        const divider = (divider, key) => {
            const classes = classNames('divider', divider.class);
            return (<li key={key} className={classes}></li>);
        };

        // nav item with nav link
        const navItem = (item, key) => {
            const classes = {
                item: classNames(item.class),
                link: classNames('nav-link', item.variant ? `nav-link-${item.variant}` : ''),
                icon: classNames(item.icon)
            };
            return (
                navLink(item, key, classes)
            )
        };

        // nav link
        const navLink = (item, key, classes) => {
            const name = intl.formatMessage({id: item.name});
            const url = item.url ? item.url : '';
            return (
                <NavItem key={key} className={classes.item} onClick={this.mobileSidebarToggle}>
                    {isExternal(url) ?
                        <RsNavLink href={url} className={classes.link} active>
                            {item.imgIcon ? (<img src={item.imgIcon}/>) : (<i className={classes.icon}></i>)}
                            {name}{badge(item.badge)}
                        </RsNavLink>
                        :
                        <NavLink to={url} className={classes.link} activeClassName="active">
                            {item.imgIcon ? (<img src={item.imgIcon}/>) : (<i className={classes.icon}></i>)}
                            {name}{badge(item.badge)}
                        </NavLink>
                    }
                </NavItem>
            )
        };

        // nav dropdown
        const navDropdown = (item, key) => {
            const name = intl.formatMessage({id: item.name});
            return (
                <li key={key} className={activeRoute(item.url, props)}>
                    <a className="nav-link nav-dropdown-toggle" href="#" onClick={handleClick.bind(this)}><i
                        className={item.icon}></i>{name}</a>
                    <ul className="nav-dropdown-items">
                        {navList(item.children)}
                    </ul>
                </li>)
        };

        // nav type
        const navType = (item, idx) =>
            item.title ? title(item, idx) :
                item.divider ? divider(item, idx) :
                    item.children ? navDropdown(item, idx)
                        : navItem(item, idx);

        // nav list
        const navList = (items) => {
            const {setSidebarToggleDisplay} = this.props;
            const list = items.map((item, index) => {
                if (item.acl) {
                    if (typeof item.acl === "function") {
                        if (item.acl(props.roles)) {
                            return navType(item, index)
                        }
                        else return null;
                    }
                    else if (item.acl.some(el => {
                        if (typeof el === "function") {
                            return el(props.roles);
                        }
                        else {
                            return checkRole(props.roles, el)
                        }
                    })) {
                        return navType(item, index)
                    }
                    // if (_.find(item.acl, (acl)=>{
                    //       return Object.keys(props.roles).includes(acl);
                    //     }) ) {
                    //     return navType(item, index)
                    // }
                    else {
                        return null;
                    }
                } else {
                    return navType(item, index)
                }

            }).filter(x => x);


            return list;
        };

        const isExternal = (url) => {
            const link = url ? url.substring(0, 4) : '';
            return link === 'http';
        };

        // sidebar-nav root
        return (
            <nav className={"tab-bar"} style={{display: "flex", flexDirection: "row", marginTop: 55}}>
                {navList(nav.items)}
            </nav>
        )
    }
}

const mapStateToProps = (state) => ({
    roles: state.roles.roles
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setSidebarToggleDisplay
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Sidebar));
