import React from "react"

const ActionButtonsContainer = ({ children }) => (
    <div style={{ display: "flex", justifyContent: "space-around" }}>
        {children}
    </div>
)

export default ActionButtonsContainer;

