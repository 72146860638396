import _ from "lodash";
import { getFirebase } from 'react-redux-firebase';
import {  FILTERS } from "shared/src/constants/orders";

import {
    FILTER_TODAY,
    FILTER_TOMORROW,
    FILTER_THIS_WEEK,
    FILTER_OPEN,
    FILTER_MY_ORDERS,
    FILTER_CLOSED,
    FILTER_CANCELLED,
    FILTER_FDO,
    setFilterContext
} from "shared/src/modules/ordersFilter"
import { SET_CONTEXT } from "shared/src/modules/ordersManagement";

export const filtersMiddleware = ({dispatch, getState}) => next => action => {

    try {
        const userId = getState().roles.roles.user_id;
        const firebase = getFirebase();
        let filter = null;
        let myOrders = null;
        switch (action.type) {
            case FILTER_TODAY:
                filter = FILTERS.TODAY;
                break;
            case FILTER_TOMORROW:
                filter = FILTERS.TOMORROW;
                break;
            case FILTER_THIS_WEEK:
                filter = FILTERS.THIS_WEEK;
                break;
            case FILTER_OPEN:
                filter = FILTERS.OPEN;
                break;
            case FILTER_MY_ORDERS:
                myOrders = action.payload.checked;
                break;
            case FILTER_CLOSED:
                filter = FILTERS.CLOSED;
                break;
            case FILTER_CANCELLED:
                filter = FILTERS.CANCELLED;
                break;
            case FILTER_FDO:
                filter = FILTERS.FDO;
                break;
        }

        if(filter){
            firebase.ref(`userSettings/${userId}/ordersFilter/default`).set(filter)
        }

        if(myOrders !== null){
            firebase.ref(`userSettings/${userId}/ordersFilter/myOrders`).set(myOrders)
        }
    }catch (e) {
        console.error("filtersMiddleware error", e, action)
    }

    return next(action);

};

export const filtersContextMiddleware = ({dispatch, getState}) => next => action => {
    try {

        if(action.type === SET_CONTEXT){
            dispatch(setFilterContext(action.payload))
        }

    }catch (e) {
        console.error("filtersContextMiddleware error", e, action)
    }

    return next(action);

};

